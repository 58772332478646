<template>
  <div>
    <component
      :is="notificationObject.link ? ( external ? 'a' : 'router-link' ) : 'div'"
      v-if="notificationObject && !notification.isDisabled && $route.name !== 'checkout'"
      v-show="!$route.meta.hideHeader"
      ref="notification"
      class="notification t-center"
      :style="notificationObject.link ? 'display: block' : undefined"
      :to="notificationObject.link && !external ? url : undefined"
      :href="notificationObject.link && external ? url : undefined"
      :target="notificationObject.link && external ? notificationObject.link.target : undefined"
    >
      <div class="notification__wrapper">
        <div class="notification--content">
          {{ notificationObject.message }}
        </div>
      </div>
    </component>
    <header
      v-show="!$route.meta.hideHeader"
      ref="header"
      :class="[breadcrumbs ? 'header--breadcrumbs' : false]"
    >
      <Spacer
        x="s"
        class="header-wrapper"
      >
        <Flex class="flex-auto">
          <Flex
            class="flex-child u-hidden--from-m"
            align="v-center"
          >
            <Icon
              :name="!menuOpened ? 'menu' : 'close'"
              theme="tool"
              size="m"
              :fn="toggleMenu"
            />
          </Flex>
          <Flex
            class="flex-child"
            :align="{ default: 'center', m: 'h-left-v-center' }"
          >
            <router-link
              to="/"
              class="logo"
            >
              <inline-svg :src="require('@/assets/svg/logo.svg')" />
            </router-link>
          </Flex>

          <Menu />

          <Toolbar />
        </Flex>
      </Spacer>
      <Breadcrumbs
        v-if="isMobile"
        class="u-hidden--from-m"
      />
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gsap from 'gsap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import Menu from '@/components/ui/menu';
import Toolbar from '@/components/ui/toolbar';
import Icon from '@/components/ui/icon';
import Breadcrumbs from '@/components/ui/breadcrumbs';

export default {
  name: 'Header',
  components: {
    Menu,
    Toolbar,
    Icon,
    Breadcrumbs,
  },
  data() {
    return {
      init: false,
      root: null,
      notification: {
        height: 0,
        isDisabled: false,
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters(['menuOpened', 'isMobile', 'breadcrumbs', 'options', 'wcSettings']),
    notificationObject() {
      let object;
      if (this.options.notification && this.options.notification.length > 0) {
        object = this.options.notification.find((item) => item.active && item.currency === 'all');
        if (!object) {
          object = this.options.notification.find((item) => item.active && item.currency === this.wcSettings.woocommerce_currency);
        }
      }
      return object;
    },
    url() {
      if (this.notificationObject) {
        if (this.notificationObject.link.url && typeof this.notificationObject.link.url === 'string' && process.env.VUE_APP_BASE_URL && process.env.VUE_APP_BASE_URL !== '/') {
          return this.notificationObject.link.url.replace(process.env.VUE_APP_BASE_URL, '');
        }
        return this.notificationObject.link.url;
      }
      return undefined;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
  },
  watch: {
    menuOpened(val) {
      if (val) {
        disableBodyScroll(this.$refs.header.querySelector('.nav'));
      } else {
        enableBodyScroll(this.$refs.header.querySelector('.nav'));
      }
      // document.body.classList[val ? 'add' : 'remove']('overflow-hidden');
    },
    $route(to) {
      if (to && !this.init) {
        this.init = true;

        gsap.set(this.$refs.header, {
          autoAlpha: 1,
          y: this.isMobile ? -60 - this.notification.height : -20 - this.notification.height,
          yPercent: -100,
          xPercent: -50,
        });

        if (this.notificationObject) {
          gsap.set('.notification', {
            autoAlpha: 1,
            y: this.isMobile ? -this.notification.height : -this.notification.height,
            yPercent: -100,
          });
        }

        gsap.to([this.$refs.header, '.notification'], {
          y: 0,
          yPercent: 0,
          duration: 0.6,
          delay: this.$route.meta.customView ? 0 : 1,
          ease: 'power3.inOut',
          onComplete: () => {
            this.$bus.$emit('endHeaderAnim');
          },
        });
      }
    },
  },
  mounted() {
    gsap.set(this.$refs.header, {
      autoAlpha: 0,
    });

    if (this.notificationObject) {
      gsap.set([this.$refs.header, '.notification'], {
        autoAlpha: 0,
      });
      this.$bus.$on('windowResized', this.setNotificationHeight);
      this.setNotificationHeight();
    }
    this.notification.message = this.wcSettings.woocommerce_currency;
  },
  destroyed() {
    this.$bus.$off('windowResized', this.setNotificationHeight);
  },
  methods: {
    toggleMenu() {
      this.$store.commit('TOGGLE_MENU');
    },
    setNotificationHeight() {
      this.root = document.documentElement;
      this.notification.height = this.$refs.notification.clientHeight || this.$refs.notification.$el.clientHeight;
      this.root.style.setProperty('--notificationHeight', `${this.notification.height}px`);
    },
    disableNotification() {
      this.notification.isDisabled = true;
      this.root.style.setProperty('--notificationHeight', '0px');
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: calc(var(--notificationHeight, 0px) + 12px);
  left: 50vw;
  // max-width: 1370px;
  width: calc(100% - 36px);
  background: $primary;
  color: $black;
  border-radius: var(--radius);
  z-index: 100;
  filter: drop-shadow(0 4px 24px rgba(0,0,0,.1));

  max-width: map-get($wrapper, xl);
  transition: top .3s;

  @extend %typo--label-m;

  &.header--breadcrumbs {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include mq(m) {
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }

  .header-wrapper {
    @include mq($until: m) {
      position: relative;
      background: $primary;
      z-index: 2;
      border-radius: var(--radius);
    }
  }

  @include mq(m) {
    top: calc(var(--notificationHeight, 0px) + 20px);
    width: calc(100% - 72px);
    overflow: hidden;
  }

  .flex-child {
    height: 48px;

    @include mq(m) {
      height: 72px;
    }
  }

  .logo {
    display: inline-block;
    width: 77px;

    @include mq(m) {
      width: 106px;
    }

    svg {
      transform: translate3d(0px, 3px, 0);
      @include mq(m) {
        transform: translate3d(6px, 3px, 0);
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 72px;
    left: 0;
    width: 100%;
    height: 72px;
    background: $primary-d;
    pointer-events: none;
    border-radius: 0 0 var(--radius) var(--radius);

    display: none;

    @include mq(m) {
      display: block;
    }
  }
}

.notification {
  @extend %typo--label-m;
  color: $white;
  padding: map-get($spacer, 'xs') map-get($spacer, 'm2');
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background: $seconday;
  filter: drop-shadow(0 4px 24px rgba(0,0,0,.1));

  &__wrapper{
    position: relative;
  }

  &--content{
    padding: 0 map-get($spacer, 'm2');
  }
}

a.notification {
  cursor: pointer
}
</style>
