<template>
  <div
    :class="[
      'video-player',
      data.full_height ? 'full-height' : false,
      data.src ? 'video--cover' : false,
      data.border ? 'video-bordered' : false,
    ]"
    :style="{
      '--width': `${width}`,
      '--height': `${height}`,
      '--iframe-height': `${ratio}vw`,
      '--iframe-min-height': `${100 * (100 / ratio)}vh`,
    }"
  >
    <div
      v-if="data.embed && data.url"
      ref="player"
      class="plyr"
      :data-plyr-provider="embed.provider"
      :data-plyr-embed-id="embed.id"
      :data-poster="poster"
    />

    <video
      v-if="!data.embed && data.src"
      ref="player"
      class="plyr"
      :src="data.src"
      :data-poster="poster"
      playsinline
      :autoplay="data.src"
      :muted="data.src"
      :loop="data.src"
    />
    <div
      v-if="data.caption"
      v-html="data.caption"
    />
    <div
      v-if="data.shadow"
      class="shadow"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import { parseVideoURL, getParm } from '@/assets/js/utils';

export default {
  name: 'VideoPlayer',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
      playing: false,
      loading: false,
      ratio: 56.25,
      width: 16,
      height: 9,
    };
  },
  computed: {
    embed() {
      return this.data.embed && this.data.url
        ? parseVideoURL(this.data.url)
        : null;
    },
    poster() {
      if (
        this.data.poster
          && this.data.poster.url_webp
          && document.documentElement.classList.contains('webp')
      ) {
        return `${this.data.poster.sizes.l.replace(
          'wp-content',
          'wp-content/uploads-webpc',
        )}.webp`;
      } return null;
    },
  },
  mounted() {
    if (this.data.url || this.data.src) {
      this.plyr = new Plyr(this.$refs.player, {
        loop: { active: this.data.src },
        muted: this.data.src,
        autoplay: this.data.src || this.data.embed,
        controls: this.data.src ? [] : ['play', 'progress', 'mute'],
        keyboard: { focused: false, global: false },
        storage: { enabled: false },
        clickToPlay: false,
      });

      if (this.data.url) {
        this.plyr.on('ready', (event) => {
          if (event.detail.plyr.ratio) {
            const ratio = event.detail.plyr.ratio.split(':');
            this.width = Number(ratio[0]);
            this.height = Number(ratio[1]);
            this.ratio = (this.height / this.width) * 100;
          }
          const time = getParm(this.data.url, 't');
          if (time) {
            this.plyr.currentTime = Number(time);
          }
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .video-player {
    position: relative;
    width: 100%;
    @include aspect-ratio($width: var(--width, 16), $height: var(--height, 9));
    max-height: 80vh;
    margin: 0 auto;

    &.video-bordered {
      border-radius: var(--radius);
      overflow: hidden;

      .plyr {
        border-radius: var(--radius);
        overflow: hidden;
      }
    }

    &--cover {
      @include aspect-ratio($auto: true);
      height: 100%;
      max-height: none;
    }

    &.full-height {
      height: calc(100vw + 36px);
      @include aspect-ratio($auto: true);
      @include mq(m) {
        height: 90vh;
        max-height: 50vw;
      }

      .section--hero--small & {
        height: auto;
        @include aspect-ratio(3, 2);

        @include mq(m) {
          @include aspect-ratio($auto: true);
          height: 70vh;
        }
      }
    }

    .shadow {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 75%;
      background: linear-gradient(
        0deg,
        rgba(36, 40, 44, 0.2) 0%,
        rgba(36, 40, 44, 0) 100%
      );
      pointer-events: none;
    }
  }
</style>

<style lang="scss">
  .plyr {
    --plyr-color-main: $color-white;
    --plyr-range-track-height: 2px;
    --plyr-range-thumb-height: 0px;
    --plyr-range-thumb-shadow: none;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
    }

    .plyr__progress__buffer {
      border-radius: 0px;
    }

    .plyr__progress input[type="range"] {
      border-radius: 0px;
    }

    .plyr__tooltip {
      display: none;
    }

    .plyr__volume {
      width: auto;
      max-width: auto;
      min-width: auto;
    }
  }

  .plyr__poster {
    background-size: cover;
  }

  .plyr__video-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include mq(m) {
      height: 100% !important;
      padding-bottom: 0 !important;
      transform: none !important;
      iframe {
        /* width: 100vw;
      height: 56.25vw;
      min-height: 100vh;
      min-width: 177.77vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); */
      }
    }
  }
</style>
