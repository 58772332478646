import * as dayjs from 'dayjs';
import currencyFormatter from 'currency-formatter';

import {
  createPaymentIntent, createSetupIntent, assignSourceToCustomer, createPaymentSource, createAffirmCharge,
} from '@/api';

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);

export const unparseCurrency = (price, wcSettings, force) => {
  const unformatOptions = {
    code: wcSettings.woocommerce_currency,
    thousand: force ? '.' : wcSettings.woocommerce_price_thousand_separator,
    decimal: force ? ',' : wcSettings.woocommerce_price_decimal_separator,
  };

  if (wcSettings.woocommerce_currency === 'CAD') {
    unformatOptions.symbol = wcSettings.woocommerce_currency_symbol;
  }

  return currencyFormatter.unformat(price, unformatOptions);
};

export const parseCurrency = (price, wcSettings, forceValue = false) => {
  let priceNumber = 0;
  if (price) {
    priceNumber = price;
  }
  const formatOptions = {
    code: forceValue || wcSettings.woocommerce_currency,
    thousand: forceValue && forceValue !== 'EUR' ? ',' : wcSettings.woocommerce_price_thousand_separator,
    decimal: forceValue && forceValue !== 'EUR' ? '.' : wcSettings.woocommerce_price_decimal_separator,
    precision: wcSettings.precision !== undefined ? wcSettings.precision : 2,
    format: '%s%v',
  };

  if (wcSettings.woocommerce_currency === 'CAD' || (forceValue && forceValue === 'CAD')) {
    formatOptions.symbol = 'C$';
  }

  return currencyFormatter.format(priceNumber, formatOptions);
};

export const getOrderTotal = (order, wcSettings) => {
  if (order.total_refunded > 0) {
    return `<del>${parseCurrency(Number(order.total), wcSettings, order.currency)}</del> ${parseCurrency(order.remaining_refund_amount, wcSettings, order.currency)}`;
  }
  return parseCurrency(order.total, wcSettings, order.currency);
};

export const parseDate = (date, wcSettings) => {
  const dateForDayJs = date;
  // if (date.substr(date.length - 5) !== '.000Z') {
  //   dateForDayJs = `${date}.000Z`;
  // }
  return dayjs(dateForDayJs)
  // .locale(importedLocal)
    .format(wcSettings.dateFormat);
};

export const parseDateTime = (date, wcSettings) => {
  const dateForDayJs = date;
  return dayjs(dateForDayJs)
    .format(wcSettings.dateTimeFormat);
};

export const exclVatLabel = (label) => `<br><small>(${label})</small>`;

export const availableOrderActions = {
  'wc-cancelled': [],
  'wc-completed': [],
  'wc-failed': ['pay', 'cancel'],
  'wc-on-hold': [],
  'wc-pending': ['pay', 'cancel'],
  'wc-processing': [],
  'wc-refunded': [],
  //
  'wc-partial-payment': [],
  'wc-pending-deposit': ['pay'],
  'wc-scheduled-payment': ['pay'],
};

export const createAndStorePaymentIntent = async (store, body = null) => {
  store.commit('SET_CART_LOADING', true);
  const { data } = await createPaymentIntent(body);
  store.commit('SET_PAYMENT_METAS', {
    id: 'stripe',
    key: 'clientSecret',
    meta: data.client_secret,
  });
  store.commit('SET_CART_LOADING', false);
};

export const stripeMountCard = (store, paymentMetas) => {
  if (!document.querySelector('#card-element')) {
    return false;
  }

  const elements = paymentMetas.stripe.stripe.elements();

  const style = {
    base: {
      color: '#FFFFFF',
      fontFamily: '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#B4B4B4',
      },
    },
    invalid: {
      fontFamily: '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };

  const card = elements.create('card', {
    hidePostalCode: true,
    style,
  });
  store.commit('SET_PAYMENT_METAS', {
    id: 'stripe',
    key: 'card',
    meta: card,
  });

  paymentMetas.stripe.card.mount('#card-element');

  paymentMetas.stripe.card.on('change', (event) => {
    const { error } = event;
    // TODO Disable the Pay button if there are no card details in the Element
    // document.querySelector("button").disabled = event.empty;
    const displayError = document.getElementById('card-errors');
    if (error) {
      displayError.textContent = error.message;
    } else {
      displayError.textContent = '';
    }
  });

  return true;
};

const stripePayWithCard = async (store, paymentMetas, source = null) => {
  store.commit('SET_CART_LOADING', true);

  const payment_method = source || {
    card: paymentMetas.stripe.card,
  };

  const result = await paymentMetas.stripe.stripe.confirmCardPayment(paymentMetas.stripe.clientSecret, {
    payment_method,
  });

  store.commit('SET_CART_LOADING', false);
  if (result.error) {
    store.commit('SET_SNACKBAR', { active: true, content: result.error.message });
    return false;
  }
  return result.paymentIntent ? result.paymentIntent.id : result.id;
};

export const handleStripePayWithCard = async (store, data, paymentGateway) => {
  let paymentResult = true;
  if (paymentGateway === 'stripe') {
    paymentResult = await stripePayWithCard(store, data.paymentMetas, data.source);
  }
  return paymentResult;
};

export const handlePaymentAfterOrder = (store, data, paymentGateway) => {
  let paymentResult = true;
  if (paymentGateway === 'paypal') {
    paymentResult = false;
    if (data.metas.result === 'success') {
    // this.$store.dispatch('clearCart'); altrimenti sbianca pagina prima ancora di fare window.location
      window.location = data.metas.redirect;
    } else {
      store.commit('SET_SNACKBAR', { active: true, content: 'Error in saving order' });
    }
  } else if (paymentGateway === 'splitit') {
    if (data.metas.result === 'success') {
      paymentResult = true;
    } else {
      paymentResult = false;
      store.commit('SET_SNACKBAR', { active: true, content: 'Error in saving order' });
    }
  }

  return paymentResult;
};

export const handleAffirmCheckout = async (store, data) => {
  try {
    const response = await createAffirmCharge({
      checkoutToken: data.checkoutToken,
      orderId: data.orderId,
    });
    // console.log(response);
    if (response.error) {
      store.commit('SET_SNACKBAR', { active: true, content: response.error });
      console.error(response.error);
      return false;
    }
    return response.data.redirect_url;
  } catch (e) {
    if (e.message) {
      store.commit('SET_SNACKBAR', { active: true, content: e.message });
    } else if (e.error) {
      store.commit('SET_SNACKBAR', { active: true, content: e.error });
    } else {
      store.commit('SET_SNACKBAR', { active: true, content: 'Error in Affirm Charge API, maybe the payment was rejected. If needed, contact us for support before closing this page, thank you.' });
    }
    console.error(e);
    return false;
  }
};

export const addableToCart = (product) => {
  if (!product.purchasable) {
    return false;
  }
  return true;
};

export const isOos = (product) => product.stock_status === 'outofstock' || product.stock_status < 1;

export const addCardToCustomerSavedMethods = async (store, {
  stripeInstance,
  newAccountCredentials,
}) => {
  store.commit('SET_CART_LOADING', true);

  const { source, sourceError } = await stripeInstance.stripe.createSource(
    stripeInstance.card, { type: 'card' },
  );

  if (sourceError) {
    console.error(sourceError);
    store.commit('SET_CART_LOADING', false);
    throw new Error(sourceError.toString());
  }
  // console.log(source);

  try {
    /* const { data: assignResult } = */await assignSourceToCustomer({
      source,
      newAccountCredentials,
    });
    // console.log(assignResult);

    const { data: setupIntent } = await createSetupIntent({
      source,
      newAccountCredentials,
    });
    // console.log(setupIntent);

    /* const setupResult = */await stripeInstance.stripe.confirmCardSetup(setupIntent.client_secret, {
      payment_method: source.id,
    });
    // console.log(setupResult);

    const { data: addCardResult } = await createPaymentSource({
      source,
      newAccountCredentials,
    });
    // console.log(addCardResult);

    store.commit('SET_CART_LOADING', false);
    return addCardResult;
  } catch (e) {
    store.commit('SET_CART_LOADING', false);
    throw e;
  }
};

export const minRate = (price, wcSettings) => {
  let maxInstallments = 1;
  if (wcSettings.splitit && wcSettings.splitit.ic_installment && wcSettings.splitit.ic_installment[0]) {
    maxInstallments = Math.max(...wcSettings.splitit.ic_installment[0].split(','));
  }
  return price / maxInstallments;
};

export const splititStyle = {
  containerStyle: {
    width: 'auto',
    // background: '#fff739',
    // backgroundColor: '#fff739',
    // fontFamily: '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  buttonStyle: {
    // letterSpacing: '0.02em',
    // backgroundColor: '#15191D',
    // fontFamily: '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    borderRadius: '18px',
    color: 'white',
    whiteSpace: 'nowrap',
    padding: '10px 24px 14px',
  },
  poweredByStyle: {
    display: 'none',
  },
};
