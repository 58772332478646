<template>
  <Flex
    :class="['thumb', 'layout--dark', 'radius']"
    dir="column"
  >
    <Figure
      v-if="data.media.image"
      :data="data.media.image"
      class="media"
    />
    <Spacer
      class="abstract"
      :all="{ default: 's', m: 'm' }"
    >
      <Flex
        dir="column"
        align="h-between"
        class="flex-height"
      >
        <div>
          <Label
            v-if="data.content.label"
            class="date"
            :data="{ value: data.content.label, size: 's' }"
          />
          <Title :data="{ value: data.content.title, tag: data.content.tag_title, size: 'm' }" />
        </div>
        <Spacer :top="{ default: 's2', m: 'l' }">
          <Richtext :data="{ value: data.content.rich_text, size: 'label-m' }" />
        </Spacer>
      </Flex>
    </Spacer>
  </Flex>
</template>

<script>
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'ThumbStory',
  components: {
    Figure,
    Title,
    Label,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;
  overflow: hidden;
  // height: 100%;
}
.media {
  @include aspect-ratio(1.5, 1);

  ::v-deep picture {
    position: absolute;
    width: 100%;
  }
}
.label {
  color: $grey-l;
  text-transform: uppercase;
}
.abstract {
  flex: 1 0 auto;
}
</style>
