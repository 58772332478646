/* eslint-disable no-undef */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Page from '@/views/Page.vue';
import Single from '@/views/Single.vue';
import SingleProject from '@/views/SingleProject.vue';
import SingleProduct from '@/views/SingleProduct.vue';
import SingleStory from '@/views/SingleStory.vue';

const singles = new Map();

singles.set('goliath_box', Single);
singles.set('guide', Single);
singles.set('post', Single);
singles.set('product', SingleProduct);
singles.set('project', SingleProject);
singles.set('story', SingleStory);
singles.set('tutorial', Single);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

store.commit('ADD_LANGUAGES', ['default']);

const routes = [
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/',
    name: 'Index',
    component: Page,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/Cart.vue'),
    meta: {
      customView: true,
    },
  },
  // /cart/?cancel_order=true&order=wc_order_wIm66dL0hWe5a&order_id=165&redirect&_wpnonce=9d9f754376
  {
    path: '/checkout/order-pay/:orderId/',
    name: 'PayOrder',
    component: () => import('@/views/PayOrder.vue'),
    meta: {
      customView: true,
      fetchCountry: true,
    },
  },
  // /checkout/order-pay/260/?pay_for_order=true&key=wc_order_CeFdYg7esp6RQ
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/views/Checkout.vue'),
    meta: {
      customView: true,
      fetchCountry: true,
      hideHeader: true,
    },
  },
  {
    path: '/checkout/order-received/:orderId/',
    name: 'ThankYou',
    component: () => import('@/views/ThankYou.vue'),
    meta: {
      customView: true,
    },
  },
  {
    path: '/register/',
    name: 'Register',
    component: () => import('@/views/account/Register.vue'),
    meta: {
      customView: true,
    },
  },
  {
    path: '/social-logged-in/',
    name: 'SocialLoggedIn',
    component: () => import('@/views/account/SocialLoggedIn.vue'),
    meta: {
      customView: true,
    },
  },
  {
    path: '/my-account/',
    name: 'Account',
    component: () => import('@/views/account/Index.vue'),
    meta: {
      customView: true,
      fetchCountry: true,
    },
    children: [
      {
        path: '/',
        name: 'EditAccount',
        component: () => import('@/views/account/EditAccount.vue'),
        meta: {
          customView: true,
          userPage: true,
          fetchCountry: true,
        },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: () => import('@/views/account/Orders.vue'),
        meta: {
          customView: true,
          userPage: true,
          fetchCountry: true,
        },
      },
    ],
  },
  {
    path: '/preview/:type/:id',
    name: 'Preview',
    component: () => import('@/views/Preview.vue'),
  },
  {
    path: '/community/news/category/:cat',
    name: 'NewsCategory',
    component: Page,
    meta: {
      slug: 'news',
    },
  },
];

Object.keys(postTypes).forEach((key) => {
  if (key !== 'page' && key !== 'product') {
    const type = postTypes[key].rest_base;
    const permalink = postTypes[key].rewrite.slug.replace(/^\/+/g, '');

    routes.push({
      path: `/${permalink}/:slug`,
      name: `${key}-${singles.get(key).name}`,
      component: singles.get(key),
      meta: {
        type,
      },
    });
  }
});

routes.push({
  path: '/shop/products/:slug',
  name: 'Product',
  component: singles.get('product'),
  meta: {
    type: 'product',
  },
});

routes.push({
  path: '/:parent?/:slug',
  name: 'Page',
  component: Page,
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path) return false;
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const promises = [];
  if (to.path !== from.path) {
    store.commit('SET_CURRENT_POST', null);
  }
  store.commit('CLOSE_MENU');

  if (store.state.overlayAffirm) {
    store.commit('SET_OVERLAY_AFFIRM', false);
  }

  if (to.meta.fetchCountry) {
    promises.push(store.dispatch('getCountries'));
  }

  if (to.params.slug === 'shop') {
    const menu = store.getters.menu('navigation').items;
    const shop = menu.find((item) => item.css === 'shop');
    const main = shop.children.find((item) => item.css === 'main');

    router.push(main.url);
  }

  promises.push(
    store.dispatch('getItems', {
      type: 'product',
      params: {
        lang: to.params.lang ? to.params.lang : i18n ? i18n.default : 'default',
      },
    }),
  );

  Promise.all(promises).then(() => {
    if (to.name === '404' || to.name === 'Search' || to.name === 'Preview') {
      document.title = `${to.name} — ${siteTitle}`;
      next();
      return;
    }

    if (to.meta.customView) {
      document.title = `${to.name} — ${siteTitle}`;
      next();
      return;
    }

    const slug = to.meta.slug ? to.meta.slug : to.params.slug;
    const lang = to.params.lang
      ? to.params.lang
      : i18n
        ? i18n.default
        : 'default';

    store.commit('SET_LANG', lang);

    const request = {
      type: to.meta.type || 'pages',
      slug,
      lang,
    };

    store.dispatch('getSingleBySlug', request).then((page) => {
      if (page && slug) {
        if (to.path === '/') {
          document.title = `${siteTitle} — ${description}`;
        } else {
          document.title = `${
            page.name ? page.name : page.title.rendered
          } — ${siteTitle}`;
        }

        next();
      } else {
        router.push('/');
        // router.push({ name: '404' });
      }
    });
  });
});

export default router;
