var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.post.acf.sections)?_c('main',{class:[
    _vm.post.acf.sections[0].blocks[0].acf_fc_layout !== 'hero'
      ? 'main--no-hero'
      : false,
    ("main-theme--" + (_vm.post.acf.page_layout)),
    _vm.breadcrumbs ? 'main--with-breadcrumbs' : false ]},[_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:['section', ("section--" + parentIndex)],attrs:{"layout":_vm.layout(section)}},[_vm._l((section.blocks),function(block,index){return _c(_vm.components.get(block.acf_fc_layout),{key:index,tag:"component",class:[
        'block',
        ("block--" + (block.acf_fc_layout)),
        block.acf_fc_layout === 'media' ? ("block--" + (block.type)) : false,
        section.boxed_elements ? 'block--boxed' : false ],attrs:{"data":Object.assign(block, { section_layout: _vm.layout(section) })}})}),(section.section_id === 'download-slingshot')?_c('FormSlingshot'):_vm._e()],2)}),(_vm.banner && _vm.post.acf.section_banner)?_c('Section',{staticClass:"section-community spacer--section-large",attrs:{"layout":{
      section_layout: _vm.banner.layout,
    }}},[_c(_vm.components.get('content'),{tag:"component",class:['block', 'block--content'],attrs:{"data":Object.assign(_vm.banner, {
          section_layout: {
            section_classes: 'section-community',
            section_wrapper: 'default',
          },
        })}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }