<template>
  <transition
    name="cookie"
    appear
  >
    <div
      v-show="!cookie && init"
      class="cookie layout--primary"
    >
      <Spacer
        :x="{ default: 's', l: 'xl' }"
        :y="{ default: 's', l: 'm' }"
      >
        <Icon
          name="close"
          :size="isMobile ? 'xs' : 's'"
          theme="minimal"
          :fn="closeCookie"
        />
        <Flex class="flex-wrap-cookie">
          <Richtext
            :data="{
              value: options.cookies ? options.cookies.text : '',
            }"
          />
          <Spacer
            :top="{ default: 's2', l: 0 }"
            :left="{ default: 0, l: 's2' }"
          >
            <Flex class="ctas">
              <Cta
                :data="{
                  title: $labels.accept_cookie,
                  fn: acceptCookie,
                  size: 's'
                }"
                theme="underline"
              />
            </Flex>
          </Spacer>
        </Flex>
      </Spacer>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

import { loadScript, is } from '@/assets/js/utils';

import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';

export default {
  name: 'Cookie',
  components: {
    Richtext,
    Cta,
    Icon,
  },
  data() {
    return {
      init: false,
      cookie: !!Cookies.get('goliath-cookie'),
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'transition', 'options']),
  },
  watch: {
    transition(val) {
      if (!val) this.init = true;
    },
  },
  methods: {
    is,
    closeCookie() {
      this.cookie = true;
      if (this.$gtag) {
        // this.$gtag.query('consent', 'default', {
        //   ad_storage: 'denied',
        //   analytics_storage: 'denied',
        // });
      }
    },

    acceptCookie() {
      Cookies.set('goliath-cookie', '1', { expires: 365 });
      this.cookie = true;

      if (this.options.scripts && this.options.scripts.scripts_repeater) {
        this.options.scripts.scripts_repeater.forEach((item) => {
          if (!item.init) {
            if (item.inline) {
              const s = document.createElement('span');
              s.style.display = 'none';
              s.innerHTML = item.script;
              document.getElementsByTagName('body')[0].appendChild(s);
            } else {
              loadScript(item.script_src);
            }
          }
        });
      }

      if (this.$gtag) {
        // this.$gtag.config(
        //   {
        //     params: {
        //       anonymize_ip: false,
        //       send_page_view: false,
        //     },
        //   },
        // );

        // this.$gtag.query('consent', 'default', {
        //   ad_storage: 'granted',
        //   analytics_storage: 'granted',
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-enter-active,
.cookie-leave-active {
  transition: all 0.8s $ease-custom;
}

.cookie-enter,
.cookie-leave-to {
  transform: translateY(100%) translateY(30px);
}

.cookie {
  position: fixed;
  z-index: 2000;
  left: 0;
  bottom: 0;
  width: 100%;

  .icon {
    position: absolute;
    right: 9px;
    top: 9px;

    @include mq(l) {
      right: map-get($spacer, xl);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .flex-wrap-cookie {
    flex-direction: column;

    @include mq(l) {
      flex-direction: row;
    }
  }

  .typo--label-s {
    color: $grey-l;
  }
}
</style>
