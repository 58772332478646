<template>
  <div
    :class="[
      'cta',
      data.size ? `typo--${data.size}` : null,
      data.theme || theme ? `theme--${data.theme || theme}` : null,
      disableHover ? 'cta-no-hover' : null,
      disabled || data.disabled ? 'cta-disabled' : null,
      data.classes,
    ]"
  >
    <template v-if="data.fn && !data.url">
      <button @click="data.fn">
        <Flex align="center">
          <div v-html="data.title" />
          <slot
            v-if="data.toggle"
            :name="data.toggle"
          />
        </Flex>
      </button>
    </template>
    <template v-else-if="hash || modalYt">
      <button @click="goTo">
        <Flex align="center">
          <div v-html="data.title" />
          <slot
            v-if="data.toggle"
            :name="data.toggle"
          />
        </Flex>
      </button>
    </template>
    <template v-else-if="data.target === '_blank'">
      <a
        :href="data.url"
        :target="data.target"
        @click="data.fn ? data.fn() : undefined"
      >
        <Flex align="center">
          <div v-html="data.title" />
          <slot
            v-if="data.toggle"
            :name="data.toggle"
          />
        </Flex>
      </a>
    </template>
    <template v-else>
      <router-link
        :to="url"
        @click.native="data.fn ? data.fn() : undefined"
      >
        <Flex align="center">
          <div v-html="data.title" />
          <slot
            v-if="data.toggle"
            :name="data.toggle"
          />
        </Flex>
      </router-link>
    </template>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (
        this.data.url
          && typeof this.data.url === 'string'
          && process.env.VUE_APP_BASE_URL
          && process.env.VUE_APP_BASE_URL !== '/'
      ) {
        return this.data.url.replace(process.env.VUE_APP_BASE_URL, '');
      }
      return this.data.url;
    },
    hash() {
      return (
        this.data.url
          && typeof this.data.url === 'string'
          && this.data.url.startsWith('#')
      );
    },
    modalYt() {
      return (
        this.data.url
          && typeof this.data.url === 'string'
          && this.data.url.includes('youtube.com')
      );
    },
  },
  methods: {
    goTo() {
      const modal = this.data.url.startsWith('#modal-');
      const modalYt = this.data.url
          && typeof this.data.url === 'string'
          && this.data.url.includes('youtube.com');
      if (modal) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      } else if (modalYt) {
        this.$bus.$emit('modal', {
          type: 'video',
          url: this.data.url,
        });
      } else {
        const hash = this.data.url;
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 112,
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
    display: inline-block;

    .cta:not(.theme--button-large):not(.theme--button):not(.theme--tool):not(.theme--shop):not(.theme--shop--large)
      & {
      &.router-link-active {
        div {
          opacity: 0.5;
        }
      }
    }
  }

  .cta-disabled {
    cursor: progress;
    opacity: 0.5;

    * {
      cursor: progress !important;
      pointer-events: none !important;
    }
  }

  .cta-inactive {
    opacity: 0.5;
    &:hover {
      color: $black;
      opacity: 1;
    }
  }

  .cta.cta-active {
    color: $black;
    &:hover {
      color: $black;
    }
  }

  button {
    font-size: inherit;
  }

  .cta {
    a,
    button {
      display: inline-block;
    }

    &:not([class*="typo--"]) {
      a,
      button {
        @extend %typo--label-m;
      }
    }

    &.theme--button,
    &.theme--button--oos {
      a,
      button {
        letter-spacing: 0.02em;
        border-radius: var(--radius);
        background: $primary;
        color: $black;
        white-space: nowrap;

        padding: 10px 24px 14px;
        @include mq(m) {
          line-height: 24px;
          padding: 11px 42px 13px;
        }
      }
    }
    &.theme--button {
      &-large {
        a,
        button {
          padding: 10px 24px 14px;
          background: $primary;
          border-radius: var(--radius);
          white-space: nowrap;
          color: $black;
          letter-spacing: 0.02em;

          @include mq(m) {
            font-size: 24px;
            line-height: 28px;
            padding: 18px 48px 20px;
            border-radius: 20px;
          }
        }
      }

      &--full {
        padding: map-get($spacer, "xs");

        a,
        button {
          background: $primary;
          border-radius: 10px;
          color: $black;
          padding: 10px 24px 14px;
          width: 100%;

          @include mq(m) {
            padding: 11px 42px 13px;
          }
        }
      }

      &--oos {
        a,
        button {
          background: $grey;
          color: $white;
        }
      }
    }

    &:not(.cta-no-hover) {
      &.theme--button,
      &.theme--button-large,
      &.theme--tool,
      &.theme--default {
        a,
        button {
          @include mq($and: $hover) {
            div {
              transition: opacity 0.2s $ease-custom;
            }
            &:hover {
              div {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    &.theme--underline {
      a,
      button {
        position: relative;
        &::after {
          content: "";
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: currentColor;
          position: absolute;
          transform: translate3d(0, 5px, 0);
          transition: opacity 0.2s $ease-custom;
        }

        @include mq($and: $hover) {
          &:hover {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }

    &.theme--tool {
      a,
      button {
        height: 40px;
        line-height: 36px;
        border-radius: 10px;
        padding: 0 15px;
        background: $black;
        color: $primary;

        &.router-link-active {
          opacity: 1;
        }
      }
    }

    &.theme--shop {
      // display: none;
      a,
      button {
        background: $black;
        border-radius: 10px;
        color: $primary;
        display: grid;
        height: 40px;
        padding: 0 15px;
      }
    }

    &.theme--shop--large {
      a,
      button {
        background: $black;
        border-radius: var(--radius);
        color: $primary;
        font-size: 36px;
        line-height: 90%;
        padding: map-get($spacer, "xs") map-get($spacer, "s");
        padding-bottom: 14px;
      }
    }
  }
</style>
