<template>
  <transition name="fade">
    <div
      v-if="isTouch && (authLoading || cartLoading)"
      class="app-loader"
    >
      <div class="app-loader__line" />
      <div class="progress-materializecss">
        <div class="indeterminate" />
      </div>

      <!-- <Flex
        class="app-loader__wrapper"
        :align="{
          v: 'center',
          h:'center'
        }"
      >
        <div class="app-loader__spinner" />
      </Flex> -->
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  components: {
  },
  computed: {
    ...mapGetters(['authLoading', 'cartLoading', 'isTouch']),
  },
  methods: {

  },
};
</script>

<style lang="scss">
.app-loader {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  // background: rgba(255,255,255,0.3);
}

.app-loader__wrapper {
  width: 100%;
  height: 100%;
}

// CIRCULAR SPINNER

.app-loader__spinner,
.app-loader__spinner:after {
  border-radius: 50%;
  width: $unit*4;
  height: $unit*4;
}
.app-loader__spinner {
  font-size: 0px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0);
  border-right: 2px solid rgba(255, 255, 255, 0);
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  border-left: 2px solid $black;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// lINE

.progress-materializecss {
  position: fixed;
  width: 100%;
  z-index: 3000;
  height: 3px;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  background-color: transparent;
  margin: 0;
  overflow: hidden;
  .indeterminate {
    background-color: $primary-d;
    &:before{
      content:          '';
      position:         absolute;
      background-color: inherit;
      top:              0;
      left:             0;
      bottom:           0;
      will-change:      left, right;
      animation:        indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    &:after {
      content:          '';
      position:         absolute;
      background-color: inherit;
      top:              0;
      left:             0;
      bottom:           0;
      will-change:      left, right;
      animation:        indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
    }
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

</style>
