<template>
  <div>
    <div
      class="form-wrapper__header"
    >
      <div class="form-wrapper__title">
        <slot name="form-title" />
      </div>
    </div>

    <slot name="form-groups" />
  </div>
</template>

<script>
export default {
  name: 'DefaultWrapper',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
