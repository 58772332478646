<template>
  <main
    v-if="post.acf.sections"
    :class="[
      post.acf.sections[0].blocks[0].acf_fc_layout !== 'hero'
        ? 'main--no-hero'
        : false,
      `main-theme--${post.acf.page_layout}`,
      breadcrumbs ? 'main--with-breadcrumbs' : false,
    ]"
  >
    <Section
      v-for="(section, parentIndex) in post.acf.sections"
      :key="parentIndex"
      :layout="layout(section)"
      :class="['section', `section--${parentIndex}`]"
    >
      <component
        :is="components.get(block.acf_fc_layout)"
        v-for="(block, index) in section.blocks"
        :key="index"
        :class="[
          'block',
          `block--${block.acf_fc_layout}`,
          block.acf_fc_layout === 'media' ? `block--${block.type}` : false,
          section.boxed_elements ? 'block--boxed' : false,
        ]"
        :data="Object.assign(block, { section_layout: layout(section) })"
      />

      <FormSlingshot v-if="section.section_id === 'download-slingshot'" />
    </Section>

    <Section
      v-if="banner && post.acf.section_banner"
      :layout="{
        section_layout: banner.layout,
      }"
      class="section-community spacer--section-large"
    >
      <component
        :is="components.get('content')"
        :class="['block', 'block--content']"
        :data="
          Object.assign(banner, {
            section_layout: {
              section_classes: 'section-community',
              section_wrapper: 'default',
            },
          })
        "
      />
    </Section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import components from '@/assets/js/components';
import data from '@/mixins/data';

import Section from '@/components/section';
import FormSlingshot from '@/components/custom/form-slingshot';

export default {
  name: 'Page',
  components: {
    Section,
    FormSlingshot,
  },
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  computed: {
    ...mapGetters(['banner', 'breadcrumbs']),
  },
  methods: {
    layout(section) {
      const {
        section_background,
        section_classes,
        section_layout,
        section_layout_custom,
        section_text,
        section_id,
        section_column,
        section_wrapper,
        section_spacer,
      } = section;

      const is_hero = section.blocks.filter((block) => block.acf_fc_layout === 'hero')
        .length > 0;

      return {
        section_background,
        section_classes,
        section_layout,
        section_layout_custom,
        section_text,
        section_id,
        section_column,
        section_wrapper,
        section_spacer,
        is_hero,
      };
    },
  },
};
</script>
