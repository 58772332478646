/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import useAuthorizationAndRetryMiddleware from './auth';

// eslint-disable-next-line no-undef
let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

const urlWp = `${url}/wp-json/`;

const ajax = axios.create(
  {
    baseURL: urlWp,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
);
useAuthorizationAndRetryMiddleware(ajax);

export const fetchSingle = ({ type, params = {} }) => {
  if (type === 'product') {
    return ajax.get(`gds/v1/wc/products/${params.slug}`);
  }
  return ajax.get(`wp/v2/${type}/`, { params });
};

export const fetchSearch = (search) => ajax.get(`wp/v2/search/?search=${search}`);

export const fetchPrivacyPolicy = (id) => axios.get(`https://www.iubenda.com/api/privacy-policy/${id}`, {});

export const sendAjax = (request) => {
  const formData = new FormData();

  formData.append('action', 'do_ajax');
  formData.append('fn', request.fn);
  formData.append('params', JSON.stringify(request.params));

  return axios.post(`${url}/wp-admin/admin-ajax.php`, formData);
};

export const fetchItems = ({ type, params = {} }) => {
  if (type === 'product') {
    return ajax.get('gds/v1/wc/products/', { params });
  }
  return ajax.get(`wp/v2/${type}/`, { params });
};

export const sendSlingShotCode = ({ email, params }) => axios.get(`https://oyj29wiwj3.execute-api.eu-west-3.amazonaws.com/Prod/serials/${email}`, { params });

// WooCommerce - GDS

export const fetchVariations = ({ params }) => ajax.get(`gds/v1/wc/products/${params.slug}/variations/`); // , { params: null }

export const getCountries = () => ajax.get('gds/v1/wc/countries/', { params: null });

// export const getPaymentGateways = () => ajax.get('gds/v1/wc/payment_gateways/', { params: null });

export const createOrder = async (body) => {
  try {
    return await ajax.post('gds/v1/wc/orders/', body, { params: null });
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const getOrder = async (params) => ajax.get(`gds/v1/wc/orders/${params.order_id}/`, { params });

// export const deleteOrder = async (params) => ajax.delete(`gds/v1/wc/orders/${params.order_id}/`, { params });
export const cancelOrder = async (params) => ajax.delete(`gds/v1/wc/orders/${params.order_id}/`, { params });

export const getOrders = async (params) => ajax.get('gds/v1/wc/orders/', { params });

export const createUser = async (body) => ajax.post('gds/v1/wc/account/', body, { params: null });

export const getUser = async (body) => ajax.get('gds/v1/wc/account/', body, { params: null });

export const getCustomer = async (body) => {
  try {
    return await ajax.get('gds/v1/wc/customer/', body, { params: null });
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const updateAddress = async (body) => ajax.put('gds/v1/wc/customer/', body);

export const updateUser = async (body) => ajax.put('gds/v1/wc/account/', body);

export const addCoupon = async (body) => {
  try {
    return await ajax.post('gds/v1/wc/checkout/add-coupon/', body);
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const removeCoupon = async (body) => {
  try {
    return await ajax.post('gds/v1/wc/checkout/remove-coupon/', body);
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const setShippingMethod = async (body) => {
  try {
    return await ajax.post('gds/v1/wc/checkout/shipping-method/', body);
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const createPaymentIntent = async (body) => ajax.post('gds/v1/wc/checkout/stripe-pi/', body, { params: null });

export const updatePaymentIntent = async (clientSecret) => ajax.put(`gds/v1/wc/checkout/payment-intent/${clientSecret}/`);

export const updatePaymentMethod = async (body, data) => {
  try {
    return await ajax.put(`gds/v1/wc/orders/${data.orderId}/pay`, body, { params: { order_key: data.orderKey } });
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const createSetupIntent = async ({
  source,
  newAccountCredentials,
}) => {
  try {
    return await ajax.post('gds/v1/wc/customer/stripe-si/', {
      source,
      newAccountCredentials,
    }, { params: null });
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const assignSourceToCustomer = async ({
  source,
  newAccountCredentials,
}) => {
  try {
    return await ajax.post('gds/v1/wc/customer/stripe-source/', {
      source,
      newAccountCredentials,
    }, { params: null });
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const createPaymentSource = async ({
  source,
  newAccountCredentials,
}) => {
  try {
    return await ajax.post('gds/v1/wc/customer/payment-source/', {
      source,
      newAccountCredentials,
    }, { params: null });
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const setDefaultPaymentSource = async ({ payment_source_id }) => {
  try {
    return await ajax.patch(`gds/v1/wc/customer/payment-source/${payment_source_id}/default/`);
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const deletePaymentSource = async ({ payment_source_id }) => {
  try {
    return await ajax.delete(`gds/v1/wc/customer/payment-source/${payment_source_id}/`);
  } catch (error) {
    const { response } = error;
    return response;
  }
};

// Cart

export const getCart = () => ajax.get('cocart/v1/get-cart/', { params: { thumb: true } });

export const calculateCart = async () => ajax.post('cocart/v1/calculate/', {
  return: true,
});
// return ajax.get('cocart/v1/totals/', {
//   params: {
//     html: false,
//   },
// });
// ;

export const addToCart = ({ payLoad }) => ajax.post('cocart/v1/add-item/', payLoad);

export const updateCartItem = async ({ payLoad }) => {
  try {
    return await ajax.post('cocart/v1/item/', payLoad);
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const removeCartItem = async ({ payLoad }) => {
  try {
    return await ajax.delete('cocart/v1/item/', { data: payLoad });
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const clearCart = async () => {
  const { status } = await ajax.post('cocart/v1/clear/');
  return status === 200;
};

// Cart Pro
// https://docs.cocart.xyz/pro.html

export const getCartPaymentGateways = () => ajax.get('cocart/v1/payment-methods/', { params: null });

export const setPaymentGateway = (body) => ajax.post('cocart/v1/payment-methods/', body);

// export const getShippingTotal = () => ajax.get('cocart/v1/totals/shipping', { params: null });

export const calculateShippingMethods = async (params) => {
  try {
    return await ajax.post('cocart/v1/calculate/shipping/', params);
  } catch (error) {
    const { response } = error;
    return response;
  }
};

export const getCoupons = async () => ajax.get('cocart/v1/coupon/');

// preview
export const fetchSingleById = ({ type, params = {} }) => {
  if (type === 'product') {
    return ajax.get(`gds/v1/wc/productsById/${params.id}`);
  }
  return ajax.get(`wp/v2/${type}/${params.id}`);
};

export const trackCheckout = () => ajax.get('gds/v1/wc/track?event=checkout');
export const trackThxPage = ({ order_id }) => ajax.get(`gds/v1/wc/track?event=thx-page&order_id=${order_id}`);

export const createAffirmCharge = async ({ checkoutToken, orderId }) => ajax.post('gds/v1/wc/checkout/affirm-charge/', {
  checkout_token: checkoutToken,
  order_id: orderId,
});
