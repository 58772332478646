export default {
  ADD_ITEM(state, { type, item, lang }) {
    if (item && type && !state.wp[type][lang][item.slug]) {
      state.wp[type][lang][item.slug] = item;
    }
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      state.wp.pages[lang] = {};

      Object.keys(state.wp.postTypes).forEach((key) => {
        if (state.wp.postTypes[key].rest_base) {
          const restBase = state.wp.postTypes[key].rest_base;
          state.wp[restBase][lang] = {};
        }
      });

      // taxonomies
      state.wp['tutorial-category'][lang] = {};
      state.wp.categories[lang] = {};
      state.wp.product_cat[lang] = {};
    });
  },
  ADD_REQUEST(state, { type, request }) {
    state.wp[type].requests.push(request);
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TOUCH(state, value) {
    state.touch = value;
  },
  SET_BREAKPOINTS(state, value) {
    state.breakpoints = value;
  },
  SET_MOBILE(state, value) {
    state.mobile = value;
  },
  SET_MOBILE_S(state, value) {
    state.mobileS = value;
  },
  SET_MOBILE_XS(state, value) {
    state.mobileXS = value;
  },
  SET_LANG(state, value) {
    state.lang = value;
  },
  TOGGLE_MENU(state) {
    state.menuOpened = !state.menuOpened;
  },
  OPEN_MENU(state) {
    state.menuOpened = true;
  },
  CLOSE_MENU(state) {
    state.menuOpened = false;
  },
  SET_BREADCRUMBS(state, value) {
    state.breadcrumbs = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_OVERLAY_AFFIRM(state, value) {
    state.overlayAffirm = value;
  },
};
