/* eslint-disable */

import Flickity from 'flickity';
const fizzyUIUtils = require('fizzy-ui-utils');

Flickity.createMethods.push('_createPrevNextCells');

Flickity.prototype._createPrevNextCells = function() {
  this.on( 'select', this.setPrevNextCells );
};

Flickity.prototype.setPrevNextCells = function() {
  // remove classes
  changeSlideClasses( this.previousSlide, 'remove', 'is-previous' );
  changeSlideClasses( this.nextSlide, 'remove', 'is-next' );
  // set slides
  var previousI = fizzyUIUtils.modulo( this.selectedIndex - 1, this.slides.length );
  var nextI = fizzyUIUtils.modulo( this.selectedIndex + 1, this.slides.length );
  this.previousSlide = this.slides[ previousI ];
  this.nextSlide = this.slides[ nextI ];
  // add classes
  changeSlideClasses( this.previousSlide, 'add', 'is-previous' );
  changeSlideClasses( this.nextSlide, 'add', 'is-next' );
};

function changeSlideClasses( slide, method, className ) {
  if ( !slide ) {
    return;
  }
  slide.getCellElements().forEach( function( cellElem ) {
    cellElem.classList[ method ]( className );
  });
}