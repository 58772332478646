<template>
  <Wrapper size="l">
    <Wrapper
      class="faq"
      size="l"
      boxed
    >
      <Grid :col="{ default: 6, m: 4 }">
        <ThumbIconLabel
          v-for="(topic, index) in data.topic"
          :id="kebabCase(topic.title)"
          :key="index"
          :class="[
            'topic',
            active >= 0 && active !== index ? 'topic-not-selected' : false,
          ]"
          :data="{
            media: topic.icon,
            content: {
              label: topic.title,
              layout: 'dark',
            },
          }"
          @click.native="() => showAccordion(index, kebabCase(topic.title))"
        />
      </Grid>
    </Wrapper>
    <Spacer
      v-show="active >= 0"
      ref="accordion"
      class="accordion"
      top="xl"
    >
      <Accordion
        v-if="active >= 0"
        :key="active"
        :data="data.topic[active]"
        :active-item="activeItem"
      />
    </Spacer>
  </Wrapper>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';
import kebabCase from 'lodash.kebabcase';
import { mapGetters } from 'vuex';

import ThumbIconLabel from '@/components/thumb/icon-label';
import Accordion from '@/components/blocks/accordion';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'FAQ',
  components: {
    ThumbIconLabel,
    Accordion,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: -1,
      activeItem: null,
      kebabCase,
    };
  },
  computed: {
    ...mapGetters(['transition']),
  },
  watch: {
    transition(val) {
      if (!val) {
        this.data.topic.forEach((topic, index) => {
          if (`#${kebabCase(topic.title)}` === this.$route.hash) {
            this.active = index;
            this.activeItem = 0;

            this.$nextTick(() => {
              gsap.killTweensOf(window);
              gsap.to(window, {
                scrollTo: {
                  y: document.querySelector(
                    `#${kebabCase(topic.items[0].title_trigger)}`,
                  ),
                  offsetY: 112,
                  autoKill: true,
                },
                duration: 1,
                ease: 'expo.out',
              });
            });
          }

          topic.items.forEach((item, itemIndex) => {
            if (`#${kebabCase(item.title_trigger)}` === this.$route.hash) {
              this.active = index;
              this.activeItem = itemIndex;

              this.$nextTick(() => {
                gsap.killTweensOf(window);
                gsap.to(window, {
                  scrollTo: {
                    y: document.querySelector(
                      `#${kebabCase(item.title_trigger)}`,
                    ),
                    offsetY: 112,
                    autoKill: true,
                  },
                  duration: 1,
                  ease: 'expo.out',
                });
              });
            }
          });
        });
      }
    },
  },
  methods: {
    showAccordion(index, id) {
      if (index === this.active) {
        this.active = -1;
        this.$router.replace({
          hash: null,
        });
      } else {
        this.$router.replace({
          hash: `#${id}`,
        });

        this.$nextTick(() => {
          gsap.killTweensOf(window);
          gsap.to(window, {
            scrollTo: {
              autoKill: true,
              y: this.$refs.accordion ? this.$refs.accordion.$el : 0,
              offsetY: 112,
            },
            duration: 1,
            ease: 'expo.out',
          });
        });

        this.active = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .topic {
    cursor: pointer;
    transition: opacity 0.2s $ease-custom;

    &-not-selected {
      opacity: 0.5;
    }

    @include mq($and: $hover) {
      &:hover {
        opacity: 1;
      }
    }
  }
</style>
