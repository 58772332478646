var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Wrapper',{attrs:{"size":"l"}},[_c('Wrapper',{staticClass:"faq",attrs:{"size":"l","boxed":""}},[_c('Grid',{attrs:{"col":{ default: 6, m: 4 }}},_vm._l((_vm.data.topic),function(topic,index){return _c('ThumbIconLabel',{key:index,class:[
          'topic',
          _vm.active >= 0 && _vm.active !== index ? 'topic-not-selected' : false ],attrs:{"id":_vm.kebabCase(topic.title),"data":{
          media: topic.icon,
          content: {
            label: topic.title,
            layout: 'dark',
          },
        }},nativeOn:{"click":function($event){return (function () { return _vm.showAccordion(index, _vm.kebabCase(topic.title)); })($event)}}})}),1)],1),_c('Spacer',{directives:[{name:"show",rawName:"v-show",value:(_vm.active >= 0),expression:"active >= 0"}],ref:"accordion",staticClass:"accordion",attrs:{"top":"xl"}},[(_vm.active >= 0)?_c('Accordion',{key:_vm.active,attrs:{"data":_vm.data.topic[_vm.active],"active-item":_vm.activeItem}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }