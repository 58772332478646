<template>
  <div
    ref="gallery"
    :class="[
      'gallery',
      extraSettings && extraSettings.template
        ? `gallery-template--${extraSettings.template}`
        : false,
    ]"
  >
    <template v-if="!data.disableCarousel">
      <Flex
        v-for="(item, index) in extraSettings.wrapAround
          ? [...data, ...data]
          : data"
        :key="index"
        class="item"
        align="v-center"
      >
        <Figure
          :ref="index"
          :data="
            Object.assign(item, {
              full_height: false,
              lazyload: false,
              ratio: false,
              object_fit: 'cover',
              border: extraSettings && !extraSettings.template === 'project',
            })
          "
        />
      </Flex>

      <div class="next">
        <Icon
          :fn="goTo"
          name="arrow-right"
          size="l"
        />
      </div>

      <div
        v-if="
          extraSettings &&
            (extraSettings.template === 'project' ||
              extraSettings.template === 'full')
        "
        class="prev"
      >
        <Icon
          :fn="goToPrev"
          name="arrow-left"
          size="l"
        />
      </div>
    </template>
    <template v-if="data.disableCarousel">
      <Wrapper
        size="xl"
        boxed
      >
        <Grid :col="{ default: 12, m: data.length < 3 ? 12 / data.length : 4 }">
          <Figure
            v-for="(item, index) in data"
            :key="index"
            :ref="index"
            :data="
              Object.assign(item, {
                full_height: false,
                lazyload: true,
                ratio: true,
                object_fit: 'contain',
              })
            "
          />
        </Grid>
      </Wrapper>
    </template>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';
import Icon from '@/components/ui/icon';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
    Icon,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        contain: true,
      },
    };
  },
  mounted() {
    if (!this.data.disableCarousel) {
      this.gallery = new Flickity(
        this.$refs.gallery,
        Object.assign(this.settings, this.extraSettings),
      );
    }
  },
  beforeUnmount() {
    if (this.gallery) this.gallery.destroy();
  },
  methods: {
    goTo() {
      this.gallery.next('true');
    },
    goToPrev() {
      this.gallery.previous();
    },
  },
};
</script>

<style lang="scss">
  .gallery {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 50vw;
      height: 100%;
      left: -50vw;
      pointer-events: none;
      background: $black;
    }

    .flickity-viewport {
      overflow: visible;
    }
    .item {
      width: 60vw;
      margin-right: map-get($spacer, s);
      @include aspect-ratio(3.5, 4);

      @include mq(m) {
        width: 27vw;
      }

      .picture {
        width: 100%;
        height: 100%;
      }
    }

    .next {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      z-index: 2;
    }

    .prev {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      z-index: 2;
    }

    &-template--project {
      .item {
        min-height: 100%;
        width: 80vw;
        @include aspect-ratio(4, 2.25);
        margin-right: map-get($spacer, s);

        // transform: scale(0.9);
        // // transition: transform 0.5s ease-out;

        // &.is-selected {
        //   transform: scale(1);
        //   transform-origin: 50% 50%;
        // }

        // &.is-previous {
        //   transform-origin: 100% 50%;
        // }

        // &.is-next {
        //   transform-origin: 0% 50%;
        // }
      }

      .prev {
        left: map-get($spacer, xxs);
        @include mq(m) {
          left: map-get($spacer, m);
        }
      }
      .next {
        right: map-get($spacer, xxs);
        @include mq(m) {
          right: map-get($spacer, m);
        }
      }
    }

    &-template--full {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      &::after {
        display: none;
      }

      .item {
        width: 80%;
        @include aspect-ratio(4, 2.25);
      }

      .prev {
        left: map-get($spacer, xxs);
        @include mq(m) {
          left: map-get($spacer, m);
        }
      }

      .next {
        right: map-get($spacer, xxs);
        @include mq(m) {
          right: map-get($spacer, m);
        }
      }
    }
  }
</style>
