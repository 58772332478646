import Medusa from '@adoratorio/medusa';

require('intersection-observer');

const medusa = {
  ref: null,
  init: () => {
    medusa.ref = new Medusa({
      debug: false,
    });
  },
};

export default medusa;
