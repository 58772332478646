<template>
  <router-link :to="data.link">
    <Figure
      v-if="data.gds_featured_image"
      :data="Object.assign(data.gds_featured_image, { caption: null, full_height: false, lazyload: true, ratio: false, object_fit: 'cover' })"
      class="media"
    />
    <div
      v-if="!data.gds_featured_image"
      class="media skeleton"
    />
    <Spacer
      class="abstract"
      :y="{ default: 'xxs', m: 'xs' }"
    >
      <Flex
        class="meta typo--label-s"
      >
        <Label
          v-if="categories"

          :data="{ value: categories, size: 's' }"
        />
        <span v-show="categories"> — </span>
        <Label
          v-if="data.date_format"
          :data="{ value: data.date_format, size: 's' }"
        />
      </Flex>
      <Spacer top="xxxs">
        <div class="title-wrapper">
          <Title :data="{ value: data.title.rendered, size: 'label-m' }" />
        </div>
      </Spacer>
    </Spacer>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    categories() {
      return this.data.gds_taxonomies && this.data.gds_taxonomies.categories ? this.data.gds_taxonomies.categories.map((category) => category.cat_name).join(', ') : false;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: block;
}
.media {
  @include aspect-ratio(4, 3);

  ::v-deep picture {
    position: absolute;
    width: 100%;
  }

  &.skeleton {
    background: $grey-d;
  }
}
.meta {
  color: $grey-l;
  text-transform: uppercase;
  span {
    margin: 0 6px;
  }
}
</style>
