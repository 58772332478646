<template>
  <div
    ref="carousel"
    class="box-details"
  >
    <div
      v-for="item in post.carousel"
      :key="item.id"
      class="item"
    >
      <Figure
        v-if="item.acf.cover"
        :data="{ ...item.acf.cover, ratio: false, theme: 'box-detail' }"
      />
      <div class="bottom">
        <Title
          :data="{
            value: item.title.rendered,
            tag: 'h3',
            size: 'l',
          }"
        />
        <Richtext
          v-if="item.acf.description"
          :data="{ value: item.acf.description, size: 'label-m' }"
        />
        <div
          v-if="item.acf.details && item.acf.details.length > 0"
          class="details"
        >
          <div
            v-for="(detail, index) in item.acf.details"
            :key="index"
          >
            <Title
              :data="{
                value: detail.title,
                tag: 'h5',
                size: 'xs',
              }"
              theme="box-detail"
            />
            <Richtext
              v-if="detail.content"
              :data="{ value: detail.content, size: 'label-m' }"
              theme="no-space"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';

export default {
  components: {
    Figure,
    Richtext,
    Title,
  },
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carousel: null,
    };
  },
  mounted() {
    const selected = this.post.carousel.findIndex(
      (item) => item.id === this.post.id,
    );
    const settings = {
      adaptiveHeight: true,
      cellSelector: '.item',
      contain: true,
      draggable: true,
      initialIndex: selected,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
    };
    this.$nextTick(() => {
      this.carousel = new Flickity(this.$refs.carousel, settings);
    });
  },
  beforeUnmount() {
    this.carousel.destroy();
  },
  methods: {
    previous() {
      this.carousel.previous(true, false);
    },
    next() {
      this.carousel.next(true, false);
    },
  },
};
</script>

<style lang="scss">
  .box-details {
    .flickity-viewport {
      transition: height 0.4s $ease-custom;
    }
    .item {
      width: 100%;

      .bottom {
        display: grid;
        padding: map-get($spacer, "s");
        row-gap: map-get($spacer, "s");

        @include mq(m) {
          padding: map-get($spacer, "l");
        }

        .details {
          align-items: start;
          column-gap: map-get($spacer, "s");
          display: grid;
          row-gap: map-get($spacer, "s");

          @include mq(m) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }
      }
    }
  }
</style>
