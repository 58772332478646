<template>
  <Spacer
    class="typo--m title-wrapper"
    top="s"
  >
    {{ $labels[data.value] }}
  </Spacer>
</template>

<script>

export default {
  name: 'Title',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  padding-bottom: 10px;
  @include mq(m) {
    padding-bottom: 22px;
  }
}
</style>
