<template>
  <nav
    :class="['nav', menuOpened ? 'menu--active' : false, 'typo--menu']"
    @mouseleave="() => (!isMobile ? setActive(null) : {})"
  >
    <Spacer
      :top="{ default: 's', m: 0 }"
      :bottom="{ default: 'm', m: 0 }"
    >
      <Flex
        tag="ul"
        align="h-center"
        :dir="{ default: 'column', m: 'row' }"
      >
        <li
          v-for="item in navigation"
          :key="item.id"
          :class="[
            'item-primary',
            routerActive === kebabCase(item.content) ? 'item--active' : false,
            active === item.id ? 'item--hover' : false,
            item.css,
          ]"
          @mouseenter="() => (!isMobile ? setActive(item.id) : {})"
          @mouseleave="() => (!isMobile ? setActive(null) : {})"
          @click="() => (isMobile ? setActive(item.id) : {})"
        >
          <Spacer :template="item.css === 'shop' ? 'menu-highlight' : 'menu'">
            <Flex
              :align="{ default: 'h-between-v-center', m: 'h-left' }"
              :inline="!isMobile"
            >
              <Cta
                v-if="item.css === 'shop'"
                :data="{
                  url: item.url,
                  title: item.content,
                }"
                :theme="isMobile ? 'shop--large' : 'shop'"
              />
              <div v-else>
                {{ item.content }}
              </div>

              <Icon
                v-if="item.children.length > 0"
                name="arrow-down"
                :fn="() => (isMobile ? setActive(item.id) : {})"
                class="u-hidden--from-m"
                size="m2"
                theme="minimal"
                :class="[
                  'u-hidden--from-m',
                  active === item.id ? 'icon-arrow-down--rotate' : false,
                ]"
              />
            </Flex>
          </Spacer>

          <Flex
            v-if="item.children.length > 0 && isMobile"
            dir="column"
            tag="ul"
            :class="[
              'u-hidden--from-m',
              'submenu--mobile',
              active === item.id ? 'submenu--mobile--active' : false,
            ]"
          >
            <Spacer
              v-for="child in item.children"
              :key="child.id"
              tag="li"
              y="xxs"
              :class="[child.css]"
            >
              <Cta
                :data="{
                  url: child.url,
                  title: child.content,
                  target: child.target,
                  size: 'label-m',
                }"
              />
            </Spacer>
          </Flex>
        </li>
      </Flex>

      <div
        v-if="!isMobile"
        :class="['submenu', active ? 'submenu--active' : false]"
      >
        <div
          v-for="item in navigation"
          v-show="active === item.id"
          :key="`submenu-${item.id}`"
          @mouseenter="() => (!isMobile ? setActive(item.id) : {})"
          @mouseleave="() => (!isMobile ? setActive(null) : {})"
        >
          <Flex
            v-if="item.children.length > 0"
            align="center"
            tag="ul"
            no-wrap
            class="flex-height"
          >
            <Spacer
              v-for="child in item.children"
              :key="child.id"
              tag="li"
              template="menu"
              :class="[child.css]"
            >
              <Cta
                :data="{
                  url: child.url,
                  title: child.content,
                  target: child.target,
                }"
              />
            </Spacer>
          </Flex>
        </div>
      </div>
    </Spacer>

    <Spacer
      y="xs"
      class="list-currency u-hidden--from-m"
      tag="ul"
    >
      <Spacer
        tag="li"
        y="xxxs"
      >
        <Cta
          :data="{
            fn: () => changeCurrency('EUR'),
            title: '€/Europe',
          }"
          :class="[
            'currency',
            currencyActive === 'EUR' ? 'currency--active' : false,
          ]"
        />
      </Spacer>
      <Spacer
        tag="li"
        y="xxxs"
      >
        <Cta
          :data="{
            fn: () => changeCurrency('USD'),
            title: '$/United States',
          }"
          :class="[
            'currency',
            currencyActive === 'USD' ? 'currency--active' : false,
          ]"
        />
      </Spacer>
      <Spacer
        tag="li"
        y="xxxs"
      >
        <Cta
          :data="{
            fn: () => changeCurrency('GBP'),
            title: '£/United Kingdom',
          }"
          :class="[
            'currency',
            currencyActive === 'GBP' ? 'currency--active' : false,
          ]"
        />
      </Spacer>
      <Spacer
        tag="li"
        y="xxxs"
      >
        <Cta
          :data="{
            fn: () => changeCurrency('CAD'),
            title: 'C$/Canada',
          }"
          :class="[
            'currency',
            currencyActive === 'CAD' ? 'currency--active' : false,
          ]"
        />
      </Spacer>
    </Spacer>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import kebabCase from 'lodash.kebabcase';

import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';

import { specialRoutes } from '@/assets/js/utils';

// eslint-disable-next-line no-undef
const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

export default {
  name: 'Menu',
  components: {
    Cta,
    Icon,
  },
  data() {
    return {
      active: null,
      routerActive: null,
      kebabCase,
      currencyActive: woocommerce_currency,
    };
  },
  computed: {
    ...mapGetters(['menu', 'isMobile', 'menuOpened']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.params && to.params.parent) {
          this.routerActive = to.params.parent;
        } else if (to.name && to.name.includes('Single')) {
          if (to.meta.type === 'guide') {
            this.routerActive = 'support';
          } else {
            this.routerActive = 'community';
          }
        } else {
          this.routerActive = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    setActive(id) {
      if (id) {
        document.querySelector('header').classList.add('header--hover');
      } else {
        document.querySelector('header').classList.remove('header--hover');
      }
      if (this.isMobile && this.active === id) {
        this.active = null;
      } else {
        this.active = id;
      }
    },
    async changeCurrency(value) {
      // await this.$store.dispatch('clearCart');
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('wmc-currency', value);
      window.location.search = urlParams;
    },
    specialRoutes(route) {
      return specialRoutes(route);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  flex: 2 0 auto;

  @include mq($until: m) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    order: 5;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    // transition: max-height 0.5s $ease-custom;
    max-height: 0px;

    &::-webkit-scrollbar {
      display: none;
    }

    &.menu--active {
      transition-duration: 1.6s;
      max-height: calc((100 * var(--vh, 1vh)) - 72px - var(--notificationHeight, 0px));
    }
  }
}

.item-primary {
  position: relative;
  cursor: pointer;

  // &.shop {
  //   display: none;
  // }

  @include mq(m) {
    height: 72px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: $black;
      transform-origin: 50% 100%;
      transform: scaleY(0);

      transition: all 0.2s $ease-custom;
    }

    &.shop {
      display: grid;
      align-items: center;
    }

    &.item--active {
      &::after {
        transform: scaleY(1);
      }
    }

    &.item--hover:not(.item--active) {
      &::after {
        transform: scaleY(1);
        opacity: 0.5;
      }
    }
  }

  &--preorder {
    ::v-deep .cta.theme--button-large a {
      color: $primary;
      border-radius: 18px;
      padding: 10px 24px 14px;
      letter-spacing: -0.02em;
    }
  }
}

.submenu {
  position: relative;
  z-index: 200;
  height: 0px;
  transition: height 0.5s $ease-custom;

  &--active {
    height: 72px;
    transition-duration: 0.75s;
  }
}

.submenu--mobile {
  position: relative;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s $ease-custom;

  &--active {
    max-height: 400px;
    transition-duration: 1.4s;
  }
}

.currency {
  &--active {
    opacity: 1;
  }

  opacity: 0.5;
}
</style>
