<template>
  <div
    class="hero"
  >
    <div
      :class="['item', !data.slide[0].media || (data.slide[0].media.type === 'image' && !data.slide[0].media.image.default) ? 'item-not-media' : false]"
    >
      <Flex
        class="content"
        dir="column"
        align="h-between"
      >
        <div />
        <div
          v-if="data.slide[0].ctas && data.slide[0].ctas[0]"
          class="trigger"
        >
          <Icon
            name="play"
            :fn="playVideo"
          />
        </div>

        <Wrapper
          class="text"
          size="l"
        >
          <Spacer
            :bottom="{ default: 's2', m: 'xl'}"
          >
            <Content
              v-if="checkContent(data.slide[0])"
              :data="setContent(Object.assign(data.slide[0], { size_title: 'xxl', tag_title: 'h1' }))"
            />
          </Spacer>
        </Wrapper>
      </Flex>

      <Media
        v-if="checkMedia(data.slide[0])"
        :data="setMedia(data.slide[0], 'hero')"
        class="media"
      />
    </div>
  </div>
</template>

<script>
import {
  setContent, setMedia, checkContent, checkMedia,
} from '@/assets/js/utils';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Icon from '@/components/ui/icon';

export default {
  name: 'Hero',
  components: {
    Content,
    Icon,
    Media,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setContent,
      setMedia,
      checkContent,
      checkMedia,
    };
  },
  methods: {
    playVideo() {
      this.$bus.$emit('modal', {
        type: 'video',
        url: this.data.slide[0].ctas[0].cta.url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  position: relative;

  .media {
    position: relative;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    ::v-deep .spacer-cta {
      display: none;
    }

    & > *:nth-child(1),
    & > *:nth-child(2),
    & > *:nth-child(3) {
      flex: 1 0 0;
    }
  }

  .trigger {
    align-self: center;
    z-index: 10;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    display: flex;
    align-items: flex-end;
  }

  &-not-media {
    min-height: 50vh;
    display: flex;
    align-items: center;

    .content {
      position: relative;
    }
  }
}
</style>
