<template>
  <div>
    <div
      v-for="(bank, bankIndex) in wcSettings.woocommerce_bacs_accounts"
      :key="bankIndex"
    >
      <div>
        <Spacer
          top="s"
          bottom="xs"
        >
          {{ bank.account_name }}
        </Spacer>
        <div v-if="bank.bank_name">
          {{ $labels.bank }}: {{ bank.bank_name }}
        </div>
        <div v-if="bank.account_number">
          {{ $labels.account_number }}: {{ bank.account_number }}
        </div>
        <div v-if="bank.sort_code">
          {{ $labels.sort_code }}: {{ bank.sort_code }}
        </div>
        <div v-if="bank.iban">
          {{ $labels.iban }}: {{ bank.iban }}
        </div>
        <div v-if="bank.bic">
          {{ $labels.bic_swift }}: {{ bank.bic }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BanksDetails',
  components: {
  },
  props: {

  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['wcSettings']),
  },
  mounted() {
  },
  methods: {

  },
};
</script>

<style lang="scss">

</style>
