<template>
  <transition name="snackbar">
    <div
      v-if="active"
      class="snackbar typo--xs"
      :class="'snackbar--'+theme"
    >
      <Spacer
        y="xs"
        x="xs"
      >
        <Flex
          :align="{
            h: 'between',
            v: 'center'
          }"
        >
          <Flex align="v-center">
            <Icon
              v-if="theme === 'error'"
              name="alert"
              size="s"
              theme="invert"
            />
            <Spacer
              left="s"
              v-html="content"
            />
          </Flex>
          <div>
            <Icon
              name="close"
              size="xs"
              theme="invert"
              :fn="close"
            />
          </div>
        </Flex>
      </Spacer>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Snackbar',
  components: {
    Icon,
  },
  props: {
    timeout: {
      type: Number,
      default: 3500,
    },
  },
  computed: {
    active() {
      return this.$store.state.wc.snackbar.active;
    },
    theme() {
      if (this.$store.state.wc.snackbar.theme) {
        return this.$store.state.wc.snackbar.theme;
      }
      return 'error';
    },
    content() {
      if (this.theme === 'error' && !this.$store.state.wc.snackbar.content) {
        return this.$labels.error_generic;
      }
      return this.$store.state.wc.snackbar.content;
    },
  },
  watch: {
    active() {
      if (this.active) {
        setTimeout(() => {
          this.$store.dispatch('closeSnackbar');
        }, this.theme === 'error' ? this.timeout + 3000 : this.timeout);
      }
    },
  },
  methods: {
    close() {
      this.$store.dispatch('closeSnackbar');
    },
  },
};
</script>

<style lang="scss" scoped>
.snackbar-enter-active,
.snackbar-leave-active {
  transition: all 0.3s ease;
}
.snackbar-enter, .snackbar-leave-to {
  transform: translateY(200%);
}
.snackbar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: $unit*2;
  margin: 0 auto;
  width: $unit*30;
  max-width: calc(100vw - #{$unit}*3);
  border-radius: var(--radius);
  color: $white;
  z-index: 30;
  .flex {
    flex-wrap: nowrap;
  }
  &--error {
    background: $warning-color;
  }
  &--success {
    background: $primary-d;
    color: $black;
  }
  &--dark {
    background: $black;
  }
}
</style>
