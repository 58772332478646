<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :class="['icon', `icon--theme-${theme}`, `icon--size-${size}`, `icon-${name}`]"
    :to="to"
    @click.stop="fn"
  >
    <inline-svg
      :src="require(`@/assets/svg/icons/${name}.svg`)"
    />
    <div
      v-if="name === 'cart' && cartCount > 0"
      class="count"
      v-html="cartCount"
    />
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'l',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['cartCount']),
  },
};
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  cursor: pointer;

  @include mq($and: $hover) {
    svg {
      transition: opacity 0.2s $ease-custom;
    }
    &:hover {
      svg {
        opacity: 0.5;
      }
    }
  }

  &--size-xs {
    width: $unit*1.5;
    height: $unit*1.5;
    svg {
      width: $unit*1.5;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &--size-s {
    width: $unit*2;
    height: $unit*2;
    svg {
      width: $unit*2;
          position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    }
  }

  &--size-m {
    width: 20px;
    height: 20px;

    @include mq(m) {
      width: 40px;
      height: 40px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &.icon-c-dollar {
      svg {
        width: 24.5px;
      }
    }
  }

  &--size-s2 {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &--size-m2 {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &--size-m3 {
    width: 20px;
    height: 20px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &--size-l {
    width: 30px;
    height: 30px;

    @include mq(m) {
      width: 60px;
      height: 60px;
    }

    svg {
      width: 15px;
      height: 15px;

      @include mq(m) {
        width: 36px;
        height: 36px;
      }
    }
  }

  &--size-xl {
    width: 45px;
    height: 45px;

    @include mq(m) {
      width: 60px;
      height: 60px;
    }

    svg {
      width: 45px;
      height: 45px;

      @include mq(m) {
        width: 60px;
        height: 60px;
      }
    }
  }

  &--theme-default {
    border-radius: 100%;
    background: $primary;
    color: $black;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--theme-tool {
    color: currentColor;

    @include mq(m) {
      border-radius: 10px;
      background: $black;
      color: $primary;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--theme-minimal {
    color: currentColor;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .count {
    position: absolute;
    background: $black;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    line-height: 12px;
    font-size: 8px;
    color: $primary;
    text-align: center;
    font-weight: 800;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    top: -4px;
    right: -4px;
    padding-left: 1px;

    @include mq(m) {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
      top: 5px;
      right: 5px;
      background: $primary;
      color: $black;
    }
  }
}
</style>
