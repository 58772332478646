<template>
  <div
    :class="[
      'wysiwyg',
      mergeProps.size ? `typo--${mergeProps.size}` : null,
      theme ? `wysiwyg--${theme}` : false,
    ]"
    v-html="mergeProps.value"
  />
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import gsap, { ScrollToPlugin } from 'gsap/all';

require('flickity-imagesloaded');

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Wysiwyg',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        size: 's',
      };

      Object.keys(this.data).forEach(
        (key) => this.data[key] === undefined && delete this.data[key],
      );
      return { ...defaultProps, ...this.data };
    },
  },
  mounted() {
    if (document.documentElement.classList.contains('webp')) {
      this.webpfy();
    }

    const galleries = Array.from(
      this.$el.querySelectorAll('.wp-block-gallery.columns-1'),
    );
    if (galleries && galleries.length > 0) {
      galleries.forEach((gallery) => {
        // eslint-disable-next-line no-unused-vars
        const flickity = new Flickity(
          gallery.querySelector('.blocks-gallery-grid'),
          {
            cellAlign: 'center',
            cellSelector: '.blocks-gallery-item',
            imagesLoaded: true,
            // prevNextButtons: false,
            pageDots: false,
            draggable: true,
            contain: false,
            wrapAround: true,
            arrowShape:
                'M42.268 82.268a2.5 2.5 0 01-3.536 0l-30-30A2.5 2.5 0 0110.5 48h80a2.5 2.5 0 010 5H16.536l25.732 25.732a2.5 2.5 0 010 3.536zM15.734 42.268a2.5 2.5 0 010-3.536l20-20a2.5 2.5 0 013.536 3.536l-20 20a2.5 2.5 0 01-3.536 0z',
          },
        );
      });
    }

    this.$el.querySelectorAll('a[href*=\'#\']').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();
        e.target.getAttribute('href');
        this.$router.replace({ hash: e.target.getAttribute('href') });
        const hash = e.target.getAttribute('href');
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 112,
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      });
    });
  },
  methods: {
    webpfy() {
      const images = Array.from(
        this.$el.querySelectorAll('.wp-block-image img'),
      );

      if (images && images.length > 0) {
        images.forEach((image) => {
          const isGif = image.src.split('.')[image.src.split('.').length - 1] === 'gif';
          if (isGif) {
            return;
          }
          image.addEventListener('error', (e) => {
            if (!e.target.classList.contains('webp-fallback') && e.target.parentNode && e.target.parentNode.children[0]) {
              e.target.parentNode.children[0].srcset = e.target.src;
              e.target.classList.add('webp-fallback');
            }
          });
          const parent = image.parentNode;
          const picture = document.createElement('picture');
          const webp = document.createElement('source');

          parent.replaceChild(picture, image);
          picture.appendChild(webp);
          picture.appendChild(image);

          webp.srcset = `${image.src.replace(
            'wp-content',
            'wp-content/uploads-webpc',
          )}.webp`;

          webp.type = 'image/webp';
        });
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/src/03_typography";

  .wysiwyg {
    a {
      border-bottom: 1px solid currentColor;
      transition: border 0.2s $ease-custom;

      @include mq($and: $hover) {
        &:hover {
          border-color: transparent;
        }
      }
    }

    figure > a {
      border: 0px;
    }

    ul,
    ol {
      display: block;
      margin: 1em 0;
      padding-left: $unit * 2;
    }

    ul {
      list-style-type: disc;
    }

    p + *,
    .wp-block-spacer + * {
      margin-top: map-get($spacer, s);

      @include mq(m) {
        margin-top: map-get($spacer, m);
      }
    }

    h1 {
      @extend %typo--xl;
      margin-top: map-get($spacer, m2);
      margin-bottom: map-get($spacer, s);

      @include mq(m) {
        margin-top: map-get($spacer, l);
        margin-bottom: map-get($spacer, m);
      }
    }

    h2 {
      @extend %typo--l;
      margin-top: map-get($spacer, m2);
      margin-bottom: map-get($spacer, s);

      @include mq(m) {
        margin-top: map-get($spacer, l);
        margin-bottom: map-get($spacer, m);
      }
    }

    h3,
    h4 {
      @extend %typo--m;
      margin-top: map-get($spacer, m2);
      margin-bottom: map-get($spacer, s);

      @include mq(m) {
        margin-top: map-get($spacer, l);
        margin-bottom: map-get($spacer, m);
      }
    }

    .has-medium-font-size {
      @extend %typo--m;
    }

    h5 {
      @extend %typo--label-m;
      color: $grey-l;
    }

    .wp-block-spacer {
      display: none;
    }

    .wp-block-image,
    .wp-block-video,
    .wp-block-embed {
      padding: map-get($spacer, m2) 0;

      @include mq(m) {
        padding: map-get($spacer, l) 0;
      }

      img,
      video {
        max-width: 100%;
      }

      img {
        // width: 100%;
      }

      figcaption {
        @extend %typo--label-s;
        color: $grey-l;
        text-align: center;
      }
    }

    .wp-block-embed {
      max-width: map-get($wrapper, m);
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .is-type-video .wp-block-embed__wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /*16:9*/

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .wp-block-image {
      max-width: map-get($wrapper, m);
      margin-left: auto;
      margin-right: auto;

      &.is-style-small {
        max-width: map-get($wrapper, xs);

        img {
          width: 100%;
        }
      }
      &.is-style-medium {
        max-width: map-get($wrapper, s);

        img {
          width: 100%;
        }
      }
      &.is-style-large {
        max-width: map-get($wrapper, m);

        img {
          width: 100%;
        }
      }
    }

    .wp-block-video {
      max-width: map-get($wrapper, s);
      margin-left: auto;
      margin-right: auto;

      video {
        width: 100%;
      }
    }

    .wp-block-gallery.columns-1 {
      padding: map-get($spacer, m2) 0;

      @include mq(m) {
        padding: map-get($spacer, l) 0;
      }

      ul {
        list-style: none;
        display: block;
        max-width: none !important;
        margin: 0;
        padding: 0;
      }

      .flickity-viewport {
        overflow: visible;
      }

      .blocks-gallery-item {
        display: block;
        min-height: 100%;
        width: 80vw;
        @include aspect-ratio(4, 2.25);
        margin-right: map-get($spacer, s);

        transform: scale(0.9);
        transition: transform 0.5s ease-out;

        display: flex;
        flex-flow: row wrap;
        align-items: center;

        &.is-selected {
          transform: scale(1);
          transform-origin: 50% 50%;
        }

        &.is-previous {
          transform-origin: 100% 50%;
        }

        &.is-next {
          transform-origin: 0% 50%;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .wp-block-gallery:not(.columns-1) {
      ul {
        gap: map-get($spacer, s);
        display: grid;
        list-style: none;
        padding: 0;

        @include mq(xxs) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include mq(m) {
          grid-template-columns: initial;
          grid-auto-flow: column;
        }
      }

      figure {
        height: 100%;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .wp-block-table {
      max-width: map-get($wrapper, s);
      margin-left: auto;
      margin-right: auto;
      overflow-x: auto;

      table {
        width: 100%;
      }

      thead {
        color: $primary;
      }
    }

    &--no-space {
      ol,
      ul {
        margin: 0;
      }
    }
  }
</style>

<style lang="scss">
  .wp-block-gallery.columns-1 {
    .flickity-prev-next-button {
      background: $primary;

      width: 30px;
      height: 30px;

      @include mq(m) {
        width: 60px;
        height: 60px;
      }
    }

    .flickity-prev-next-button.previous {
      left: 0;
    }
    .flickity-prev-next-button.next {
      right: 0;
    }
  }
</style>
