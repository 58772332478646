<template>
  <main
    :class="['single', 'layout--white']"
  >
    <section class="layout--black">
      <Hero
        :data="{
          slide: [{
            media: {
              type: 'image',
              image: {
                default: post.gds_featured_image,
                caption: null,
                shadow: true,
              },
            },
            align_media: 'center',
            title: post.title.rendered,
          }]
        }"
      />
    </section>
    <section>
      <Spacer
        v-if="post.acf.intro && post.acf.intro !== ''"
        class="t-center--desktop"
        tag="section"
        :y="{ default: 'm2', m: 'xxl' }"
      >
        <Wrapper
          size="l"
          boxed
        >
          <Richtext :data="{ value: post.acf.intro, size: 'm'}" />
        </Wrapper>
      </Spacer>
    </section>
    <section class="wysiwyg">
      <Spacer
        v-for="(block, index) in post.acf.content"
        :key="index"
        :top="{ m: 'm' }"
        :bottom="{ default: 'm', m: 'm' }"
      >
        <Wrapper
          :size="block.acf_fc_layout === 'content' ? 's' : block.type === 'gallery' ? undefined : block.size ? block.size : 'l'"
          :boxed="block.type !== 'gallery'"
        >
          <component
            :is="block.acf_fc_layout === 'content' ? 'Richtext' : 'Media'"
            :data="block.acf_fc_layout === 'content' ? { value: block.richtext, size: 'label-m' } : block"
            :extra-settings="block.acf_fc_layout === 'media' && block.type === 'gallery' ? { cellAlign: 'center', contain: false, wrapAround: true, template: 'project', draggable: true } : undefined"
          />
        </Wrapper>
      </Spacer>
    </section>
    <Section
      v-if="banner"
      :layout="{
        section_layout: 'black'
      }"
      class="section-community spacer--section-large"
    >
      <Content
        :class="['block', 'block--content']"
        :data="Object.assign(banner, { layout: 'black', section_layout: { section_classes: 'section-community', section_wrapper: 'default' }})"
      />
    </Section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import Hero from '@/components/blocks/hero';
// import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
// import Cta from '@/components/typo/cta';
// import Thumb from '@/components/thumb';
import Media from '@/components/blocks/media';
import Content from '@/components/blocks/content';

import Section from '@/components/section';

import data from '@/mixins/data';

export default {
  name: 'SingleStory',
  components: {
    Hero,
    Richtext,
    // Title,
    Media,
    Content,
    // Cta,
    // Thumb,
    Section,
  },
  mixins: [data],
  computed: {
    ...mapGetters(['banner']),
  },
  mounted() {
    console.log(this.post);
  },
};
</script>

<style lang="scss" scoped>
.wysiwyg {
  ::v-deep {
    figcaption {
      position: relative !important;
      left: auto !important;
      top: auto !important;
      padding: 3px 0 0 0 !important;
      @extend %typo--label-s;
      color: $grey-d;
      text-align: center;
    }
  }
}
</style>
