<template>
  <Flex
    class="flex-child user"
    align="v-center-h-right"
    no-wrap
  >
    <Icon
      theme="tool"
      name="user"
      size="m"
      to="/my-account"
    />

    <div
      class="currency currency-drop u-hidden--until-m"
      @mouseleave="closeDropdown"
    >
      <div
        class="active-currency"
        @mouseenter="openDropdown"
      >
        <Icon
          theme="tool"
          :name="currentCurrency"
          size="m"
        />
      </div>
    </div>

    <Icon
      theme="tool"
      name="cart"
      size="m"
      to="/cart"
    />
    <!-- <Cta
      theme="tool"
      :data="{
        url: specialRoutes('goliathShopUrl'),
        title: specialRoutes('goliathShopTitle'),
      }"
      class="u-hidden--until-m"
    /> -->
  </Flex>
</template>

<script>
import Icon from '@/components/ui/icon';
// import Cta from '@/components/typo/cta';

import { specialRoutes } from '@/assets/js/utils';

// eslint-disable-next-line no-undef
const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

export default {
  name: 'Toolbar',
  components: {
    Icon,
    // Cta,
  },
  data() {
    return {
      currencies: [
        {
          value: 'EUR',
          name: 'euro',
        },
        {
          value: 'USD',
          name: 'dollar',
        },
        {
          value: 'GBP',
          name: 'pound',
        },
        {
          value: 'CAD',
          name: 'c-dollar',
        },
      ],
    };
  },
  computed: {
    currentCurrency() {
      return this.currencies.find((cur) => cur.value === woocommerce_currency).name;
    },
    otherCurrencies() {
      return this.currencies.filter((cur) => cur.value !== woocommerce_currency);
    },
  },
  methods: {
    closeDropdown() {
      this.$bus.$emit('closeDropdown');
    },
    openDropdown() {
      this.$bus.$emit('openDropdown');
    },
    specialRoutes(route) {
      return specialRoutes(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  & > * {
    margin-left: 12px;
    @include mq(m) {
      margin-left: 8px;
    }
  }
}
</style>
