<template>
  <div>
    <label
      class="typo--label-s"
      v-html="data.specs_title"
    />
    <p
      class="typo--label-m"
      v-html="data.specs_content"
    />
  </div>
</template>

<script>
export default {
  name: 'Details',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  text-transform: uppercase;
  @extend %typo--label-s;
  color: $grey-l;
}

</style>
