<template>
  <ul>
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'AccordionWrapper',
  provide() {
    return { Accordion: this.Accordion };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Accordion: {
        count: 0,
        active: null,
      },
    };
  },
  watch: {
    isActive: {
      immediate: true,
      handler(value) {
        if (value) {
          this.Accordion.active = 1;
        }
      },
    },
  },
};
</script>

<style>
  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s cubic-bezier(0.65, 0, 0.35, 1),
      opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
  }
</style>
