<template>
  <Spacer
    :all="{ default: 's', m: 'm' }"
    :class="['thumb', 'layout--dark']"
    @click.native="openModal"
  >
    <Flex
      v-if="data.acf"
      dir="column"
      align="h-between-v-left"
      class="flex-height"
    >
      <div>
        <Label :data="{ value: data.acf.category[0].name, size: 'm' }" />
        <Title :data="{ value: data.title.rendered, size: 's' }" />
      </div>
      <Spacer
        v-if="data.acf.icon"
        :top="{ default: 's2', m: 'l' }"
      >
        <Figure :data="data.acf.icon" />
      </Spacer>
    </Flex>
  </Spacer>
</template>

<script>
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';

export default {
  name: 'ThumbTutorial',
  components: {
    Figure,
    Label,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$bus.$emit('modal', {
        type: 'video',
        url: this.data.acf.link,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  cursor: pointer;
  height: 100%;

  @include mq(l) {
    height: auto;
    @include aspect-ratio(4.4, 3);
  }
}
</style>
