var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:['nav', _vm.menuOpened ? 'menu--active' : false, 'typo--menu'],on:{"mouseleave":function () { return (!_vm.isMobile ? _vm.setActive(null) : {}); }}},[_c('Spacer',{attrs:{"top":{ default: 's', m: 0 },"bottom":{ default: 'm', m: 0 }}},[_c('Flex',{attrs:{"tag":"ul","align":"h-center","dir":{ default: 'column', m: 'row' }}},_vm._l((_vm.navigation),function(item){return _c('li',{key:item.id,class:[
          'item-primary',
          _vm.routerActive === _vm.kebabCase(item.content) ? 'item--active' : false,
          _vm.active === item.id ? 'item--hover' : false,
          item.css ],on:{"mouseenter":function () { return (!_vm.isMobile ? _vm.setActive(item.id) : {}); },"mouseleave":function () { return (!_vm.isMobile ? _vm.setActive(null) : {}); },"click":function () { return (_vm.isMobile ? _vm.setActive(item.id) : {}); }}},[_c('Spacer',{attrs:{"template":item.css === 'shop' ? 'menu-highlight' : 'menu'}},[_c('Flex',{attrs:{"align":{ default: 'h-between-v-center', m: 'h-left' },"inline":!_vm.isMobile}},[(item.css === 'shop')?_c('Cta',{attrs:{"data":{
                url: item.url,
                title: item.content,
              },"theme":_vm.isMobile ? 'shop--large' : 'shop'}}):_c('div',[_vm._v(" "+_vm._s(item.content)+" ")]),(item.children.length > 0)?_c('Icon',{staticClass:"u-hidden--from-m",class:[
                'u-hidden--from-m',
                _vm.active === item.id ? 'icon-arrow-down--rotate' : false ],attrs:{"name":"arrow-down","fn":function () { return (_vm.isMobile ? _vm.setActive(item.id) : {}); },"size":"m2","theme":"minimal"}}):_vm._e()],1)],1),(item.children.length > 0 && _vm.isMobile)?_c('Flex',{class:[
            'u-hidden--from-m',
            'submenu--mobile',
            _vm.active === item.id ? 'submenu--mobile--active' : false ],attrs:{"dir":"column","tag":"ul"}},_vm._l((item.children),function(child){return _c('Spacer',{key:child.id,class:[child.css],attrs:{"tag":"li","y":"xxs"}},[_c('Cta',{attrs:{"data":{
                url: child.url,
                title: child.content,
                target: child.target,
                size: 'label-m',
              }}})],1)}),1):_vm._e()],1)}),0),(!_vm.isMobile)?_c('div',{class:['submenu', _vm.active ? 'submenu--active' : false]},_vm._l((_vm.navigation),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === item.id),expression:"active === item.id"}],key:("submenu-" + (item.id)),on:{"mouseenter":function () { return (!_vm.isMobile ? _vm.setActive(item.id) : {}); },"mouseleave":function () { return (!_vm.isMobile ? _vm.setActive(null) : {}); }}},[(item.children.length > 0)?_c('Flex',{staticClass:"flex-height",attrs:{"align":"center","tag":"ul","no-wrap":""}},_vm._l((item.children),function(child){return _c('Spacer',{key:child.id,class:[child.css],attrs:{"tag":"li","template":"menu"}},[_c('Cta',{attrs:{"data":{
                url: child.url,
                title: child.content,
                target: child.target,
              }}})],1)}),1):_vm._e()],1)}),0):_vm._e()],1),_c('Spacer',{staticClass:"list-currency u-hidden--from-m",attrs:{"y":"xs","tag":"ul"}},[_c('Spacer',{attrs:{"tag":"li","y":"xxxs"}},[_c('Cta',{class:[
          'currency',
          _vm.currencyActive === 'EUR' ? 'currency--active' : false ],attrs:{"data":{
          fn: function () { return _vm.changeCurrency('EUR'); },
          title: '€/Europe',
        }}})],1),_c('Spacer',{attrs:{"tag":"li","y":"xxxs"}},[_c('Cta',{class:[
          'currency',
          _vm.currencyActive === 'USD' ? 'currency--active' : false ],attrs:{"data":{
          fn: function () { return _vm.changeCurrency('USD'); },
          title: '$/United States',
        }}})],1),_c('Spacer',{attrs:{"tag":"li","y":"xxxs"}},[_c('Cta',{class:[
          'currency',
          _vm.currencyActive === 'GBP' ? 'currency--active' : false ],attrs:{"data":{
          fn: function () { return _vm.changeCurrency('GBP'); },
          title: '£/United Kingdom',
        }}})],1),_c('Spacer',{attrs:{"tag":"li","y":"xxxs"}},[_c('Cta',{class:[
          'currency',
          _vm.currencyActive === 'CAD' ? 'currency--active' : false ],attrs:{"data":{
          fn: function () { return _vm.changeCurrency('CAD'); },
          title: 'C$/Canada',
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }