<template>
  <AccordionWrapper>
    <AccordionItem>
      <template
        slot="accordion-trigger"
      >
        <div
          class="form-wrapper__header"
        >
          <div class="form-wrapper__title">
            <slot name="form-title" />
          </div>
        </div>
      </template>
      <template
        slot="accordion-content"
      >
        <slot name="form-groups" />
      </template>
    </AccordionItem>
  </AccordionWrapper>
</template>

<script>
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';

// :opened-fn="data.accordion.fn"
// :show="data.accordion.open"

export default {
  name: 'AccordionFormWrapper',
  components: {
    AccordionWrapper,
    AccordionItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
