import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'subscribe',
      },
      groups: {
        newsletter: {
          visible: true,
          fields: {
            email: {
              type: 'email',
              model: null,
              placeholder: null,
              label: 'email',
              rules: 'required|email',
              size: 12,
            },
            first_name: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'first_name',
              rules: 'required',
              size: 12,
            },
            last_name: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'last_name',
              rules: 'required',
              size: 12,
            },
            country: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'country',
              rules: 'required',
              options: {
                'Aaland Islands': 'Aaland Islands',
                Afghanistan: 'Afghanistan',
                Albania: 'Albania',
                Algeria: 'Algeria',
                'American Samoa': 'American Samoa',
                Andorra: 'Andorra',
                Angola: 'Angola',
                Anguilla: 'Anguilla',
                Antarctica: 'Antarctica',
                'Antigua And Barbuda': 'Antigua And Barbuda',
                Argentina: 'Argentina',
                Armenia: 'Armenia',
                Aruba: 'Aruba',
                Australia: 'Australia',
                Austria: 'Austria',
                Azerbaijan: 'Azerbaijan',
                Bahamas: 'Bahamas',
                Bahrain: 'Bahrain',
                Bangladesh: 'Bangladesh',
                Barbados: 'Barbados',
                Belarus: 'Belarus',
                Belgium: 'Belgium',
                Belize: 'Belize',
                Benin: 'Benin',
                Bermuda: 'Bermuda',
                Bhutan: 'Bhutan',
                Bolivia: 'Bolivia',
                'Bonaire, Saint Eustatius and Saba': 'Bonaire, Saint Eustatius and Saba',
                'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
                Botswana: 'Botswana',
                'Bouvet Island': 'Bouvet Island',
                Brazil: 'Brazil',
                'British Indian Ocean Territory': 'British Indian Ocean Territory',
                'Brunei Darussalam': 'Brunei Darussalam',
                Bulgaria: 'Bulgaria',
                'Burkina Faso': 'Burkina Faso',
                Burundi: 'Burundi',
                Cambodia: 'Cambodia',
                Cameroon: 'Cameroon',
                Canada: 'Canada',
                'Cape Verde': 'Cape Verde',
                'Cayman Islands': 'Cayman Islands',
                'Central African Republic': 'Central African Republic',
                Chad: 'Chad',
                Chile: 'Chile',
                China: 'China',
                'Christmas Island': 'Christmas Island',
                'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
                Colombia: 'Colombia',
                Comoros: 'Comoros',
                Congo: 'Congo',
                'Cook Islands': 'Cook Islands',
                'Costa Rica': 'Costa Rica',
                'Cote D\'Ivoire': 'Cote D\'Ivoire',
                Croatia: 'Croatia',
                Cuba: 'Cuba',
                Curacao: 'Curacao',
                Cyprus: 'Cyprus',
                'Czech Republic': 'Czech Republic',
                'Democratic Republic of the Congo': 'Democratic Republic of the Congo',
                Denmark: 'Denmark',
                Djibouti: 'Djibouti',
                Dominica: 'Dominica',
                'Dominican Republic': 'Dominican Republic',
                Ecuador: 'Ecuador',
                Egypt: 'Egypt',
                'El Salvador': 'El Salvador',
                'Equatorial Guinea': 'Equatorial Guinea',
                Eritrea: 'Eritrea',
                Estonia: 'Estonia',
                Ethiopia: 'Ethiopia',
                'Falkland Islands': 'Falkland Islands',
                'Faroe Islands': 'Faroe Islands',
                Fiji: 'Fiji',
                Finland: 'Finland',
                France: 'France',
                'French Guiana': 'French Guiana',
                'French Polynesia': 'French Polynesia',
                'French Southern Territories': 'French Southern Territories',
                Gabon: 'Gabon',
                Gambia: 'Gambia',
                Georgia: 'Georgia',
                Germany: 'Germany',
                Ghana: 'Ghana',
                Gibraltar: 'Gibraltar',
                Greece: 'Greece',
                Greenland: 'Greenland',
                Grenada: 'Grenada',
                Guadeloupe: 'Guadeloupe',
                Guam: 'Guam',
                Guatemala: 'Guatemala',
                Guernsey: 'Guernsey',
                Guinea: 'Guinea',
                'Guinea-Bissau': 'Guinea-Bissau',
                Guyana: 'Guyana',
                Haiti: 'Haiti',
                'Heard and Mc Donald Islands': 'Heard and Mc Donald Islands',
                Honduras: 'Honduras',
                'Hong Kong': 'Hong Kong',
                Hungary: 'Hungary',
                Iceland: 'Iceland',
                India: 'India',
                Indonesia: 'Indonesia',
                Iran: 'Iran',
                Iraq: 'Iraq',
                Ireland: 'Ireland',
                'Isle of Man': 'Isle of Man',
                Israel: 'Israel',
                Italy: 'Italy',
                Jamaica: 'Jamaica',
                Japan: 'Japan',
                'Jersey  (Channel Islands)': 'Jersey  (Channel Islands)',
                Jordan: 'Jordan',
                Kazakhstan: 'Kazakhstan',
                Kenya: 'Kenya',
                Kiribati: 'Kiribati',
                Kuwait: 'Kuwait',
                Kyrgyzstan: 'Kyrgyzstan',
                'Lao People\'s Democratic Republic': 'Lao People\'s Democratic Republic',
                Latvia: 'Latvia',
                Lebanon: 'Lebanon',
                Lesotho: 'Lesotho',
                Liberia: 'Liberia',
                Libya: 'Libya',
                Liechtenstein: 'Liechtenstein',
                Lithuania: 'Lithuania',
                Luxembourg: 'Luxembourg',
                Macau: 'Macau',
                Macedonia: 'Macedonia',
                Madagascar: 'Madagascar',
                Malawi: 'Malawi',
                Malaysia: 'Malaysia',
                Maldives: 'Maldives',
                Mali: 'Mali',
                Malta: 'Malta',
                'Marshall Islands': 'Marshall Islands',
                Martinique: 'Martinique',
                Mauritania: 'Mauritania',
                Mauritius: 'Mauritius',
                Mayotte: 'Mayotte',
                Mexico: 'Mexico',
                'Micronesia, Federated States of': 'Micronesia, Federated States of',
                'Moldova, Republic of': 'Moldova, Republic of',
                Monaco: 'Monaco',
                Mongolia: 'Mongolia',
                Montenegro: 'Montenegro',
                Montserrat: 'Montserrat',
                Morocco: 'Morocco',
                Mozambique: 'Mozambique',
                Myanmar: 'Myanmar',
                Namibia: 'Namibia',
                Nauru: 'Nauru',
                Nepal: 'Nepal',
                Netherlands: 'Netherlands',
                'Netherlands Antilles': 'Netherlands Antilles',
                'New Caledonia': 'New Caledonia',
                'New Zealand': 'New Zealand',
                Nicaragua: 'Nicaragua',
                Niger: 'Niger',
                Nigeria: 'Nigeria',
                Niue: 'Niue',
                'Norfolk Island': 'Norfolk Island',
                'North Korea': 'North Korea',
                'Northern Mariana Islands': 'Northern Mariana Islands',
                Norway: 'Norway',
                Oman: 'Oman',
                Pakistan: 'Pakistan',
                Palau: 'Palau',
                Palestine: 'Palestine',
                Panama: 'Panama',
                'Papua New Guinea': 'Papua New Guinea',
                Paraguay: 'Paraguay',
                Peru: 'Peru',
                Philippines: 'Philippines',
                Pitcairn: 'Pitcairn',
                Poland: 'Poland',
                Portugal: 'Portugal',
                'Puerto Rico': 'Puerto Rico',
                Qatar: 'Qatar',
                'Republic of Kosovo': 'Republic of Kosovo',
                Reunion: 'Reunion',
                Romania: 'Romania',
                Russia: 'Russia',
                Rwanda: 'Rwanda',
                'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
                'Saint Lucia': 'Saint Lucia',
                'Saint Martin': 'Saint Martin',
                'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
                'Samoa (Independent)': 'Samoa (Independent)',
                'San Marino': 'San Marino',
                'Sao Tome and Principe': 'Sao Tome and Principe',
                'Saudi Arabia': 'Saudi Arabia',
                Senegal: 'Senegal',
                Serbia: 'Serbia',
                Seychelles: 'Seychelles',
                'Sierra Leone': 'Sierra Leone',
                Singapore: 'Singapore',
                'Sint Maarten': 'Sint Maarten',
                Slovakia: 'Slovakia',
                Slovenia: 'Slovenia',
                'Solomon Islands': 'Solomon Islands',
                Somalia: 'Somalia',
                'South Africa': 'South Africa',
                'South Georgia and the South Sandwich Islands': 'South Georgia and the South Sandwich Islands',
                'South Korea': 'South Korea',
                'South Sudan': 'South Sudan',
                Spain: 'Spain',
                'Sri Lanka': 'Sri Lanka',
                'St. Helena': 'St. Helena',
                'St. Pierre and Miquelon': 'St. Pierre and Miquelon',
                Sudan: 'Sudan',
                Suriname: 'Suriname',
                'Svalbard and Jan Mayen Islands': 'Svalbard and Jan Mayen Islands',
                Swaziland: 'Swaziland',
                Sweden: 'Sweden',
                Switzerland: 'Switzerland',
                Syria: 'Syria',
                Taiwan: 'Taiwan',
                Tajikistan: 'Tajikistan',
                Tanzania: 'Tanzania',
                Thailand: 'Thailand',
                'Timor-Leste': 'Timor-Leste',
                Togo: 'Togo',
                Tokelau: 'Tokelau',
                Tonga: 'Tonga',
                'Trinidad and Tobago': 'Trinidad and Tobago',
                Tunisia: 'Tunisia',
                Turkey: 'Turkey',
                Turkmenistan: 'Turkmenistan',
                'Turks &amp; Caicos Islands': 'Turks &amp; Caicos Islands',
                'Turks and Caicos Islands': 'Turks and Caicos Islands',
                Tuvalu: 'Tuvalu',
                Uganda: 'Uganda',
                Ukraine: 'Ukraine',
                'United Arab Emirates': 'United Arab Emirates',
                'United Kingdom': 'United Kingdom',
                'United States of America': 'United States of America',
                Uruguay: 'Uruguay',
                'USA Minor Outlying Islands': 'USA Minor Outlying Islands',
                Uzbekistan: 'Uzbekistan',
                Vanuatu: 'Vanuatu',
                'Vatican City State (Holy See)': 'Vatican City State (Holy See)',
                Venezuela: 'Venezuela',
                Vietnam: 'Vietnam',
                'Virgin Islands (British)': 'Virgin Islands (British)',
                'Virgin Islands (U.S.)': 'Virgin Islands (U.S.)',
                'Wallis and Futuna Islands': 'Wallis and Futuna Islands',
                'Western Sahara': 'Western Sahara',
                Yemen: 'Yemen',
                Zambia: 'Zambia',
                Zimbabwe: 'Zimbabwe',
              },
              size: 12,
            },
            purpose: {
              type: 'radio',
              model: null,
              id: 'purpose',
              rules: 'required',
              label: 'purpose',
              placeholder: null,
              size: 12,
              options: [{
                id: 'Hobby',
                title: 'Hobby',
              }, {
                id: 'Work',
                title: 'Work',
              }, {
                id: 'Education',
                title: 'Education',
              },
              ],
            },
            experience: {
              type: 'radio',
              model: null,
              id: 'experience',
              rules: 'required',
              label: 'experience',
              placeholder: null,
              size: 12,
              options: [{
                id: 'Beginner',
                title: 'Beginner',
              }, {
                id: 'Intermediate',
                title: 'Intermediate',
              }, {
                id: 'Advanced',
                title: 'Advanced',
              },
              ],
            },
            mainAreaForUse: {
              type: 'radio',
              model: null,
              id: 'main_area_for_use',
              rules: 'required',
              label: 'main_area_for_use',
              placeholder: null,
              size: 12,
              options: [
                { id: 'Furniture', title: 'Furniture' },
                { id: 'Signage', title: 'Signage' },
                { id: 'Art', title: 'Art' },
                { id: 'Structures (construction, fair, set-up)', title: 'Structures (construction, fair, set-up)' },
                { id: 'Boards (surf, skate)', title: 'Boards (surf, skate)' },
                { id: 'Boat building', title: 'Boat building' },
                { id: 'Small projects', title: 'Small projects' },
                { id: 'Other', title: 'Other' },
              ],
            },
            mainMaterial: {
              type: 'radio',
              model: null,
              id: 'main_material',
              rules: 'required',
              label: 'main_material',
              placeholder: null,
              size: 12,
              options: [{
                id: 'Wood',
                title: 'Wood',
              }, {
                id: 'Plastic',
                title: 'Plastic',
              }, {
                id: 'Soft metal (aluminum, brass, copper)',
                title: 'Soft metal (aluminum, brass, copper)',
              },
              ],
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  methods: {
    async callback() {
      const body = {
        email: this.groups.newsletter.fields.email.model,
        first_name: this.groups.newsletter.fields.first_name.model,
        last_name: this.groups.newsletter.fields.last_name.model,
        country: this.groups.newsletter.fields.country.model,
        purpose: this.groups.newsletter.fields.purpose.model,
        experience: this.groups.newsletter.fields.experience.model,
        mainAreaForUse: this.groups.newsletter.fields.mainAreaForUse.model,
        mainMaterial: this.groups.newsletter.fields.mainMaterial.model,
        list_id: this.data.list_id,
      };

      const request = {
        fn: 'mailchimp',
        params: body,
      };

      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(this.options.extra.recaptcha_public_key, { action: 'submit' })
          .then((token) => {
            request.params.token = token;

            this.$store.dispatch('sendRequest', request)
              .then((response) => {
                if (response.data.status && response.data.status === 'subscribed') {
                  this.$store.commit('SET_SNACKBAR', { active: true, content: this.$labels.success_newsletter, theme: 'success' });

                  if (window.lintrk) {
                    window.lintrk('track', { conversion_id: 6171450 });
                  }

                  if (this.$gtag) {
                    this.$gtag.event('signup', {
                      event_category: 'Newsletter',
                      event_label: 'Subscriber',
                    });
                  }
                } else {
                  this.$store.commit('SET_SNACKBAR', { active: true, content: response.data.detail });
                }
              })
              .catch(() => {
                this.$store.commit('SET_SNACKBAR', { active: true, content: this.$labels.error_generic });
              });
          })
          .catch(() => {
            this.$store.commit('SET_SNACKBAR', { active: true, content: this.$labels.error_generic });
          });
      });
    },
  },
};
