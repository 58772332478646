var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thumb"},[_c('router-link',{attrs:{"to":_vm.data.permalink}},[(_vm.image)?_c('Figure',{staticClass:"media",attrs:{"data":Object.assign({}, _vm.image, {border: true, lazyload: true})}}):_vm._e(),_c('Spacer',{staticClass:"abstract",attrs:{"all":{ default: 'xs', m: 'm' }}},[_c('Title',{attrs:{"data":{
          value: _vm.data.name,
          tag: 'h2',
          size: 's',
        }}}),_c('Price',{attrs:{"product":_vm.data,"theme":"thumb","tax":""}}),_c('Availability',{attrs:{"product":_vm.data}})],1)],1),_c('Cta',{attrs:{"theme":"button--full","data":{
      fn: function () {
        _vm.addToCart(Object.assign({}, _vm.data,
          {id: String(_vm.data.id),
          quantity: 1,
          quantityDelta: 1}));
      },
      title: _vm.$labels.add_to_cart,
    },"disabled":_vm.isOos}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }