import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import Custom from '@/components/blocks/custom';
import Newsletter from '@/components/blocks/newsletter';
import Hero from '@/components/blocks/hero';
import Accordion from '@/components/blocks/accordion';
import Marquee from '@/components/blocks/marquee';
import Search from '@/components/blocks/search';
import TechSpecs from '@/components/blocks/custom/tech-specs';
import FAQ from '@/components/blocks/custom/faq';
import Reserve from '@/components/blocks/custom/reserve';
import OrderGoliath from '@/components/blocks/custom/order-goliath';
import Cover from '@/components/blocks/cover';
import FormAC from '@/components/blocks/custom/form-ac';

const components = new Map();

components.set('content', Content);
components.set('media', Media);
components.set('repeater', Repeater);
components.set('list', List);
components.set('archive', Archive);
components.set('hero', Hero);
components.set('marquee', Marquee);
components.set('newsletter', Newsletter);
components.set('accordion', Accordion);
components.set('placeholder', Custom);
components.set('search', Search);
components.set('faq', FAQ);
components.set('tech_specs', TechSpecs);
components.set('reserve', Reserve);
components.set('order_goliath', OrderGoliath);
components.set('cover', Cover);
components.set('form_ac', FormAC);

export default components;
