var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('Flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:[
      'modal',
      ("modal-theme--" + _vm.theme),
      _vm.data ? ("modal-theme--" + (_vm.data.id)) : '' ],attrs:{"align":"center","tabindex":_vm.show ? 0 : -1},nativeOn:{"click":function($event){return _vm.close($event)}}},[(_vm.data)?_c('Wrapper',{class:[
        'modal-wrapper',
        _vm.theme === 'dialog' || _vm.theme === 'overlay'
          ? 'layout--white radius'
          : false ],attrs:{"size":_vm.data.type === 'video'
          ? 'xxl'
          : _vm.data.type === 'dialog' || _vm.data.type === 'splitit'
            ? 'xs'
            : _vm.data.type === 'box-detail'
              ? 's'
              : 'm',"boxed":_vm.data.type === 'video'},nativeOn:{"click":function($event){$event.stopPropagation();}}},[(!_vm.data.mandatory)?_c('Icon',{staticClass:"close",attrs:{"name":"close","fn":_vm.close,"theme":"minimal","size":"l"}}):_vm._e(),(_vm.data.type === 'video')?[_c('Video',{ref:"video",attrs:{"data":{
            embed: true,
            url: _vm.data.url,
          }}})]:_vm._e(),(_vm.data.type === 'content')?[_c('Spacer',{attrs:{"all":{ default: 'm', m: 'xl' }}},[_c('Content',{attrs:{"data":{
              title: _vm.data.content.title,
              rich_text: _vm.data.content.richtext,
              ctas: _vm.data.content.cta ? [{ cta: _vm.data.content.cta }] : [],
              cta_layout: 'underline',
              boxed: false,
            }}}),(_vm.data.id === 'order-goliath')?_c('Spacer',{staticClass:"pricing",attrs:{"top":{ default: 'm2', m: 'l' }}},[_c('Grid',{attrs:{"col":"12"}},[_c('div',[_c('Label',{attrs:{"data":{ value: _vm.data.content.price_label }}}),_c('Richtext',{staticClass:"typo--m",attrs:{"data":{ value: _vm.data.content.price }}})],1),_c('div',[_c('Label',{attrs:{"data":{ value: _vm.$labels.number_months }}}),_c('Flex',{attrs:{"col":"3"}},_vm._l((_vm.months),function(month,i){return _c('Spacer',{key:i,attrs:{"top":{ default: 'xs', s: 's' },"right":{ default: 'xs', s: 's' }}},[_c('Cta',{attrs:{"data-months":month,"data":{
                        fn: _vm.calculateInstallments,
                        title: (month + " " + (_vm.$labels.months)),
                        classes:
                          Number(_vm.monthSelected) !== Number(month)
                            ? 'cta-inactive'
                            : 'cta-active',
                      },"theme":"button"}})],1)}),1)],1),_c('div',[_c('Label',{attrs:{"data":{
                    value: ((_vm.$labels.monthly_installments) + " " + (_vm.$labels.interest_zero)),
                  }}}),_c('Richtext',{attrs:{"data":{
                    value: ("<span class='typo--m'>" + _vm.installmentPrice + "</span> <small>" + (_vm.$labels.tax_excluded) + "</small>"),
                  }}})],1),_c('div',[_c('Spacer',{attrs:{"top":{ default: 'xs', s: 0 },"bottom":{ default: 'xs', s: 0 }}},[_c('Accordion',{attrs:{"data":Object.assign({}, _vm.data.content.accordions,
                      {boxed: false,
                      size: 'xl',
                      itemLayout: 'light',
                      triggerTheme: 'underline',
                      triggerSize: 'label-m'})}})],1)],1)])],1):_vm._e(),(_vm.data.id === 'reserve' || _vm.data.id === 'order-goliath')?_c('Spacer',{staticClass:"u-relative",attrs:{"top":{ default: 'm2', m: 'l' }}},[_c('Cta',{attrs:{"data":{
                fn: _vm.addToCart,
                title: _vm.data.cta ? _vm.data.cta.title : _vm.$labels.add_to_cart,
              },"theme":"button"}}),_c('Flex',{staticClass:"powered-by--splitit",attrs:{"align":"v-center","dir":_vm.isMobile ? 'column' : 'row'}},[_c('Spacer',{attrs:{"right":_vm.isMobile ? false : 's',"bottom":_vm.isMobile ? 'xxs' : false}},[_c('Label',{attrs:{"data":{
                    size: 's',
                    value: _vm.$labels.powered,
                  }}})],1),_c('inline-svg',{staticClass:"info-logo color--primary",attrs:{"src":require('@/assets/svg/splitit-black.svg')}})],1)],1):_vm._e()],1)]:_vm._e(),(_vm.data.type === 'dialog')?[_c('Spacer',{attrs:{"all":{ default: 's2' }}},[(_vm.data.title)?_c('Spacer',{attrs:{"bottom":{ default: 's', m: 's' }}},[_c('Title',{attrs:{"data":{
                value: _vm.data.title,
                size: 'm',
              }}})],1):_vm._e(),_c('Richtext',{attrs:{"data":{
              value: _vm.data.content,
              size: 'label-m',
            }}}),_c('Spacer',{attrs:{"top":{ default: 'm2', m: 'l' }}},[_c('Flex',{attrs:{"align":"h-center"}},[_c('Cta',{attrs:{"data":{
                  fn: _vm.dialogFn,
                  title: _vm.data.cta,
                },"theme":"button"}})],1)],1)],1)]:_vm._e(),(_vm.data.type === 'splitit')?[_c('Spacer',{attrs:{"all":{ default: 's2' }}},[_c('Flex',{attrs:{"align":"center","dir":"column"}},[_c('Spacer',{attrs:{"bottom":"xs"}},[_c('Label',{attrs:{"data":{
                  size: 's',
                  value: _vm.$labels.powered,
                }}})],1),_c('inline-svg',{staticClass:"info-logo color--primary",attrs:{"src":require('@/assets/svg/splitit-black.svg')}})],1),_c('Spacer',{attrs:{"y":"m"}},[_c('p',{staticClass:"typo--label-m",domProps:{"innerHTML":_vm._s(_vm.$labels.modal_splitit)}})]),_c('Flex',{attrs:{"align":"h-center"}},[_c('div',{key:_vm.show,attrs:{"id":"splitit-payment-container"}})])],1)]:_vm._e(),(_vm.data.type === 'box-detail')?_c('BoxDetail',{key:_vm.show,ref:"carousel",attrs:{"post":_vm.data.post}}):_vm._e()],2):_vm._e(),(_vm.data && _vm.data.type === 'box-detail')?_c('Wrapper',{staticClass:"carousel-controls",attrs:{"size":"xl","boxed":""}},[_c('Icon',{staticClass:"previous",attrs:{"fn":_vm.carouselPrevious,"name":"arrow-left","size":"l"}}),_c('Icon',{staticClass:"next",attrs:{"fn":_vm.carouselNext,"name":"arrow-right","size":"l"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }