<template>
  <Spacer
    :class="['thumb', `layout--${data.content.layout}`]"
  >
    <Figure
      v-if="data.media.image"
      :data="Object.assign(data.media.image, { border: true })"
      class="media"
    />
    <Spacer
      class="abstract"
      :y="{ default: 'xxs', m: 'xs' }"
    >
      <Label
        v-if="data.content.label"
        class="label"
        :data="{ value: data.content.label, size: 's' }"
      />
      <Spacer top="xxxs">
        <Title :data="{ value: data.content.title, tag: data.content.tag_title, size: 'label-m' }" />
      </Spacer>
    </Spacer>
  </Spacer>
</template>

<script>
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';

export default {
  name: 'ThumbTeam',
  components: {
    Figure,
    Title,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  width: 100%;
  height: 100%;
}
.media {
  @include aspect-ratio(1, 1);

  ::v-deep picture {
    position: absolute;
    width: 100%;
  }
}
.label {
  color: $grey-l;
  text-transform: uppercase;
}
</style>
