<template>
  <div id="app">
    <AppHeader />
    <div
      ref="main"
      :class="[
        'app-view',
        $route.name === 'Checkout' ? 'app-view--no-border' : false,
      ]"
    >
      <transition
        :css="false"
        appear
        mode="in-out"
        @enter="enter"
        @leave="leave"
      >
        <router-view :key="computedPath" />
      </transition>
    </div>
    <AppFooter ref="footer" />
    <Modal />
    <Popup v-if="popup && popup.active" />

    <Snackbar />
    <Loader />
    <Cookie v-if="$store.state.wp.site.locale === 'us'" />
    <div
      ref="disable"
      class="disable"
    />

    <CurrencyDropdown v-show="!$route.meta.hideHeader && !isMobile" />

    <div
      v-if="overlayAffirm"
      class="overlay-affirm typo--s"
    >
      <Wrapper
        size="l"
        boxed
      >
        <p
          v-if="overlayAffirm === 'thx-page'"
          v-html="$labels.affirm_thxpage"
        />
        <p
          v-if="overlayAffirm === 'pay-order'"
          v-html="$labels.affirm_payorder"
        />
      </Wrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import gsap from 'gsap';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import { isTouchDevice, is, isIOS } from '@/assets/js/utils';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import Modal from '@/components/ui/modal';
import Popup from '@/components/ui/popup';
import Snackbar from '@/components/ui/snackbar';
import Loader from '@/components/ui/loader';
import CurrencyDropdown from '@/components/ui/currency-dropdown';
import Cookie from '@/components/ui/cookie';

// eslint-disable-next-line no-undef
const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

gsap.defaults({
  force3D: false,
});

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Modal,
    Snackbar,
    Loader,
    CurrencyDropdown,
    Cookie,
    Popup,
  },
  mixins: [lazyload],
  data() {
    return {
      scaled: false,
      logoLoader: true,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'isMobile', 'transition', 'overlayAffirm', 'popup']),
    computedPath() {
      if (
        this.$route.name === 'NewsCategory'
        || this.$route.params.slug === 'news'
      ) {
        return 'news-category';
      }
      return this.$route.path;
    },
  },
  watch: {
    transition(val) {
      if (!val) {
        const to = this.$store.state.route;
        if (to.hash && document.querySelector(`[id="${to.hash}"]`)) {
          gsap.killTweensOf(window);
          gsap.to(window, {
            scrollTo: {
              y: document.querySelector(`[id="${to.hash}"]`),
              offsetY: 112,
              autoKill: true,
            },
            duration: 1,
            ease: 'expo.out',
          });
        }
        const cookieBanner = document.querySelector('#iubenda-cs-banner');
        if (cookieBanner) {
          cookieBanner.style.transform = 'translateY(0)';
        }
      }
    },
    $route(to) {
      if (to.meta.hideHeader) {
        document.querySelector('html').classList.add('hide-crisp');
      } else {
        document.querySelector('html').classList.remove('hide-crisp');
      }
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    const { location } = window;
    if (woocommerce_currency === 'USD' && !location.host.includes('us') && process.env.NODE_ENV !== 'development') {
      const host = location.host.startsWith('www')
        ? location.host.replace('www.', '')
        : location.host;
      const usLocation = `${location.protocol}//us.${host}${location.pathname}`;
      window.location.assign(usLocation);
    }

    this.$store.dispatch('getCart');

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
      }, 400),
    );

    this.$bus.$on('windowResized', this.resize);
    this.resize();

    medusa.ref.addTarget({
      id: 'main-fx',
      threshold: 0.5,
      nodes: [],
      mode: 'default',
      callback: this.mainFx,
      autoremove: false,
    });

    medusa.ref.pushToTarget('main-fx', this.$refs.footer.$el);

    const setGaChat = () => {
      this.$gtag.event('start', {
        event_category: 'Chat',
        event_label: 'Start Customer Care Chat',
      });
    };

    window.CRISP_READY_TRIGGER = () => {
      window.$crisp.push(['on', 'chat:opened', setGaChat]);
    };
  },
  beforeDestroy() {
    medusa.ref.removeTarget('main-fx');
  },
  methods: {
    resize() {
      if (isIOS()) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      } else {
        document.documentElement.style.setProperty('--vh', '1vh');
      }

      const breakpoints = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--breakpoints')
        .replace(/[ '"]+/g, '');
      this.$store.commit('SET_BREAKPOINTS', breakpoints);
      this.$store.commit('SET_TOUCH', isTouchDevice());
      this.$store.commit('SET_MOBILE_XS', is('xs'));
      this.$store.commit('SET_MOBILE_S', is('s'));
      this.$store.commit('SET_MOBILE', is('m'));
    },
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      disableBodyScroll(this.$refs.disable);
      // const from = this.$store.state.route.from.name;

      if (this.logoLoader && document.querySelector('#logo-loader')) {
        this.logoLoader = false;
        const logoLoader = document.querySelector('#logo-loader');
        gsap.to(logoLoader, {
          autoAlpha: 0,
          y: 10,
          ease: 'power3.inOut',
          duration: 0.5,
          onComplete: () => {
            logoLoader.remove();
          },
        });
      }

      done();

      if (this.$route.meta.customView) {
        gsap.set(this.$refs.main, {
          scale: 1,
          clearProps: 'all',
        });
        gsap.set(el, {
          clearProps: 'all',
        });
        enableBodyScroll(this.$refs.disable);
        this.$store.commit('SET_TRANSITION', false);
      } else {
        gsap.set(el, {
          y: window.innerHeight,
          borderTopLeftRadius: 60,
          borderTopRightRadius: 60,
          scale: 0.95,
          transformOrigin: '50% 100vh',
          zIndex: 2,
        });

        const tl = gsap.timeline({
          delay: 0.05,
          onComplete: () => {
            enableBodyScroll(this.$refs.disable);
            this.$store.commit('SET_TRANSITION', false);
            gsap.set(el, {
              clearProps: 'all',
            });
          },
        });

        tl.to(
          el,
          {
            y: 0,
            duration: 0.7,
            ease: 'power3.inOut',
          },
          0,
        );
        tl.to(
          el,
          {
            scale: 1,
            duration: 0.4,
            ease: 'power3.inOut',
          },
          0.3,
        );
        tl.to(
          el,
          {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            duration: 0.3,
            ease: 'power3.inOut',
          },
          0.6,
        );
      }
    },
    leave(el, done) {
      gsap.set(el, {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        y: window.pageYOffset * -1,
        zIndex: 0,
      });

      if (this.$route.meta.customView) {
        done();
      } else {
        gsap.to(el, {
          autoAlpha: 0.25,
          borderTopLeftRadius: 60,
          borderTopRightRadius: 60,
          scale: 0.95,
          y: '+=40',
          duration: 0.8,
          transformOrigin: '50% 100vh',
          ease: 'power3.inOut',
          onComplete: () => {
            setTimeout(() => {
              done();
            }, 200);
          },
        });
      }
    },

    mainFx(entry) {
      if (this.isMobile || this.$store.state.route.meta.customView) {
        gsap.killTweensOf([this.$refs.main, entry.target]);
        gsap.set(this.$refs.main, {
          scale: 1,
          clearProps: 'all',
        });
        gsap.set(entry.target, {
          clearProps: 'all',
          autoAlpha: 1,
        });
        return;
      }
      if (this.transition) {
        this.scaled = false;
        gsap.killTweensOf([this.$refs.main, entry.target]);
        gsap.to(this.$refs.main, {
          scale: 1,
          duration: 0.4,
          ease: 'power3.out',
        });
        gsap.to(entry.target, {
          autoAlpha: 0,
          duration: 0.4,
          ease: 'power3.out',
        });
      } else if (window.pageYOffset > 0 && entry.isIntersecting) {
        this.scaled = true;
        gsap.killTweensOf([this.$refs.main, entry.target]);
        gsap.to(this.$refs.main, {
          scale: 0.95,
          duration: 0.4,
          ease: 'power3.out',
        });

        gsap.to(entry.target, {
          autoAlpha: 1,
          duration: 0.4,
          ease: 'power3.out',
        });
      } else {
        gsap.killTweensOf([this.$refs.main, entry.target]);
        gsap.to(this.$refs.main, {
          scale: 1,
          duration: 0.4,
          ease: 'power3.out',
        });

        gsap.to(entry.target, {
          autoAlpha: 0,
          duration: 0.4,
          ease: 'power3.out',
        });
      }

      // old
      // let val = document.body.offsetHeight - window.innerHeight - window.innerHeight * 0.5;
      // val = window.pageYOffset;
      // const scale = modulate(val, [document.body.offsetHeight - window.innerHeight - window.innerHeight * 0.5, document.body.offsetHeight - window.innerHeight], [1, 0.95]);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.app-view {
  transform-origin: 50% 100%;
  position: relative;
  z-index: 2;
  min-height: 100vh;
  border-radius: 0 0 24px 24px;

  @include mq(m) {
    border-radius: 0 0 60px 60px;
  }

  &--no-border {
    border-radius: 0;
    transform: none !important;

    main {
      border-radius: 0;
      padding-bottom: 0;
    }
  }
}

.overlay-affirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.92);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.affirm-as-low-as,
.field-description {
  a {
    border-bottom: 1px solid currentColor;
    transition: border 0.2s $ease-custom;

    @include mq($and: $hover) {
      &:hover {
        border-color: transparent;
      }
    }
  }
}
</style>
