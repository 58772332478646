import axios from 'axios';
import ajax from '@/api/axios';

// eslint-disable-next-line no-undef
let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

export const fetchSingle = ({ type, params = {} }) => ajax.get(`/wp/v2/${type}/`, { params });

export const fetchItems = ({ type, params = {} }) => ajax.get(`/wp/v2/${type}/`, { params });

export const fetchSearch = (search) => ajax.get(`/wp/v2/search/?search=${search}`);

export const sendAjax = (request) => {
  const formData = new FormData();

  formData.append('action', 'do_ajax');
  formData.append('fn', request.fn);
  formData.append('params', JSON.stringify(request.params));

  return axios.post(`${url}/wp-admin/admin-ajax.php`, formData);
};

export const sendSlingShotCode = ({ email, params }) => axios.get(`https://oyj29wiwj3.execute-api.eu-west-3.amazonaws.com/Prod/serials/${email}`, { params });
