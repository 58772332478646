import Vue from 'vue';
// import Cookies from 'js-cookie';
import { unparseCurrency } from '@/assets/js/utils-wc';

const marketing = {};

const transformItemGA = (item) => {
  const categories = item.categories
    ? item.categories.map((cat) => cat.name)
    : [];

  const { name } = item;
  const variant = item.variation ? item.variation.name : '';

  // if (item.key) { // cart line item
  //   name = item.product_title;
  //   variant = item.product_name;
  // }

  return {
    id: `${item.sku}_${item.variation ? item.variation : item.id}`,
    name,
    category: categories.join(' > '),
    variant,
    quantity: item.quantityDelta,
    price: Number(item.price),
    // list_name: 'Search Results',
    // brand: 'Google',
    // list_position: 1,
  };
};

const transformCartItemGA = (item) => {
  const categories = item.categories
    ? item.categories.map((cat) => cat.name)
    : [];

  return {
    id: `${item.sku}_${
      item.variation_id ? item.variation_id : item.product_id
    }`,
    name: item.product_name,
    category: categories,
    variant: item.variation_id ? item.item_name : '',
    quantity: item.quantity,
    price: Number(item.price),
    // list_name: 'Search Results',
    // brand: 'Google',
    // list_position: 1,
  };
};

const transformOrderItemGA = (item) => ({
  id: `${item.sku}_${item.variation_id ? item.variation_id : item.product_id}`,
  name: item.item_name,
  category: '', // TODO missing category
  variant: item.variation_id ? item.item_name : '',
  quantity: item.quantity,
  price: Number(item.total) + Number(item.total_tax),
  // list_name: 'Search Results',
  // brand: 'Google',
  // list_position: 1,
});

const transformItemFB = (item) => {
  const categories = item.categories
    ? item.categories.map((cat) => cat.name)
    : [];

  const { name } = item;
  const variant = item.variation ? item.variation.name : '';

  // if (item.key) { // cart line item
  //   name = item.product_title;
  //   variant = item.product_name;
  // }

  return {
    id: `${item.sku}_${
      item.variation_id ? item.variation_id : item.product_id
    }`,
    name,
    category: categories.join(' > '),
    variant,
    price: Number(item.price),
  };
};

const transformCartItemFB = (item) => {
  const categories = item.categories
    ? item.categories.map((cat) => cat.name)
    : [];

  return {
    id: `${item.sku}_${
      item.variation_id ? item.variation_id : item.product_id
    }`,
    name: item.product_name,
    category: categories.join(' > '),
    variant: item.variation_id ? item.item_name : '',
    quantity: item.quantity,
    price: Number(item.price),
  };
};

const transformOrderItemFB = (item) => ({
  id: `${item.sku}_${item.variation_id ? item.variation_id : item.product_id}`,
  name: item.item_name,
  quantity: item.quantity,
});

const avoid = process.env.NODE_ENV === 'development'
  || window.location.href.includes('dev.');

marketing.install = (vm) => {
  // const isCookieSet = Cookies.get('goliath-cookie');
  const isCookieSet = true;
  // --1 VIEW ITEMS LIST
  vm.prototype.$bus.$on('viewItemsList', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;
    const items = data.map((item) => transformItemGA(item));

    // GA
    if (vm.$gtag) {
      vm.$gtag.event('view_item_list', {
        currency: woocommerce_currency,
        items,
      });
    }
  });

  // --2 VIEW ITEM
  vm.prototype.$bus.$on('viewItem', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

    // GA
    if (vm.$gtag) {
      vm.$gtag.event('view_item', {
        currency: woocommerce_currency,
        items: [transformItemGA(data)],
      });
    }

    // FB
    if (window.fbq) {
      const fbItem = transformItemFB(data);

      window.fbq('track', 'ViewContent', {
        content_ids: [String(fbItem.id)],
        content_category: fbItem.category,
        content_name: fbItem.name,
        // contents: [{ id: fbItem.id }],
        content_type: 'product',
        value: fbItem.price,
        currency: woocommerce_currency,
      });
    }
  });

  // --3 SELECT ITEM
  vm.prototype.$bus.$on('selectItem', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

    // GA
    if (vm.$gtag) {
      vm.$gtag.event('select_item', {
        currency: woocommerce_currency,
        items: [transformItemGA(data)],
      });
    }
  });

  // --4 ADDED TO CART

  vm.prototype.$bus.$on('addedToCart', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

    // GA
    if (vm.$gtag) {
      const item = data.key ? transformCartItemGA(data) : transformItemGA(data);
      vm.$gtag.event('add_to_cart', {
        currency: woocommerce_currency,
        value: data.price * data.quantityDelta,
        items: [item],
      });
    }

    // FB
    if (window.fbq) {
      const fbItem = data.key
        ? transformCartItemFB(data)
        : transformItemFB(data);
      window.fbq('track', 'AddToCart', {
        content_name: fbItem.name,
        content_category: fbItem.category,
        content_ids: [String(fbItem.id)],
        contents: [{ id: fbItem.id, quantity: data.quantityDelta }],
        content_type: 'product',
        value: fbItem.price * data.quantityDelta,
        currency: woocommerce_currency,
      });
    }
  });

  // --5 VIEW CART
  vm.prototype.$bus.$on('viewCart', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { wcSettings } = __VUE_WORDPRESS__.state;
    const value = unparseCurrency(data.totals.subtotal, wcSettings)
      + unparseCurrency(data.totals.subtotal_tax, wcSettings);

    // GA
    if (vm.$gtag) {
      const items = data.items.map((item) => transformCartItemGA(item));
      vm.$gtag.event('view_cart', {
        currency: wcSettings.woocommerce_currency,
        value,
        items,
      });
    }
  });

  // --6 REMOVED TO CART

  vm.prototype.$bus.$on('removedFromCart', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

    // GA
    if (vm.$gtag) {
      vm.$gtag.event('remove_from_cart', {
        currency: woocommerce_currency,
        value: data.price * data.quantityDelta,
        items: [transformItemGA(data)],
      });
    }
  });

  // --7 BEGIN CHECKOUT
  vm.prototype.$bus.$on('beginCheckout', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { wcSettings } = __VUE_WORDPRESS__.state;
    const value = unparseCurrency(data.totals.subtotal, wcSettings)
      + unparseCurrency(data.totals.subtotal_tax, wcSettings);
    const coupon = data.coupons && data.coupons[0] ? data.coupons[0].coupon : '';

    // GA
    if (vm.$gtag) {
      const items = data.items.map((item) => transformCartItemGA(item));
      vm.$gtag.event('begin_checkout', {
        currency: wcSettings.woocommerce_currency,
        value,
        items,
        coupon,
      });
    }

    // FB
    if (window.fbq) {
      const items = data.items.map((item) => transformCartItemFB(item));
      const itemsId = items.map((item) => String(item.id));

      window.fbq('track', 'InitiateCheckout', {
        content_ids: itemsId,
        contents: [items],
        content_type: 'product',
        value,
        num_items: data.item_count,
        currency: wcSettings.woocommerce_currency,
      });
    }
  });

  // --8 ADD PAYMENT INFO
  vm.prototype.$bus.$on('addPaymentInfo', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { wcSettings } = __VUE_WORDPRESS__.state;
    const value = unparseCurrency(data.totals.subtotal, wcSettings)
      + unparseCurrency(data.totals.subtotal_tax, wcSettings);
    const coupon = data.coupons && data.coupons[0] ? data.coupons[0].coupon : '';

    // GA
    if (vm.$gtag) {
      const items = data.items.map((item) => transformCartItemGA(item));
      vm.$gtag.event('add_payment_info', {
        currency: wcSettings.woocommerce_currency,
        coupon,
        payment_type: data.paymentGateway,
        value,
        items,
      });
    }
  });

  // --9 ADD SHIPPING INFO
  vm.prototype.$bus.$on('addShippingInfo', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { wcSettings } = __VUE_WORDPRESS__.state;
    const value = unparseCurrency(data.totals.subtotal, wcSettings)
      + unparseCurrency(data.totals.subtotal_tax, wcSettings);
    const coupon = data.coupons && data.coupons[0] ? data.coupons[0].coupon : '';
    const shippingMethods = Object.keys(data.shipping_methods);
    let shipping_tier;
    shippingMethods.forEach((shippingMethod) => {
      if (data.shipping_methods[shippingMethod].chosen_method) {
        shipping_tier = data.shipping_methods[shippingMethod].label;
      }
    });

    // GA
    if (vm.$gtag) {
      const items = data.items.map((item) => transformCartItemGA(item));
      vm.$gtag.event('add_shipping_info', {
        currency: wcSettings.woocommerce_currency,
        coupon,
        shipping_tier,
        value,
        items,
      });
    }
  });

  // --10 PURCHASED

  vm.prototype.$bus.$on('purchased', (data) => {
    if (avoid || !isCookieSet) return;
    // eslint-disable-next-line no-undef
    const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;
    const coupon = data.coupons && data.coupons[0] ? data.coupons[0].coupon : '';

    // GA
    if (vm.$gtag) {
      const items = data.items.map((item) => transformOrderItemGA(item));
      vm.$gtag.purchase({
        transaction_id: data.id,
        // affiliation: 'Google online store',
        value: data.total,
        currency: woocommerce_currency,
        coupon,
        tax: data.total_tax,
        shipping: Number(data.shipping_total) + Number(data.shipping_tax),
        discount: Number(data.discount_total) + Number(data.discount_tax),
        items,
      });
    }

    // FB
    if (window.fbq) {
      const items = data.items.map((item) => transformOrderItemFB(item));
      const itemsId = items.map((item) => String(item.id));

      window.fbq('track', 'Purchase', {
        content_ids: itemsId,
        // content_id: data.id, non letto
        contents: [items],
        content_type: 'product',
        value: data.total,
        num_items: data.item_count,
        currency: woocommerce_currency,
      });
    }

    // LINKEDIN
    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 6171450 });
    }
  });
};

Vue.use(marketing);
