<template>
  <ValidationProvider
    v-if="!data.hidden"
    v-slot="{ errors, classes }"
    mode="lazy"
    :rules="data.rules"
    :vid="data.vid"
    tag="div"
  >
    <div
      ref="radio-payment"
      class="field field--radio-payment"
      :class="classes"
    >
      <div
        v-for="(paymentOption, j) in optionsWithInstallments"
        :key="j"
      >
        <Spacer
          v-if="j > 0"
          top="s"
        >
          <hr>
        </Spacer>
        <Spacer
          v-if="paymentOption.options && paymentOption.options[0]"
          class="form-group__title"
          :bottom="isMobile ? 's' : 'm'"
        >
          <span v-show="paymentOption.options[0].id !== 'affirm'">
            {{ paymentOption.label }}
          </span>
          <span
            v-if="
              cart &&
                cart.totals &&
                !$route.params.orderId &&
                paymentOption.options[0].id !== 'affirm'
            "
            v-html="
              optionsWithInstallments.length > 1
                ? getTotal(paymentOption.id)
                : $labels.payment_method
            "
          />

          <span
            v-if="
              cart &&
                cart.totals &&
                !$route.params.orderId &&
                paymentOption.options[0].id === 'affirm'
            "
            data-page-type="product"
            class="affirm-as-low-as"
            data-affirm-type="text"
            :data-amount="unparseCurrency(cart.totals.total) * 100"
          >{{ paymentOption.label }}</span>
        </Spacer>
        <div
          v-for="(option, index) in paymentOption.options"
          :key="index"
          class="field-radio-option typo--label-m"
        >
          <Flex
            :align="{
              h: 'between',
              v: 'center',
            }"
            no-wrap
          >
            <label>
              <input
                :id="option.id"
                v-model="computedModel"
                type="radio"
                :name="data.id"
                :value="option.id"
                @change="data.fn ? data.fn(option.id) : false"
              >
              <div class="field-after-input" />
              <span v-html="option.title" />
            </label>
            <div>
              <inline-svg
                :src="require('@/assets/svg/' + option.id + '.svg')"
              />
            </div>
          </Flex>
          <div
            v-show="computedModel === option.id"
            class="field-description"
          >
            <div v-html="option.description" />
            <div v-if="option.id === 'bacs'">
              <BanksDetails />
            </div>
            <div v-if="option.testmode">
              Test mode is ENABLED.
            </div>
            <Spacer
              v-if="option.id === 'stripe'"
              top="s"
            >
              <Radio
                v-model="saved_cards.model"
                :data="saved_cards"
                :model.sync="saved_cards.model"
              />
              <div v-show="showStripeInputs">
                <div id="card-element">
                  <!-- Elements will create input elements here -->
                </div>
                <p
                  id="card-errors"
                  role="alert"
                  class="typo--xs"
                >
                  <!-- Error messages will be shown in this element -->
                </p>
                <Spacer top="m">
                  <Checkbox
                    v-model="save_card.model"
                    :data="save_card"
                    :model.sync="save_card.model"
                  >
                    <label
                      slot="label"
                      class="field-label typo--xs t-uppercase"
                      for="save_card"
                    >{{ $labels[save_card.label] }}</label>
                  </Checkbox>
                </Spacer>
              </div>
            </Spacer>
          </div>
        </div>
      </div>

      <div
        slot="errors"
        class="input-errors"
      >
        {{ errors[0] }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseCurrency, unparseCurrency, minRate } from '@/assets/js/utils-wc';
import { ValidationProvider } from 'vee-validate';
import BanksDetails from '@/components/wc/banks-details.vue';
import Checkbox from '@/components/form/fields/checkbox';
import Radio from '@/components/form/fields/radio';

export default {
  name: 'RadioPayment',
  components: {
    ValidationProvider,
    BanksDetails,
    Checkbox,
    Radio,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      saved_cards: {
        type: 'radio',
        model: null,
        id: 'saved_cards',
        hidden: false,
        placeholder: null,
        options: [],
        fn: this.setSavedCards,
      },
      save_card: {
        id: 'save_card',
        type: 'checkbox',
        checked: false,
        hidden: true,
        model: false,
        label: 'save_card',
      },
      showStripeInputs: false,
      order: null,
    };
  },
  computed: {
    ...mapGetters([
      'paymentMetas',
      'wcSettings',
      'isMobile',
      'customer',
      'customerSavedMethods',
      'cart',
    ]),
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
    optionsWithInstallments() {
      const optionsWithInstallments = [];
      const optionsOneTimePayment = [];

      Object.keys(this.data.options).forEach((property) => {
        if (
          property === 'splitit'
          || this.data.options[property].id === 'splitit'
        ) {
          const optionsSplitit = this.data.options[property];
          optionsSplitit.title = this.$labels.pay_via_splitit;
          optionsSplitit.description = `${this.$labels.no_credit_checks}.</br>${this.$labels.pay_in_monthly_installments}.</br></br>${this.$labels.for_further_informations} </br></br><a href="/support/guide/interest-free-instalments-splitit/" target="_blank" class="t-underline">${this.$labels.read_our_faq}</a>`;
          optionsWithInstallments.push(optionsSplitit);
        } else if (
          property === 'affirm'
          || this.data.options[property].id === 'affirm'
        ) {
          optionsWithInstallments.push(this.data.options[property]);
        } else {
          optionsOneTimePayment.push(this.data.options[property]);
        }
      });
      if (
        optionsWithInstallments.length > 0
        && this.cart.items.some((item) => item.sku.startsWith('GOLIATH-CNC'))
      ) {
        return [
          {
            id: 'installments',
            label: this.$labels.monthly_installments,
            options: optionsWithInstallments,
          },
          {
            id: 'full-price',
            label: this.$labels.one_time_payment,
            options: optionsOneTimePayment,
          },
        ];
      }
      return [
        {
          id: 'full-price',
          label: '',
          options: optionsOneTimePayment,
        },
      ];
    },
  },
  watch: {
    customer: {
      immediate: true,
      handler(val) {
        if (val && val.id) {
          this.save_card.hidden = false;
        }
      },
    },
    customerSavedMethods: {
      immediate: true,
      handler(val) {
        if (
          val
          && typeof val === 'object'
          && val[0]
          && !this.saved_cards.options[0]
        ) {
          const cards = [];
          val.forEach((element) => {
            cards.push({
              id: String(element.token_value),
              title: `${element.method.brand} ${this.$labels.ending_in} ${element.method.last4} (${this.$labels.expires} ${element.expires})`,
            });
          });
          cards.push({
            id: 'new',
            title: this.$labels.use_new_card,
          });
          this.saved_cards.options = cards;
        } else {
          this.showStripeInputs = true;
        }
      },
    },
  },
  async mounted() {
    if (this.$route.query.key && this.$route.params.orderId) {
      const { data } = await this.$store.dispatch('getOrder', {
        order_key: this.$route.query.key,
        order_id: this.$route.params.orderId,
      });
      this.order = data;
    }
  },
  methods: {
    setSavedCards(val) {
      if (val === 'new') {
        this.showStripeInputs = true;
      } else {
        this.showStripeInputs = false;
      }
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings, true);
    },
    getTotal(type) {
      let total = 0;
      // if (this.$route.query.key && this.$route.params.orderId) {
      //   total = getOrderTotal(this.order, this.wcSettings);
      // } else
      if (this.cart && this.cart.totals) {
        total = this.unparseCurrency(this.cart.totals.total);
      }
      if (type === 'installments') {
        return `from ${this.parseCurrency(
          minRate(total, this.wcSettings),
        )}/month`;
      }
      return `${this.parseCurrency(total)}`;
    },
  },
};
</script>

<style lang="scss">
.card-element-wrapper {
  background: $black;
}

#card-element {
  transform: translateY(-2px);
}
.field--radio-payment {
  svg {
    height: 14px;
    @include mq(xxs) {
      height: 16px;
    }
  }
}
// .form .field--radio-payment .field-radio-option input:checked + .field-after-input {
//   top: 7px;
// }
</style>
