var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{class:['single', 'layout--white']},[_c('section',{staticClass:"layout--black"},[_c('Hero',{attrs:{"data":{
        slide: [{
          media: {
            type: 'image',
            image: {
              default: _vm.post.gds_featured_image,
              caption: null,
              shadow: true,
            },
          },
          align_media: 'center',
          title: _vm.post.title.rendered,
        }]
      }}})],1),_c('section',[(_vm.post.acf.intro && _vm.post.acf.intro !== '')?_c('Spacer',{staticClass:"t-center--desktop",attrs:{"tag":"section","y":{ default: 'm2', m: 'xxl' }}},[_c('Wrapper',{attrs:{"size":"l","boxed":""}},[_c('Richtext',{attrs:{"data":{ value: _vm.post.acf.intro, size: 'm'}}})],1)],1):_vm._e()],1),_c('section',{staticClass:"wysiwyg"},_vm._l((_vm.post.acf.content),function(block,index){return _c('Spacer',{key:index,attrs:{"top":{ m: 'm' },"bottom":{ default: 'm', m: 'm' }}},[_c('Wrapper',{attrs:{"size":block.acf_fc_layout === 'content' ? 's' : block.type === 'gallery' ? undefined : block.size ? block.size : 'l',"boxed":block.type !== 'gallery'}},[_c(block.acf_fc_layout === 'content' ? 'Richtext' : 'Media',{tag:"component",attrs:{"data":block.acf_fc_layout === 'content' ? { value: block.richtext, size: 'label-m' } : block,"extra-settings":block.acf_fc_layout === 'media' && block.type === 'gallery' ? { cellAlign: 'center', contain: false, wrapAround: true, template: 'project', draggable: true } : undefined}})],1)],1)}),1),(_vm.banner)?_c('Section',{staticClass:"section-community spacer--section-large",attrs:{"layout":{
      section_layout: 'black'
    }}},[_c('Content',{class:['block', 'block--content'],attrs:{"data":Object.assign(_vm.banner, { layout: 'black', section_layout: { section_classes: 'section-community', section_wrapper: 'default' }})}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }