<template>
  <Spacer
    :class="['breadcrumbs', 'typo--breadcrumbs', open ? 'breadcrumbs--open' : false, bread && crumbs.length > 0 ? 'breadcrumbs--active' : false]"
    x="s"
  >
    <Spacer
      y="xs"
      class="bread typo--label-m"
      @click.native="toggleOpen"
    >
      <Flex
        align="h-between-v-center"
      >
        {{ bread }}

        <Icon
          :class="[open ? 'icon-arrow-down--rotate' : false, 'icon']"
          name="arrow-down"
          :fn="toggleOpen"
          size="s2"
          theme="minimal"
        />
      </Flex>
    </Spacer>

    <Flex
      dir="column"
      tag="ul"
      :class="['crumbs', open ? 'crumbs--active' : false]"
    >
      <Spacer
        v-for="child in crumbs"
        :key="child.id"
        tag="li"
        y="xxs"
      >
        <Cta
          :data="{
            url: child.url,
            title: child.content,
            target: child.target,
            size: 'l'
          }"
        />
      </Spacer>
    </Flex>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';

export default {
  name: 'Breadcrumbs',
  components: {
    Cta,
    Icon,
  },
  data() {
    return {
      bread: null,
      crumbs: [],
      open: false,
    };
  },
  computed: {
    ...mapGetters(['menu', 'isMobile', 'menuOpened']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.open = false;
        if (to.params.parent) {
          this.setBreadcrumbs(to.params.parent);
        } else {
          this.bread = null;
          this.crumbs = [];
          this.$store.commit('SET_BREADCRUMBS', false);
        }
      },
      immediate: true,
    },
    menuOpened(val) {
      if (val) {
        this.$store.commit('SET_BREADCRUMBS', false);
        this.open = false;
      } else if (this.bread) {
        this.$store.commit('SET_BREADCRUMBS', true);
      }
    },
  },
  methods: {
    setBreadcrumbs(parent) {
      const item = this.navigation.find((el) => el.url.includes(parent));
      if (item) {
        this.bread = item.content;
        this.crumbs = item.children;

        this.$store.commit('SET_BREADCRUMBS', true);
      } else {
        this.bread = null;
        this.crumbs = [];

        this.$store.commit('SET_BREADCRUMBS', false);
      }
    },
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  background: $primary-d;
  z-index: 0;
  border-radius: var(--radius);

  transform: translate3d(0, -100%, 0);
  visibility: hidden;
  transition: all 0.1s ease-out, max-height 0.5s $ease-custom;
  pointer-events: none;
  max-height: 48px;

  &--active {
    visibility: visible;
    pointer-events: auto;
    transform: translate3d(0, 0%, 0);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &--open {
    max-height: 600px;
    transition: all 0.1s ease-out, max-height 1.4s $ease-custom;
  }

  .icon {
    margin-top: 1px;
  }

  .crumbs {
    position: relative;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s $ease-custom;

    &--active {
      max-height: 300px;
      transition-duration: 1.4s;
      padding-bottom: 18px;
    }
  }
}
</style>
