<template>
  <div
    v-if="archiveCategory"
    class="related"
  >
    <Archive
      :data="{
        archive: 'product',
        archive_type: 'archive',
        exclude: product,
        layout: false,
        posts_per_page: '3',
        product_category: archiveCategory,
        thumb: 'product',
        title: `Other ${archiveCategory.name}`,
        unboxed: true,
      }"
    />
    <Cta
      :data="{
        url: `/shop/${archiveCategory.slug}`,
        title: `${$labels.product_discover_cta} ${archiveCategory.name}`,
      }"
      theme="button"
    />
  </div>
</template>

<script>
import Archive from '@/components/blocks/archive';
import Cta from '@/components/typo/cta';

export default {
  components: {
    Archive,
    Cta,
  },
  props: {
    product: {
      type: Number,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    archiveCategory() {
      return this.categories.find(
        (category) => category.slug === 'accessories',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
  .related {
    display: grid;
    justify-items: center;
    row-gap: map-get($spacer, "m");
  }
</style>
