import { mapGetters } from 'vuex';

import {
  handleStripePayWithCard,
  handlePaymentAfterOrder,
  createAndStorePaymentIntent,
  addCardToCustomerSavedMethods,
  splititStyle,
} from '@/assets/js/utils-wc';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'pay_for_order',
      },
      groups: {
        payments: {
          visible: true,
          fields: {
            payment_method: {
              type: 'radio-payment',
              model: null,
              id: 'radio-payment',
              rules: 'required',
              placeholder: null,
              options: this.paymentGateways,
              fn: this.setPaymentGateway,
            },
          },
        },
      },

      paymentGatewaySelected: null,
    };
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas']),
  },
  watch: {
    paymentGateways: {
      immediate: true,
      handler(val) {
        if (val) {
          const options = Object.keys(val).map((key) => val[key]);
          Object.assign(this.groups.payments.fields.payment_method, {
            ...this.groups.payments.fields.payment_method,
            options,
          });
        }
      },
    },
  },
  methods: {
    init() {
      if (Number(this.$route.query.affirm) === 1) {
        Object.assign(this.groups.payments.fields.payment_method, {
          ...this.groups.payments.fields.payment_method,
          model: 'affirm',
        });
      }
    },
    async callback() {
      const body = {
        payment_method: this.paymentGatewaySelected,
        metas: {},
      };
      let addCardResult = null;
      if (this.paymentGatewaySelected === 'stripe') {
        await createAndStorePaymentIntent(this.$store, {
          order_id: this.$route.params.orderId,
          order_key: this.$route.query.key,
        });
        // 1 - Save card if request
        if (
          this.$refs['radio-payment']
          && this.$refs['radio-payment'][0]
          && this.$refs['radio-payment'][0].save_card.model
          && (this.$refs['radio-payment'][0].saved_cards.model === 'new'
            || this.$refs['radio-payment'][0].saved_cards.model === null)
        ) {
          addCardResult = await addCardToCustomerSavedMethods(this.$store, {
            stripeInstance: this.paymentMetas.stripe,
          });
        }

        // 2 - Pay with card
        const stripeSource = addCardResult
          ? addCardResult.token
          : this.$refs['radio-payment'][0].saved_cards.model !== 'new'
            ? this.$refs['radio-payment'][0].saved_cards.model
            : null;

        const paymentResultBeforeOrder = await handleStripePayWithCard(
          this.$store,
          { paymentMetas: this.paymentMetas, source: stripeSource },
          this.paymentGatewaySelected,
        );
        if (paymentResultBeforeOrder === false) {
          return false;
        }
        body.metas.stripe = {
          source: stripeSource != null ? stripeSource : undefined,
        };
      }
      const data = {
        orderId: this.$route.params.orderId,
        orderKey: this.$route.query.key,
      };
      const result = await this.$store.dispatch('updatePaymentMethod', {
        body,
        data,
      });
      const paymentResultAfterOrder = handlePaymentAfterOrder(
        this.$store,
        result,
        this.paymentGatewaySelected,
      );
      if (paymentResultAfterOrder) {
        if (this.paymentGatewaySelected === 'splitit') {
          this.$bus.$emit('modal', {
            type: 'splitit',
            id: 'splitit',
            theme: 'dialog',
          });
          this.$nextTick(() => {
            window.Splitit.Popup.setup({
              style: splititStyle,
              checkoutUrl: result.metas.checkout_url,
              container: '#splitit-payment-container',
              onError: () => {
                this.$store.commit('SET_SNACKBAR', {
                  active: true,
                  content: 'Error in saving order',
                });
              },
              onSuccess: () => {
                this.$bus.$emit('removeContentModal', 'splitit');

                this.$store.commit('SET_SNACKBAR', {
                  active: true,
                  content: this.$labels.order_updated,
                  theme: 'success',
                });
                this.$router.push({ name: 'Orders' });
              },
            });
          });
        } else {
          this.$store.commit('SET_SNACKBAR', {
            active: true,
            content: this.$labels.order_updated,
            theme: 'success',
          });
          this.$router.push({ name: 'Orders' });
        }
      }
      return true;
    },
    async setPaymentGateway(method) {
      this.paymentGatewaySelected = method;
    },
  },
};
