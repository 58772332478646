<template>
  <div class="field field--number">
    <slot
      name="label"
      class="field-label"
    />
    <div class="field-input">
      <div>
        <Icon
          name="minus"
          theme="centered"
          size="xs"
          :fn="() => { data.fn([data.cartItemKey, computedValue-1]) }"
        />
      </div>
      <input
        :id="data.id || $vnode.key"
        v-model.trim="computedValue"
        :data-key="data.cartItemKey"
        :name="data.id || $vnode.key"
        type="number"
        :placeholder="data.placeholder"
        :readonly="data.readonly"
        @change="data.fn ? data.fn($event) : false"
      >
      <div>
        <Icon
          name="plus"
          theme="centered"
          size="xs"
          :fn="() => { data.fn([data.cartItemKey, computedValue+1]) }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Input',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    computedValue: {
      get() {
        return Number(this.value);
      },
      set(val) {
        this.$emit('update:value', Number(val));
      },
    },
  },
  methods: {
    remove() {
      this.$el.querySelector('input').value = this.computedValue - 1;
      this.data.fn({
        trigger: this.$el.querySelector('input'),
      });
    },
    add() {
      this.$el.querySelector('input').value = this.computedValue + 1;
    },
  },
};
</script>

<style lang="scss">
</style>
