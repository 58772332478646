<template>
  <div class="thumb">
    <router-link :to="data.permalink">
      <Figure
        v-if="image"
        :data="{ ...image, border: true, lazyload: true }"
        class="media"
      />
      <Spacer
        class="abstract"
        :all="{ default: 'xs', m: 'm' }"
      >
        <Title
          :data="{
            value: data.name,
            tag: 'h2',
            size: 's',
          }"
        />
        <Price
          :product="data"
          theme="thumb"
          tax
        />
        <Availability :product="data" />
      </Spacer>
    </router-link>
    <Cta
      theme="button--full"
      :data="{
        fn: () => {
          addToCart({
            ...data,
            id: String(data.id),
            quantity: 1,
            quantityDelta: 1,
          });
        },
        title: $labels.add_to_cart,
      }"
      :disabled="isOos"
    />
  </div>
</template>

<script>
import { isOos } from '@/assets/js/utils-wc';
import { mapActions } from 'vuex';
import Cta from '@/components/typo/cta';
import Figure from '@/components/media/figure';
import Price from '@/components/wc/price';
import Title from '@/components/typo/title';
import Availability from '@/components/wc/availability';

export default {
  name: 'ProductThumb',
  components: {
    Availability,
    Cta,
    Figure,
    Price,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      let value = false;
      if (this.data.acf.product_thumb) {
        value = this.data.acf.product_thumb;
      } else if (this.data.gds_featured_image) {
        value = this.data.gds_featured_image;
      }
      return value;
    },
    isOos() {
      return isOos(this.data);
    },
  },
  methods: {
    ...mapActions(['addToCart']),
  },
};
</script>

<style lang="scss" scoped>
  .thumb {
    position: relative;
    > a {
      cursor: pointer;
      display: grid;
      text-align: left;

      > * {
        grid-area: 1 / 1;

        &:not(.figure) {
          z-index: 1;
        }
      }

      @media (hover: hover) {
        & + .cta {
          opacity: 0;
        }

        &:hover {
          & + .cta {
            opacity: 1;

            &.cta-disabled {
              opacity: 0.5;
            }
          }
        }
      }

      .media {
        @include aspect-ratio(4, 5);
      }

      .label {
        color: $grey-l;
      }
    }
    .cta {
      width: 100%;
      position: absolute;
      bottom: 0;
      transition: opacity 0.2s $ease-custom;
      z-index: 1;

      @media (hover: hover) {
        &:hover {
          opacity: 1;

          &.cta-disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
</style>
