<template>
  <div :class="['price', theme ? `price--${theme}` : false]">
    <span v-html="product.price_html" />
    <span
      v-if="computedTax"
      class="tax"
    >{{ $labels[`${computedTax}`] }}</span>
  </div>
  <!--
    Per personalizzare prezzo
    <span>
    <del
      v-if="product.sale_price"
      v-html="parseCurrency(product.regular_price)"
    />
    <component
      :is="product.sale_price ? 'ins' : 'span'"
      v-html="parseCurrency(product.price)"
    />
  </span> -->
</template>

<script>
import { mapGetters } from 'vuex';

import { parseCurrency } from '@/assets/js/utils-wc';

export default {
  name: 'Price',
  components: {},
  props: {
    product: {
      type: Object,
      required: true,
    },
    tax: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['wcSettings']),
    computedTax() {
      let value = false;
      if (this.tax) {
        if (this.wcSettings.woocommerce_tax_display_shop === 'excl') {
          value = 'tax_excluded';
        } else value = 'tax_included';
      }
      return value;
    },
  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss" scoped>
  .price {
    align-items: baseline;
    column-gap: map-get($spacer, "xxs");
    display: grid;
    grid-auto-flow: column;
    width: max-content;
    @extend %typo--s;

    &--thumb {
      @extend %typo--label-m;
    }

    .tax {
      @extend %typo--label-m;
    }
  }
</style>
