<template>
  <Wrapper
    size="l"
    boxed
  >
    <Spacer
      :all="{ m: 'xl'}"
      :class="['reserve', data.layout !== 'default' ? `reserve--${data.layout}` : false]"
    >
      <Media
        :data="data.media"
        class="bg"
      />
      <Wrapper
        size="xs2"
        :margin="false"
      >
        <Spacer
          :all="{ default: 's', m: 0 }"
        >
          <Content
            :data="{
              title: titlePrice,
              rich_text: data.rich_text,
              boxed: false,
              section_layout: {
                section_wrapper: true,
                section_classes: ''
              },
            }"
          />

          <Spacer
            :top="{ default: 's2', m: 'm' }"
            class="pricing"
          >
            <Grid col="12">
              <div>
                <Label :data="{ value: data.product.preorder_label }" />
                <Richtext :data="{ value: preorderPrice }" />
              </div>
              <div>
                <Label :data="{ value: data.product.shipping_label }" />
                <Richtext :data="{ value: data.product.shipping_price }" />
              </div>
              <div>
                <Label :data="{ value: data.product.deposit_label }" />
                <Richtext :data="{ value: depositPrice }" />
              </div>
            </Grid>
          </Spacer>

          <Spacer :top="{ default: 'm2', m: 'l' }">
            <Cta
              :data="data.cta"
              :theme="data.cta_layout || 'button'"
            />
          </Spacer>
        </Spacer>
      </Wrapper>
    </Spacer>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseCurrency } from '@/assets/js/utils-wc';

import Media from '@/components/blocks/media';
import Content from '@/components/blocks/content';
import Richtext from '@/components/typo/richtext';
import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';

export default {
  name: 'Reserve',
  components: {
    Media,
    Content,
    Richtext,
    Label,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titlePrice: this.data.title,
      preorderPrice: this.data.product.preorder_price,
      depositPrice: this.data.product.deposit_price,
    };
  },
  computed: {
    ...mapGetters(['product', 'wcSettings']),
  },
  mounted() {
    this.productReserve = this.product(this.data.product_id?.post_name || 'goliath-cnc');

    this.titlePrice = this.data.title.replace('{product.price.save}', `${parseCurrency(this.productReserve.regular_price - this.productReserve.sale_price, this.wcSettings)}`);
    // this.preorderPrice = this.data.product.preorder_price.replace('{product.price.total}', `<del>${parseCurrency(this.productReserve.regular_price, this.wcSettings)}</del>`).replace('{product.price.sale}', `${parseCurrency(this.productReserve.sale_price, this.wcSettings)}`);
    this.preorderPrice = this.data.product.preorder_price.replace('{product.price.total}', `${parseCurrency(this.productReserve.regular_price, this.wcSettings)}`);
    this.depositPrice = this.data.product.deposit_price.replace('{product.price.deposit}', `${parseCurrency(this.productReserve.deposit_amount, this.wcSettings)}`);
    Object.assign(this.data.modal, { ...this.data.modal, richtext: this.data.modal.richtext.replace('{product.price.deposit}', `${parseCurrency(this.productReserve.deposit_amount, this.wcSettings)}`) });

    this.$bus.$emit('populateModal', {
      type: 'content',
      theme: 'overlay',
      id: 'reserve',
      content: this.data.modal,
      product: this.productReserve,
    });
  },
  beforeDestroy() {
    this.$bus.$emit('removeContentModal', 'reserve');
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.reserve {
  position: relative;
  text-align: left;
  background: $grey-xl;

  border-radius: var(--radius);

  &--dark {
    background: $grey-d;
    color: $white;

    @include mq(m) {
      background: transparent;
    }
  }

  @include mq(m) {
    background: transparent;
    border-radius: 0;
  }

}

.bg {
  @include aspect-ratio(3, 2);

  @include mq($until: m) {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    ::v-deep picture {
      position: absolute;
      width: 100%;
    }
  }

  @include mq(m) {
    @include aspect-ratio($auto: true);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }
}

.pricing {
  .label {
    color: $grey-l;
  }
}
</style>
