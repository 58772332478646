import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import wc from './modules/wc';
import auth from './modules/auth';

// eslint-disable-next-line no-undef
const { state } = __VUE_WORDPRESS__;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wp: state,
    breakpoints: null,
    touch: null,
    mobile: null,
    mobileS: null,
    mobileXS: null,
    currentPost: null,
    lang: state.i18n ? state.i18n.default : 'default',
    menuOpened: false,
    breadcrumbs: false,
    transition: true,
    overlayAffirm: false,
  },
  mutations,
  getters,
  actions,
  modules: {
    auth,
    wc,
  },
});
