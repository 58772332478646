<template>
  <main :class="['single', 'layout--default', 'main--no-hero']">
    <Spacer
      class="t-center--desktop"
      :top="{ default: 's', m: 'xl' }"
      :bottom="{ default: 'l', m: 'xxxl' }"
    >
      <Wrapper
        size="xl"
        boxed
      >
        <Title :data="{ value: post.title.rendered, size: 'xxl' }" />
      </Wrapper>
    </Spacer>

    <Spacer
      v-if="post.acf.media"
      tag="section"
      :bottom="{ default: 'm2', m: 'xxl' }"
    >
      <Media
        :data="post.acf.media"
        :extra-settings="{
          cellAlign: 'center',
          contain: false,
          wrapAround: true,
          template: 'project',
          draggable: true,
        }"
      />
    </Spacer>
    <Spacer
      v-if="post.acf.content"
      tag="section"
      :bottom="{ default: 'l', m: 'xxl' }"
    >
      <Content
        :data="
          Object.assign(post.acf.content, {
            cta_layout: 'button',
            hideBorder: true,
            ctas: post.acf.content.ctas ? setFn(post.acf.content.ctas) : null,
          })
        "
      />
    </Spacer>
    <Spacer
      v-if="post.latest"
      tag="section"
      :top="{ default: 'l', m: 'xl' }"
      :bottom="{ default: 'xl', m: 'xxxl' }"
      class="content"
    >
      <Wrapper
        size="xl"
        boxed
      >
        <Spacer :bottom="{ default: 's2', m: 'l' }">
          <Title :data="{ value: 'Latest projects' }" />
        </Spacer>
        <Grid :col="{ default: 12, m: 4 }">
          <div
            v-for="related in post.latest"
            :key="related.id"
            class="item"
          >
            <Thumb :data="related" />
          </div>
        </Grid>

        <Spacer
          class="t-center"
          :top="{ default: 'm2', m: 'xl' }"
          bottom
        >
          <Cta
            :data="{
              title: 'More projects',
              url: '/community/projects/',
            }"
            theme="button"
          />
        </Spacer>
      </Wrapper>
    </Spacer>
    <Section
      v-if="banner"
      :layout="{
        section_layout: banner.layout,
      }"
      class="section-community spacer--section-large"
    >
      <Content
        :class="['block', 'block--content']"
        :data="
          Object.assign(banner, {
            section_layout: {
              section_classes: 'section-community',
              section_wrapper: 'default',
            },
          })
        "
      />
    </Section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Thumb from '@/components/thumb';
import Media from '@/components/blocks/media';
import Content from '@/components/blocks/content';

import Section from '@/components/section';

import data from '@/mixins/data';

export default {
  name: 'SingleWork',
  components: {
    Title,
    Media,
    Content,
    Cta,
    Thumb,
    Section,
  },
  mixins: [data],
  computed: {
    ...mapGetters(['banner']),
  },
  methods: {
    setFn(ctas) {
      if (ctas) {
        ctas.forEach((el) => {
          if (el.cta) {
            el.cta.fn = () => {
              this.$gtag.event('download', {
                event_category: 'Project',
                event_label: this.post.title.rendered,
              });
            };
          }
        });
      }

      return ctas;
    },
  },
};
</script>
