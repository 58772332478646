<template>
  <transition name="fade">
    <Flex
      v-show="show"
      :class="[
        'modal',
        `modal-theme--${theme}`,
        data ? `modal-theme--${data.id}` : '',
      ]"
      align="center"
      :tabindex="show ? 0 : -1"
      @click.native="close"
    >
      <Wrapper
        v-if="data"
        :class="[
          'modal-wrapper',
          theme === 'dialog' || theme === 'overlay'
            ? 'layout--white radius'
            : false,
        ]"
        :size="
          data.type === 'video'
            ? 'xxl'
            : data.type === 'dialog' || data.type === 'splitit'
              ? 'xs'
              : data.type === 'box-detail'
                ? 's'
                : 'm'
        "
        :boxed="data.type === 'video'"
        @click.native.stop=""
      >
        <Icon
          v-if="!data.mandatory"
          name="close"
          :fn="close"
          theme="minimal"
          size="l"
          class="close"
        />
        <template v-if="data.type === 'video'">
          <Video
            ref="video"
            :data="{
              embed: true,
              url: data.url,
            }"
          />
        </template>
        <template v-if="data.type === 'content'">
          <Spacer :all="{ default: 'm', m: 'xl' }">
            <Content
              :data="{
                title: data.content.title,
                rich_text: data.content.richtext,
                ctas: data.content.cta ? [{ cta: data.content.cta }] : [],
                cta_layout: 'underline',
                boxed: false,
              }"
            />

            <Spacer
              v-if="data.id === 'order-goliath'"
              :top="{ default: 'm2', m: 'l' }"
              class="pricing"
            >
              <Grid col="12">
                <div>
                  <Label :data="{ value: data.content.price_label }" />
                  <Richtext
                    class="typo--m"
                    :data="{ value: data.content.price }"
                  />
                </div>
                <div>
                  <Label :data="{ value: $labels.number_months }" />
                  <Flex col="3">
                    <Spacer
                      v-for="(month, i) in months"
                      :key="i"
                      :top="{ default: 'xs', s: 's' }"
                      :right="{ default: 'xs', s: 's' }"
                    >
                      <Cta
                        :data-months="month"
                        :data="{
                          fn: calculateInstallments,
                          title: `${month} ${$labels.months}`,
                          classes:
                            Number(monthSelected) !== Number(month)
                              ? 'cta-inactive'
                              : 'cta-active',
                        }"
                        theme="button"
                      />
                    </Spacer>
                  </Flex>
                </div>
                <div>
                  <Label
                    :data="{
                      value: `${$labels.monthly_installments} ${$labels.interest_zero}`,
                    }"
                  />
                  <Richtext
                    :data="{
                      value: `<span class='typo--m'>${installmentPrice}</span> <small>${$labels.tax_excluded}</small>`,
                    }"
                  />
                </div>
                <div>
                  <Spacer
                    :top="{ default: 'xs', s: 0 }"
                    :bottom="{ default: 'xs', s: 0 }"
                  >
                    <Accordion
                      :data="{
                        ...data.content.accordions,
                        boxed: false,
                        size: 'xl',
                        itemLayout: 'light',
                        triggerTheme: 'underline',
                        triggerSize: 'label-m',
                      }"
                    />
                  </Spacer>
                </div>
              </Grid>
            </Spacer>

            <Spacer
              v-if="data.id === 'reserve' || data.id === 'order-goliath'"
              :top="{ default: 'm2', m: 'l' }"
              class="u-relative"
            >
              <Cta
                :data="{
                  fn: addToCart,
                  title: data.cta ? data.cta.title : $labels.add_to_cart,
                }"
                theme="button"
              />
              <Flex
                class="powered-by--splitit"
                align="v-center"
                :dir="isMobile ? 'column' : 'row'"
              >
                <Spacer
                  :right="isMobile ? false : 's'"
                  :bottom="isMobile ? 'xxs' : false"
                >
                  <Label
                    :data="{
                      size: 's',
                      value: $labels.powered,
                    }"
                  />
                </Spacer>
                <inline-svg
                  class="info-logo color--primary"
                  :src="require('@/assets/svg/splitit-black.svg')"
                />
              </Flex>
            </Spacer>
          </Spacer>
        </template>

        <template v-if="data.type === 'dialog'">
          <Spacer :all="{ default: 's2' }">
            <Spacer
              v-if="data.title"
              :bottom="{ default: 's', m: 's' }"
            >
              <Title
                :data="{
                  value: data.title,
                  size: 'm',
                }"
              />
            </Spacer>
            <Richtext
              :data="{
                value: data.content,
                size: 'label-m',
              }"
            />
            <Spacer :top="{ default: 'm2', m: 'l' }">
              <Flex align="h-center">
                <Cta
                  :data="{
                    fn: dialogFn,
                    title: data.cta,
                  }"
                  theme="button"
                />
              </Flex>
            </Spacer>
          </Spacer>
        </template>

        <template v-if="data.type === 'splitit'">
          <Spacer :all="{ default: 's2' }">
            <Flex
              align="center"
              dir="column"
            >
              <Spacer bottom="xs">
                <Label
                  :data="{
                    size: 's',
                    value: $labels.powered,
                  }"
                />
              </Spacer>
              <inline-svg
                class="info-logo color--primary"
                :src="require('@/assets/svg/splitit-black.svg')"
              />
            </Flex>
            <Spacer y="m">
              <p
                class="typo--label-m"
                v-html="$labels.modal_splitit"
              />
            </Spacer>
            <Flex align="h-center">
              <div
                id="splitit-payment-container"
                :key="show"
              />
            </Flex>
          </Spacer>
        </template>
        <BoxDetail
          v-if="data.type === 'box-detail'"
          :key="show"
          ref="carousel"
          :post="data.post"
        />
      </Wrapper>
      <Wrapper
        v-if="data && data.type === 'box-detail'"
        size="xl"
        class="carousel-controls"
        boxed
      >
        <Icon
          :fn="carouselPrevious"
          name="arrow-left"
          size="l"
          class="previous"
        />
        <Icon
          :fn="carouselNext"
          name="arrow-right"
          size="l"
          class="next"
        />
      </Wrapper>
    </Flex>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseCurrency } from '@/assets/js/utils-wc';

import Accordion from '@/components/blocks/accordion';
import BoxDetail from '@/components/ui/modal/box-detail';
import Content from '@/components/blocks/content';
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';
import Label from '@/components/typo/label';
import Richtext from '@/components/typo/richtext';
import Title from '@/components/typo/title';
import Video from '@/components/media/video';

export default {
  name: 'Modal',
  components: {
    Accordion,
    BoxDetail,
    Content,
    Cta,
    Icon,
    Label,
    Richtext,
    Title,
    Video,
  },
  data() {
    return {
      show: false,
      data: null,
      force: true,
      installmentPrice: '',
      monthSelected: '',
    };
  },
  computed: {
    ...mapGetters(['product', 'wcSettings', 'isMobile']),
    theme() {
      let theme = 'default';
      if (this.data && this.data.theme) theme = this.data.theme;

      return theme;
    },
    months() {
      if (
        this.wcSettings.splitit
        && this.wcSettings.splitit.ic_installment
        && this.wcSettings.splitit.ic_installment[0]
      ) {
        return this.wcSettings.splitit.ic_installment[0].split(',');
      }
      return [];
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add('overflow-hidden');
        document.body.addEventListener('keydown', this.close);
      } else {
        document.body.classList.remove('overflow-hidden');
        document.body.removeEventListener('keydown', this.close);
      }
    },
  },
  mounted() {
    this.$bus.$on('modal', this.init);
    this.$bus.$on('openModal', this.open);
    this.$bus.$on('populateModal', this.content);
    this.$bus.$on('removeContentModal', this.remove);
  },
  methods: {
    init(data) {
      this.data = data;
      this.show = true;
    },
    content(data) {
      this.data = data;
      this.force = false;
    },
    open(id) {
      if (id === this.data.id) {
        this.show = true;
      }
      if (this.data.id === 'order-goliath') {
        this.monthSelected = Math.max(
          ...this.wcSettings.splitit.ic_installment[0].split(','),
        );
        this.calculateInstallments();
      }
    },
    close(e) {
      if (this.data.mandatory) return;
      if (e.keyCode === 27 || e.type === 'click') {
        if (this.data.type === 'video' && this.$refs.video) {
          this.$refs.video.plyr.destroy();
        }
        this.show = false;

        if (this.force) this.data = null;
      }
    },
    remove(id) {
      if (id) {
        if (this.data.id === id) {
          this.show = false;
          this.data = null;
        }
      }
    },
    addToCart() {
      this.$store
        .dispatch(
          'addToCart',
          Object.assign(this.data.product, {
            id: String(this.data.product.id),
            quantity: 1,
            quantityDelta: 1,
          }),
        )
        .then(() => {
          this.show = false;
          this.$router.push('/cart');
        });
    },
    dialogFn() {
      this.data.fn();
      this.remove(this.data.id);
    },
    calculateInstallments(e = null) {
      const productReserve = this.product(
        this.data.product_id?.post_name || 'goliath-cnc',
      );
      if (
        e
        && e.target.closest('.cta')
        && e.target.closest('.cta').dataset.months
      ) {
        this.monthSelected = e.target.closest('.cta').dataset.months;
      }
      // console.log(productReserve.price);
      // console.log(this.monthSelected);
      this.installmentPrice = parseCurrency(
        Math.floor(productReserve.price / this.monthSelected),
        { ...this.wcSettings, precision: 0 },
      );
    },
    carouselPrevious() {
      this.$refs.carousel.previous();
    },
    carouselNext() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  .modal-wrapper {
    overflow: auto;
    max-height: calc(100 * var(--vh) - #{map-get($spacer, xs)});
  }

  .close {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 10;

    @include mq(m) {
      top: 30px;
      right: 30px;
    }
  }

  &-theme--default {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      z-index: -1;
    }

    .modal-wrapper {
      position: static;
      max-height: 80vh;
    }
  }

  &-theme--overlay {
    @include mq($until: m) {
      padding: 0 map-get($spacer, s);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: 0.4;
      z-index: -1;
    }
  }

  &-theme--dialog,
  &-theme--splitit {
    @include mq($until: m) {
      padding: 0 map-get($spacer, s);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: 0.4;
      z-index: -1;
    }

    .close {
      position: absolute;
      top: 6px;
      right: 6px;
      z-index: 10;

      @include mq(m) {
        top: 9px;
        right: 9px;
      }
    }
  }

  .carousel-controls {
    display: none;
    grid-auto-flow: column;
    justify-content: space-between;
    position: absolute;
    pointer-events: none;

    @include mq(m) {
      display: grid;
    }

    > * {
      pointer-events: all;
      &.previous {
        left: map-get($spacer, "xs");

        @include mq(m) {
          left: 0;
        }
      }

      &.next {
        right: map-get($spacer, "xs");

        @include mq(m) {
          right: 0;
        }
      }
    }
  }
}
.powered-by--splitit {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 42px;
  > div {
    @include mq(m) {
      display: block;
    }
  }
  svg {
    height: 24px;
    @include mq(m) {
      height: 36px;
    }
  }
}
// .modal-theme--splitit {
//   .modal-wrapper {
//     background: $primary;
//     color: $black;
//   }
// }
</style>
