<template>
  <Wrapper
    :size="data.size ? data.size : 'l'"
    :boxed="data.boxed !== undefined ? data.boxed : true"
  >
    <Grid
      class="accordion-wrapper"
      col="12"
    >
      <AccordionWrapper
        v-for="(item, index) in data.items"
        :key="index"
        :is-active="index === activeItem"
      >
        <AccordionItem
          :id="kebabCase(item.title_trigger)"
          :theme="{layout: data.itemLayout ? data.itemLayout : 'dark' }"
          :base-hash="kebabCase(data.title)"
        >
          <template slot="accordion-trigger">
            <Cta :data="{ title: item.title_trigger, size: data.triggerSize ? data.triggerSize : 's', theme: data.triggerTheme ? data.triggerTheme : 'default', fn: () => {}}" />
          </template>
          <template slot="accordion-content">
            <Content
              :data="{
                title: item.title,
                rich_text: item.rich_text,
                size_rich_text: 'label-m',
                ctas: item.cta ? [{cta: item.cta }] : [],
                cta_layout: 'underline',
                boxed: false
              }"
            />
          </template>
        </AccordionItem>
      </AccordionWrapper>
    </Grid>
  </Wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase';

import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';

import Content from '@/components/blocks/content';
import Cta from '@/components/typo/cta';

export default {
  name: 'Accordion',
  components: {
    AccordionWrapper,
    AccordionItem,
    Content,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    activeItem: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      kebabCase,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
