<template>
  <transition
    name="fade"
    appear
  >
    <Flex
      v-show="show"
      :class="[
        'popup',
      ]"
      @click.native="close"
    >
      <Wrapper
        :class="[
          'popup-wrapper',
          'layout--white radius'
        ]"
        :size="'m'"
        @click.native.stop=""
      >
        <Icon
          name="close"
          :fn="close"
          theme="default"
          size="m"
          class="close"
        />
        <Figure
          v-if="popup.image"
          :data="Object.assign(popup.image, { caption: null, full_height: false, lazyload: true, ratio: false, object_fit: 'cover' })"
          class="media"
        />
        <Spacer :all="{ default: 'm', m: 'm' }">
          <Content
            :data="{
              title: popup.title,
              rich_text: popup.text,
              ctas: popup.link ? [{ cta: { ...popup.link, fn: close } }] : [],
              cta_layout: 'button',
              boxed: false,
              size_title: 'm',
              size_rich_text: 'label-m'
            }"
          />
        </Spacer>
      </Wrapper>
    </Flex>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import Content from '@/components/blocks/content';
import Figure from '@/components/media/figure';
import Icon from '@/components/ui/icon';
import Cookies from 'js-cookie';

export default {
  name: 'Popup',
  components: {
    Figure,
    Content,
    Icon,
  },
  data() {
    return {
      show: false,
      isInit: false,
      cookie: !!Cookies.get('goliath-popup'),
    };
  },
  computed: {
    ...mapGetters(['popup', 'isMobile', 'transition']),
    theme() {
      let theme = 'default';
      if (this.data && this.data.theme) theme = this.data.theme;

      return theme;
    },
  },
  watch: {
    show(val) {
      if (val) {
        // document.body.classList.add('overflow-hidden');
        document.body.addEventListener('keydown', this.close);
      } else {
        // document.body.classList.remove('overflow-hidden');
        document.body.removeEventListener('keydown', this.close);
      }
    },
    transition(val) {
      if (!val && !this.isInit && !this.cookie) {
        this.isInit = true;
        setTimeout(() => {
          this.show = true;
        }, 1000 * 6);
      }
    },
  },
  mounted() {
  },
  methods: {
    close(e) {
      if (!e || e.keyCode === 27 || e.type === 'click') {
        this.show = false;
        Cookies.set('goliath-popup', '1');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  padding: map-get($spacer, s);

  &.fade-enter-active,
  &.fade-leave-active {
    transition: all 0.2s ease;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }

  @include mq($until: m) {
    align-items: center;
  }

  .popup-wrapper {
    overflow: auto;
    max-height: calc(100 * var(--vh) - #{map-get($spacer, xs)});
    width: 100%;
    max-width: 450px;
    pointer-events: auto;
    margin: 0;
  }

  .close {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 10;

    @include mq(m) {
      top: 16px;
      right: 16px
    }
  }

  .media {
    height: 220px;
  }

  ::v-deep .spacer-cta {
    .flex-inline {
      display: flex;
    }

    .cta a {
      width: 100%;
      display: block;
    }
  }
}
</style>
