<template>
  <ValidationProvider
    v-if="!data.hidden"
    v-slot="{ errors }"
    mode="lazy"
    :rules="data.rules"
    :vid="data.vid"
    tag="div"
  >
    <div
      :class="[
        'field field--textarea',
        { empty: labelEmpty },
        { focus: labelFocus },
      ]"
    >
      <slot
        name="label"
        class="field-label"
      />
      <div class="field-input">
        <textarea
          :id="data.id || $vnode.key"
          v-model.trim="computedModel"
          :placeholder="computedPlaceholder"
          @blur="labelPosition"
          @focus="labelPosition"
        />
      </div>

      <div
        slot="errors"
        class="input-errors"
      >
        {{ errors[0] }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import isEmpty from 'lodash.isempty';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Textarea',
  components: {
    ValidationProvider,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      labelFocus: false,
    };
  },
  computed: {
    labelEmpty() {
      return isEmpty(this.computedModel);
    },
    computedPlaceholder() {
      if (this.data.placeholder && this.labelFocus) {
        return this.$labels[this.data.placeholder];
      }
      return null;
    },
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
  },
  methods: {
    labelPosition(e) {
      if (e.type === 'focus') {
        this.labelFocus = true;
      }
      if (e.type === 'blur') {
        this.labelFocus = false;
      }
    },
  },
};
</script>

<style></style>
