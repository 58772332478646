<template>
  <Wrapper
    size="l"
    boxed
    class="cover"
  >
    <Title
      v-if="data.title && data.title !== ''"
      class="t-center"
      :data="{
        value: data.title,
        size: 'xl',
      }"
    />
    <div class="card">
      <Spacer
        :all="{ default: 's', m: 'l' }"
        :style="{
          backgroundImage: isMobile
            ? `url(${data.media.image.mobile.url}`
            : `url(${data.media.image.default.url}`,
        }"
      >
        <div class="top">
          <Title
            v-if="data.top.label && data.top.label !== ''"
            :data="{
              value: data.top.label,
              tag: 'h3',
            }"
          />
          <RichText
            v-if="data.top.text && data.top.text !== ''"
            :data="{
              value: data.top.text,
            }"
          />
        </div>
        <div class="bottom">
          <RichText
            v-if="data.bottom.text && data.bottom.text !== ''"
            :data="{
              value: data.bottom.text,
              size: 'xs',
            }"
          />
          <Cta
            v-if="data.bottom.link"
            theme="button"
            :data="data.bottom.link"
          />
        </div>
      </Spacer>
    </div>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

import Cta from '@/components/typo/cta';
import RichText from '@/components/typo/richtext';
import Title from '@/components/typo/title';

export default {
  name: 'Cover',
  components: {
    Cta,
    RichText,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
};
</script>

<style lang="scss" scoped>
  .cover {
    display: grid;
    height: 50%;
    row-gap: map-get($spacer, "s2");

    @include mq(m) {
      row-gap: map-get($spacer, "l");
    }

    .card {
      position: relative;
      padding-bottom: 125%;

      @include mq(m) {
        padding-bottom: 50%;
      }

      > div {
        align-content: space-between;
        background: $grey;
        background-position: center;
        background-size: cover;
        border-radius: var(--radius);
        bottom: 0;
        display: grid;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .top,
    .bottom {
      display: grid;
      row-gap: map-get($spacer, "s2");
      max-width: 500px;
    }
  }
</style>
