<template>
  <Spacer
    :all="{ default: 's', m: 'm' }"
    :class="['thumb', `layout--${data.content.layout}`, 'radius']"
  >
    <Flex
      :dir="{ default: 'row', m: 'column'}"
      :align="{ default: 'h-between-v-center', m: 'h-between'}"
      class="flex-height"
    >
      <Title :data="{ value: data.content.title, size: 'l' }" />

      <div>
        <div class="arrow">
          <inline-svg
            :src="require('@/assets/svg/icons/arrow-45.svg')"
          />
        </div>
      </div>
      <Cta
        :data="data.content.ctas[0].cta"
        class="cta"
      />
    </Flex>
  </Spacer>
</template>

<script>
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';

export default {
  name: 'ThumbTitleArrow',
  components: {
    Title,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;

  @include mq(m) {
    @include aspect-ratio(1, 1);
  }

  @include mq($and: $hover) {
    transition: opacity 0.2s $ease-custom;
    &:hover {
      opacity: 0.5;
    }
  }
}
.cta {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;

  ::v-deep a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.arrow {
  display: inline-block;
  position: relative;
  color: $primary;
  width: 30px;

  @include mq(m) {
    width: 60px;
  }
}
</style>
