<template>
  <div class="carousel">
    <slot />
    <transition name="fade">
      <div
        v-show="carousel && lastCell"
        class="next"
      >
        <Icon
          :fn="goTo"
          name="arrow-right"
          size="l"
        />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-show="carousel && carousel.selectedIndex > 0"
        class="prev"
      >
        <Icon
          :fn="goToPrev"
          name="arrow-left"
          size="l"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import medusa from '@/assets/js/observer';
import Icon from '@/components/ui/icon';

require('flickity-imagesloaded');

export default {
  name: 'Carousel',
  components: {
    Icon,
  },
  props: {
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carousel: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
      },
      lastCell: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.carousel = new Flickity(this.$el, Object.assign(this.settings, this.extraSettings));

      medusa.ref.addTarget({
        // eslint-disable-next-line no-underscore-dangle
        id: `carousel-fix-${this._uid}`,
        threshold: 0.99,
        offsets: '0px 80px 0px 80px',
        nodes: [],
        mode: 'default',
        viewport: this.carousel.viewport,
        callback: this.checkLastCell,
        autoremove: false,
      });

      // eslint-disable-next-line no-underscore-dangle
      medusa.ref.pushToTarget(`carousel-fix-${this._uid}`, this.carousel.getLastCell().element);
    });
  },
  beforeUnmount() {
    this.carousel.destroy();

    // eslint-disable-next-line no-underscore-dangle
    medusa.ref.removeTarget(`carousel-fix-${this._uid}`);
  },
  methods: {
    goTo() {
      this.carousel.next('true');
    },
    goToPrev() {
      this.carousel.previous('true');
    },
    checkLastCell(entry) {
      this.lastCell = !entry.isIntersecting;
    },
  },
};
</script>

<style lang="scss">
.carousel {
  .flickity-viewport {
    overflow: visible;
  }
  .item {
    min-height: 100%;
    margin-right: map-get($spacer, s);
    display: flex;

    width: 45%;
    @include mq(m) {
      width: 33.33%;
    }
  }
  .next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 2;
  }

  .prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 2;
  }
}
</style>
