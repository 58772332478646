<template>
  <component
    :is="mergeProps.tag"
    :class="[
      'title',
      `typo--${mergeProps.size}`,
      theme ? `title--${theme}` : false,
    ]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        tag: 'h2',
        size: 'l',
      };

      Object.keys(this.data).forEach(
        (key) => this.data[key] === undefined && delete this.data[key],
      );
      return { ...defaultProps, ...this.data };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  &--box-detail {
    color: $grey;
    text-transform: uppercase;
  }
}
</style>
