import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=55517151&scoped=true&"
import script from "./simple.vue?vue&type=script&lang=js&"
export * from "./simple.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55517151",
  null
  
)

export default component.exports