<template>
  <Grid :col="cols">
    <component
      :is="thumb()"
      v-for="item in items"
      :key="item"
      :data="{
        link: '/',
        date_format: item,
        title: { rendered: item }
      }"
      :class="['skeleton', `skeleton--${thumbComponent}`]"
    />
  </Grid>
</template>

<script>
import Thumb from '@/components/thumb/';
import ThumbTutorial from '@/components/thumb/tutorial';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('tutorial', ThumbTutorial);

export default {
  name: 'Skeleton',
  props: {
    thumbComponent: {
      type: String,
      default: 'default',
    },
    items: {
      type: Number,
      required: true,
    },
    cols: {
      type: Object,
      default: () => ({
        default: 12,
        m: 4,
      }),
    },
  },
  methods: {
    thumb() {
      return thumbs.get(this.thumbComponent);
    },
  },
};
</script>

<style lang="scss">
.skeleton {
  pointer-events: none;
  cursor: default;

  * {
    cursor: default !important;
    pointer-events: none !important;
  }

  &--default {
    .media {
      background: $grey-d;
    }
    .abstract {
      .meta {
        width: 50%;
        background: $grey-d;

        * {
          visibility: hidden;
        }
      }

      .title-wrapper {
        width: 75%;
        background: $grey-d;

        * {
          visibility: hidden;
        }
      }
    }
  }

  &--tutorial {
    @include mq($until: l) {
      min-height: 158px;
    }

    @include mq(l) {
      height: auto;
      @include aspect-ratio(4.4, 3);
    }
  }
}
</style>
