<template>
  <Spacer
    ref="accordionItem"
    template="accordion-trigger"
    tag="li"
    class="accordion__item radius"
    :class="[...layout]"
  >
    <div
      class="accordion__trigger"
      :class="{ accordion__trigger_active: visible }"
      @click="open"
    >
      <Flex align="h-between-v-center">
        <div class="accordion-trigger-text">
          <slot name="accordion-trigger" />
        </div>

        <Spacer
          use-margin
          left="s"
          class="icon icon-wrapper"
        >
          <Icon
            :name="visible ? 'remove' : 'add'"
            theme="minimal"
            size="m"
            :fn="open"
          />
        </Spacer>
      </Flex>
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="visible"
        class="accordion__content"
      >
        <slot name="accordion-content" />
      </div>
    </transition>
  </Spacer>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  components: {
    Icon,
  },
  inject: ['Accordion'],
  props: {
    preOpenedFn: {
      type: Function,
      required: false,
      default: () => {},
    },
    openedFn: {
      type: Function,
      required: false,
      default: () => {},
    },
    theme: {
      type: Object,
      required: false,
      default: () => {},
    },
    baseHash: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active;
    },
    layout() {
      return this.theme ? `layout--${this.theme.layout}` : 'layout--dark';
    },
  },
  created() {
    this.index = this.Accordion.count + 1;
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
        if (this.$route.hash) {
          this.$router.replace({ hash: this.baseHash });
        }
      } else {
        this.$router.replace({ hash: this.$refs.accordionItem.$el.id });
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      this.preOpenedFn();
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      el.style.height = '';
      this.openedFn();
    },
  },
};
</script>

<style lang="scss" scoped>
  .accordion__item {
    text-align: left;
    &.layout--light {
      &.spacer--accordion-trigger {
        padding: 0;
      }
    }
  }

  .accordion__trigger {
    cursor: pointer;

    @include mq($and: $hover) {
      &:not(.accordion__trigger_active):hover {
        opacity: 0.5;
        transition: opacity 0.2s $ease-custom;
      }
    }

    .accordion-trigger-text {
      flex: 1 1 0;
      ::v-deep .cta button {
        text-align: left !important;
      }
    }

    .icon {
      transform: translateY(2px);
      @include mq(m) {
        transform: translateY(2px);
      }
    }
  }
</style>

<style lang="scss">
  .accordion__item {
    &.layout--light {
      .wysiwyg ul,
      .wysiwyg ol {
        margin-top: 0;
      }
    }
  }
</style>
