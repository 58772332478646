<template>
  <Spacer
    :all="{ default: 's', m: 'm' }"
    :class="['thumb', `layout--${data.content.layout}`, 'radius']"
  >
    <Flex
      dir="column"
      align="h-between"
      class="flex-height"
    >
      <Title
        class="title"
        :data="{ value: data.content.title, tag: data.content.tag_title, size: data.content.size_title }"
      />
      <Spacer
        :top="{ default: 'xs', m: 0}"
        :bottom="{ default: 's', m: 0}"
      >
        <Figure :data="data.media.image" />
      </Spacer>
      <Cta
        :data="data.content.ctas[0].cta"
        :theme="data.content.cta_layout"
      />
    </Flex>
  </Spacer>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';

export default {
  name: 'ThumbFeatures',
  components: {
    Figure,
    Title,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  height: 100%;

  ::v-deep img {
    width: 60px;
    height: 60px;

    @include mq(m) {
      width: 144px;
      height: 144px;
    }
  }

  .title {
    min-height: 2.4em;
  }

  @include mq(m) {
    height: auto;
    @include aspect-ratio(1, 1);
  }
}
</style>
