<template>
  <Spacer
    :id="layout.section_id ? layout.section_id : null"
    :class="[
      layout.section_classes && layout.section_classes !== ''
        ? layout.section_classes.split(' ')
        : null,
      `layout--${layout.section_layout}`,
      layout.section_layout === 'custom' ? layout.section_layout_custom : null,
      layout.section_column ? `section--column-${layout.section_column}` : null,
      layout.is_hero && $vnode.key > 0 ? `section--hero--small` : null,
    ]"
    tag="section"
    :template="!layout.is_hero ? sectionSpacer : undefined"
  >
    <Wrapper
      :size="
        layout.section_wrapper !== 'default'
          ? layout.section_wrapper === 'no'
            ? false
            : layout.section_wrapper
          : layout.section_column === '6'
            ? 'xl'
            : false
      "
      :boxed="layout.section_wrapper_boxed || layout.section_column === '6'"
    >
      <Grid
        :col="{ default: 12, m: `${layout.section_column}` }"
        :extra-class="['section-grid']"
      >
        <slot />
      </Grid>
    </Wrapper>
  </Spacer>
</template>

<script>
export default {
  name: 'Section',
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sectionSpacer() {
      let template = 'section';

      if (
        this.layout.section_spacer
          && this.layout.section_spacer !== 'default'
      ) {
        if (this.layout.section_spacer === 'small') template = 'section-small';
        else if (this.layout.section_spacer === 'large') template = 'section-large';
        else if (this.layout.section_spacer === 'empty') template = 'section--block--no-spacer';
      }

      return template;
    },
  },
};
</script>

<style></style>
