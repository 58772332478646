<template>
  <div class="search">
    <form @submit.prevent="submit">
      <input
        v-model="input"
        type="text"
        name="search"
        placeholder="Search..."
      >
      <button>Search</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {
      input: '',
    };
  },
  methods: {
    submit() {
      if (this.input !== '') {
        this.$router.push({
          name: 'Search',
          query: {
            s: this.input,
          },
        });
      }
    },
  },
};
</script>

<style>

</style>
