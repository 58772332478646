<template>
  <main
    :class="[
      'single',
      'layout--default',
      !post.gds_featured_image || post.acf.hide_hero ? 'main--no-hero' : null,
    ]"
  >
    <section>
      <Hero
        :data="{
          slide: [
            {
              media: {
                type: 'image',
                image: {
                  default: !post.acf.hide_hero
                    ? post.gds_featured_image
                    : false,
                  caption: null,
                  shadow: true,
                },
              },
              align_media: post.acf.align_media || 'center',
              title: post.title.rendered,
            },
          ],
        }"
      />
    </section>
    <Spacer
      v-if="categories || post.date_format"
      class="t-center meta"
      tag="section"
      :y="{ default: 'm2', m: 'xl' }"
    >
      <Wrapper
        size="l"
        boxed
      >
        <Spacer :bottom="categories ? 's' : undefined">
          <Label
            :data="{
              value: post.date_format,
              size: 's',
            }"
          />
        </Spacer>
        <Label
          v-if="categories"
          :data="{
            value: categories,
          }"
        />
      </Wrapper>
    </Spacer>
    <Spacer
      :top="
        !(!post.gds_featured_image || post.acf.hide_hero) &&
          !(categories || post.date_format)
          ? { default: 'm2', m: 'xl' }
          : undefined
      "
    >
      <Spacer
        v-if="post.excerpt && post.excerpt.rendered !== ''"
        class="t-center--desktop"
        tag="section"
        :bottom="{ default: 'l', m: 'xxxl' }"
      >
        <Wrapper
          size="l"
          boxed
        >
          <Richtext :data="{ value: post.excerpt.rendered, size: 'm' }" />
        </Wrapper>
      </Spacer>

      <Spacer
        v-if="post.content"
        tag="section"
        bottom="xl"
        class="content"
      >
        <Wrapper
          size="xl"
          boxed
        >
          <Richtext :data="{ value: post.content.rendered, size: 'label-m' }" />
        </Wrapper>
      </Spacer>
    </Spacer>
    <Spacer
      v-if="post.related && post.related.length > 0"
      tag="section"
      :y="{ default: 'l', m: 'xl' }"
      class="content"
    >
      <Wrapper
        size="xl"
        boxed
      >
        <Spacer :bottom="{ default: 's2', m: 'l' }">
          <Title :data="{ value: 'Related Content' }" />
        </Spacer>
        <Grid :col="{ default: 12, m: 4 }">
          <div
            v-for="related in post.related"
            :key="related.id"
            class="item"
          >
            <Thumb :data="related" />
          </div>
        </Grid>

        <Spacer
          class="t-center"
          :top="{ default: 'm2', m: 'xl' }"
        >
          <Cta
            :data="{
              title: 'More news',
              url: {
                name: 'NewsCategory',
                params: {
                  cat: post.gds_taxonomies.categories[0].slug,
                }
              },
            }"
            theme="button"
          />
        </Spacer>
      </Wrapper>
    </Spacer>

    <Section
      v-if="banner"
      :layout="{
        section_layout: banner.layout,
      }"
      class="section-community spacer--section-large"
    >
      <Content
        :class="['block', 'block--content']"
        :data="
          Object.assign(banner, {
            section_layout: {
              section_classes: 'section-community',
              section_wrapper: 'default',
            },
          })
        "
      />
    </Section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import Hero from '@/components/blocks/hero';

import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Thumb from '@/components/thumb';
import Richtext from '@/components/typo/richtext';

import Section from '@/components/section';
import Content from '@/components/blocks/content';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Hero,
    Title,
    Label,
    Cta,
    Richtext,
    Thumb,
    Section,
    Content,
  },
  mixins: [data],
  computed: {
    ...mapGetters(['banner']),
    categories() {
      return this.post.gds_taxonomies && this.post.gds_taxonomies.categories
        ? this.post.gds_taxonomies.categories
          .map((category) => category.cat_name)
          .join(', ')
        : false;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    twttr.widgets.load();

    // eslint-disable-next-line no-undef
    FB.init({
      xfbml: true,
      version: 'v10.0',
    });
  },
};
</script>

<style lang="scss" scoped>
  .meta {
    text-transform: uppercase;
    color: $grey-l;
  }
</style>

<style lang="scss">
  @import "@/assets/scss/src/grid/_wrapper.scss";

  .single {
    .content {
      .wysiwyg {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ol,
        ul,
        blockquote {
          @extend .wrapper;
          @extend .wrapper--s;
        }

        .twitter-tweet {
          margin: 0 auto !important;
        }

        p + .twitter-tweet {
          margin-top: map-get($spacer, s) !important;
          max-width: map-get($wrapper, xs) !important;

          @include mq(m) {
            margin-top: map-get($spacer, m) !important;
          }
        }

        .instagram-media,
        .fb_iframe_widget {
          display: block !important;
          position: relative !important;
          margin-left: auto !important;
          margin-right: auto !important;
          max-width: map-get($wrapper, xs) !important;

          margin-top: map-get($spacer, s) !important;
          margin-bottom: map-get($spacer, s) !important;

          @include mq(m) {
            margin-top: map-get($spacer, m) !important;
            margin-bottom: map-get($spacer, m) !important;
          }
        }

        .fb_iframe_widget {
          & > * {
            margin: 0 auto;
            display: block;
          }
          iframe {
            background: $white;
          }
        }
      }
    }
  }
</style>
