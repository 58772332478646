<template>
  <component
    :is="components[data.type]"
    v-if="data[data.type]"
    :extra-settings="extraSettings"
    :data="
      Object.assign(data[data.type], {
        align: data.align || 'center',
        border: data.border,
        disableCarousel: data.disable_carousel,
        full_height: data.full_height,
        lazyload: data.lazyload,
        object_fit: data.object_fit ? 'cover' : 'contain',
        ratio: data.ratio,
        size: data.size,
        theme: data.theme || '',
      })
    "
  />
</template>

<script>
import { Figure, Video, Gallery } from '@/components/media';

export default {
  name: 'MediaSection',
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      components: {
        default: Figure,
        image: Figure,
        video: Video,
        gallery: Gallery,
      },
    };
  },
};
</script>

<style></style>
