<template>
  <div
    :key="id"
    :class="[theme ? `form-theme--${theme}` : 'form-theme--default']"
  >
    <component :is="setWrapperComponent()">
      <template
        v-if="settings.title"
        slot="form-title"
      >
        <span>{{ settings.title }}</span>
      </template>
      <template
        v-if="settings.subtitle"
        slot="form-subtitle"
      >
        <span>{{ settings.subtitle }}</span>
      </template>

      <template
        slot="form-groups"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <form
            :id="id"
            tag="form"
            novalidate
            class="form"
            @submit.prevent="handleSubmit(callback)"
          >
            <Grid>
              <Column
                v-for="(group, groupKey) in groups"
                :key="groupKey"
                :size="!responsive ? { default: group.size } : { default: 12, s: group.size || 12 }"
                class="form-group"
                :data-form-group="groupKey"
              >
                <component
                  :is="setGroupComponent(group)"
                  :data="group"
                >
                  <div
                    v-if="group.title"
                    slot="title"
                  >
                    <div
                      class="form-group__header"
                    >
                      <div
                        v-if="(group.accordion && group.accordion.toggle) || group.title"
                        class="form-group__title"
                      >
                        <div
                          v-if="group.accordion && group.accordion.toggle"
                          class="field field--checkbox"
                        >
                          <label>{{ group.title }}</label>
                          <div class="field-input">
                            <input
                              v-model="group.visible"
                              type="checkbox"
                            >
                            <div class="field-after-input" />
                          </div>
                        </div>
                        <span v-else>
                          {{ group.title }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Spacer
                    v-if="group.subtitle"
                    slot="subtitle"
                    top="s"
                    class="typo--xs"
                  >
                    {{ group.subtitle }}
                  </Spacer>
                  <div
                    slot="fields"
                  >
                    <div
                      v-if="showEmptyState(group)"
                      class="form-group__fields typo--label-m"
                    >
                      {{ group.emptyState.value }}
                    </div>
                    <Grid
                      v-else
                      class="form-group__fields"
                      :class="{'form-group__fields--no-title' : !(group.accordion && group.accordion.toggle) && !group.title}"
                    >
                      <Column
                        v-for="(field, key) in group.fields"
                        :key="key"
                        :size="!responsive ? { default: field.size } : { default: 12, s: field.size || 12 }"
                      >
                        <component
                          :is="setFieldComponent(field.type)"
                          :key="key"
                          :ref="field.id"
                          :class="[field.id ? `form-field--${field.id}`: '']"
                          :data="field"
                          :model.sync="field.model"
                        >
                          <label
                            slot="label"
                            :for="field.id || key"
                            class="field-label typo--xs t-uppercase spacer--margin--b--xs"
                          >{{ $labels[field.label] }} <sup
                            v-if="field.rules"
                            v-html="isRequired(field.rules)"
                          /></label>
                        </component>
                      </Column>
                    </Grid>
                  </div>
                </component>
              </Column>

              <Column
                v-show="checkEmitGlobal"
                :size="setBtnSize"
                class="form-footer"
              >
                <Cta
                  :data="{
                    title: $labels[settings.sendLabel] || $labels.send,
                    fn: () => {},
                    disabled: checkInvalid(),
                  }"
                  theme="button"
                />
              </Column>
            </Grid>
          </form>
        </ValidationObserver>
      </template>
    </component>
  </div>
</template>

<script>
import Vue from 'vue';
import merge from 'lodash.merge';
import isEmpty from 'lodash.isempty';

import { ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import Cta from '@/components/typo/cta';

import mixins from '@/components/form/mixins';

import DefaultGroup from '@/components/form/groups/default';
import AccordionGroup from '@/components/form/groups/accordion';

import DefaultWrapper from '@/components/form/wrapper/default';
import AccordionFormWrapper from '@/components/form/wrapper/accordion';

import Input from '@/components/form/fields/input';
import Select from '@/components/form/fields/select';
import Checkbox from '@/components/form/fields/checkbox';
import Radio from '@/components/form/fields/radio';
import RadioPayment from '@/components/form/fields/radio-payment';
import Textarea from '@/components/form/fields/textarea';
import Title from '@/components/form/fields/title';

import labels from '@/assets/js/labels';

const customMessages = {
  required: labels.error_required,
  email: labels.error_email,
  required_if: labels.error_required,
  min: (fieldName, placeholders) => `${labels.error_min_pre} ${placeholders.length} ${labels.error_min_after}`,
  max: (fieldName, placeholders) => `${labels.error_max_pre} ${placeholders.length} ${labels.error_max_after}`,
  length: (fieldName, placeholders) => `${labels.error_length_pre} ${placeholders.length} ${labels.error_length_after}`,
  confirmed: (fieldName, placeholders) => `${labels.error_confirmed_pre} "${placeholders.target}" ${labels.error_confirmed_after}`,
};

Object.keys(rules).forEach((rule) => {
  if (customMessages[rule]) {
    extend(rule, Object.assign(rules[rule], { message: customMessages[rule] }));
  } else {
    extend(rule, rules[rule]);
  }
});

export default {
  name: 'Form',
  components: {
    Cta,
    ValidationObserver,
    DefaultWrapper,
    AccordionFormWrapper,
    DefaultGroup,
    AccordionGroup,
    Input,
    Select,
    Radio,
    RadioPayment,
    Textarea,
    Title,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    // hasPaymentIntent: {
    //   type: Boolean,
    //   default: false,
    // },
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    setBtnSize() {
      if (this.responsive) {
        return {
          default: 12,
          m: this.settings.btnSize ? this.settings.btnSize : 12,
        };
      }
      return {
        default: this.settings.btnSize ? this.settings.btnSize : 12,
      };
    },
    checkEmitGlobal() {
      return typeof this.settings.emitGlobal === 'undefined' || !this.settings.emitGlobal;
    },
  },
  beforeCreate() {
    const mixin = mixins.get(this.$options.propsData.id);
    Vue.set(this.$options, merge(this.$options, mixin));
  },
  mounted() {
    if (this.init) {
      this.init();
    }

    if (this.settings.emitGlobal) {
      this.$bus.$on('submitForm', this.submitByEmit);
    }
  },
  beforeDestroy() {
    if (this.settings.emitGlobal) {
      this.$bus.$off('submitForm', this.submitByEmit);
    }
  },
  methods: {
    isEmpty(data) {
      return isEmpty(data);
    },
    showEmptyState(group) {
      if (group.emptyState && group.emptyState.value && group.fields[group.emptyState.conditionalField] && isEmpty(group.fields[group.emptyState.conditionalField].options)) {
        return true;
      }
      return false;
    },
    async submitByEmit(data) {
      if (data === this.id) {
        const valid = await this.$refs.observer.validate();
        if (valid) this.callback();
      }
    },
    setWrapperComponent() {
      if (this.settings.accordion) {
        return AccordionFormWrapper;
      }
      return DefaultWrapper;
    },
    setGroupComponent(group) {
      if (group.accordion) {
        return AccordionGroup;
      }
      return DefaultGroup;
    },
    setFieldComponent(type) {
      if (type === 'select') {
        return Select;
      } if (type === 'checkbox') {
        return Checkbox;
      } if (type === 'textarea') {
        return Textarea;
      } if (type === 'radio') {
        return Radio;
      } if (type === 'radio-payment') {
        return RadioPayment;
      } if (type === 'title') {
        return Title;
      }
      return Input;
    },
    checkInvalid() {
      const invalid = this.$refs.observer ? this.$refs.observer.flags.invalid : false;
      if (this.settings.emitGlobal) {
        this.$bus.$emit('validForm', {
          id: this.id,
          invalid,
          groups: this.groups,
        });
      }
      return invalid;
    },
    isRequired(rule) {
      return rule && rule.includes('required') ? '*' : '';
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
