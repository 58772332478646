var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Spacer',{class:['thumb'],nativeOn:{"click":function($event){return _vm.openDetails($event)}}},[(_vm.data.gds_featured_image)?_c('Figure',{staticClass:"media",attrs:{"data":Object.assign({}, _vm.data.gds_featured_image,
      {ratio: false,
      border: true,
      lazyload: true,
      theme: 'custom-ratio'})}}):_vm._e(),_c('Spacer',{staticClass:"abstract",attrs:{"all":{ default: 'xs', m: 'm' }}},[(_vm.data.acf.amount)?_c('Label',{staticClass:"label",attrs:{"data":{ value: ("x" + (_vm.data.acf.amount)), size: 'm' }}}):_vm._e(),_c('Title',{attrs:{"data":{
        value: _vm.data.title.rendered,
        tag: 'h2',
        size: 's',
      }}})],1),_c('Cta',{attrs:{"theme":"button--full","data":{
      fn: _vm.openDetails,
      title: _vm.$labels.product_details_cta,
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }