<template>
  <router-link :to="data.link">
    <Hero
      :data="{
        slide: [{
          media: {
            type: 'image',
            image: {
              default: data.gds_featured_image,
              caption: null,
              shadow: true,
            },
          },
          align_media: data.acf.align_media || 'center',
          title: data.title.rendered,
        }]
      }"
    />
  </router-link>
</template>

<script>
import Hero from '@/components/blocks/hero';

export default {
  name: 'Thumb',
  components: {
    Hero,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: block;
}
.media {
  @include aspect-ratio(4, 3);

  ::v-deep picture {
    position: absolute;
    width: 100%;
  }

  &.skeleton {
    background: $grey-d;
  }
}
.meta {
  color: $grey-l;
  text-transform: uppercase;
  span {
    margin: 0 6px;
  }
}
</style>
