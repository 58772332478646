<template>
  <Wrapper
    size="l"
    boxed
  >
    <Content :data="{ title: data.title, rich_text: data.rich_text, boxed: false, margin: false, size_title: 'xl' }" />
    <Spacer :top="{ default: 's2', m: 'xxl' }">
      <Wrapper
        size="s"
        :margin="false"
      >
        <Form
          id="newsletter"
          :data="data"
          theme="single"
        />
      </Wrapper>
    </Spacer>
  </Wrapper>
</template>

<script>
import Content from '@/components/blocks/content';
import Form from '@/components/form';
import { loadScript } from '@/assets/js/utils.js';

export default {
  name: 'Newsletter',
  components: {
    Content,
    Form,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {
    /* eslint-disable-next-line no-undef */
    const recaptchaUrl = `https://www.google.com/recaptcha/api.js?render=${__VUE_WORDPRESS__.recaptcha_public_key}`;
    loadScript(recaptchaUrl)
      .then(() => {
        // console.log('recaptcha script loaded', window.grecaptcha);
      });
  },
};
</script>
