/* eslint-disable no-debugger */
export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  menu: (state, getters) => (name) => state.wp.menus[getters.lang][name],
  footer: (state) => (state.wp.options ? state.wp.options.footer : null),
  request:
    (state) => ({ type, params }) => {
      let res = null;
      const slugs = [];
      if (params.slug && params.slug.length > 0) {
        params.slug.forEach((slug) => {
          if (state.wp[type][params.lang][slug]) slugs.push(slug);
        });
        return slugs.length > 0 ? slugs : null;
      }
      const request = state.wp[type].requests.find((req) => {
        if (Object.keys(req.params).length === Object.keys(params).length) {
          res = Object.keys(req.params).every((key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]));
        }
        return res;
      });

      if (request) return request.data;
      return res;
    },
  requestedItems:
    (state, getters) => ({ type, params }) => {
      const request = getters.request({ type, params });
      return request ? request.data.map((id) => state.wp[type][id]) : [];
    },
  singleBySlug:
    (state) => ({ type, slug, lang }) => state.wp[type][lang][slug],
  isTouch: (state) => state.touch,
  isMobile: (state) => state.mobile,
  isMobileS: (state) => state.mobileS,
  isMobileXS: (state) => state.mobileXS,
  breakpoints: (state) => state.breakpoints,
  currentPost: (state) => state.currentPost,
  menuOpened: (state) => state.menuOpened,
  options: (state) => state.wp.options,
  banner: (state) => state.wp.options.banner,
  popup: (state) => state.wp.options.popup,
  breadcrumbs: (state) => state.breadcrumbs,
  transition: (state) => state.transition,
  route: (state) => state.route,
  product: (state) => (id) => (state.wp.product && state.wp.product.default && state.wp.product.default[id] ? state.wp.product.default[id] : null),
  overlayAffirm: (state) => state.overlayAffirm,
};
