import drop from 'lodash.drop';
import omit from 'lodash.omit';

import medusa from '@/assets/js/observer';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      request: null,
      type: null,
      items: [],
      placeholder: 'Loading',
      showMore: this.data.infinite_scroll,
      showMoreAvoid: false,
      skeletonItems: this.data.infinite_scroll
        ? parseInt(this.data.posts_per_page, 10)
        : this.data.archive_type === 'posts'
          ? this.data.posts.length
          : 12,
      offset: 0,
      filters: [],
      filterActive: null,
    };
  },
  computed: {
    ...mapGetters(['route']),
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      }

      if (this.items.length < (parseInt(this.data.posts_per_page, 10) || 12)) {
        this.showMore = false;
      }
    });

    if (this.data.filter) {
      this.loadFilters().then(() => {
        if (this.$route.params.cat) {
          const category = this.$route.params.cat;
          const categoryFilter = this.filters.find(
            (filter) => filter.slug === category,
          );

          if (categoryFilter) {
            this.changeFilter(categoryFilter.id, true);
          }
        }
      });
    }
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      this.$root.$children[0].lazyObserve(this.$el);

      return items;
    },
    async loadFilters() {
      const args = {
        type:
          this.data.archive === 'post'
            ? 'categories'
            : `${this.data.archive}-category`,
        params: {
          lang: this.$store.state.lang,
          hide_empty: true,
        },
      };
      this.filters = await this.$store.dispatch('getItems', args);
    },
    async changeFilter(id, fromRoute = false) {
      this.items = [];
      this.offset = 0;

      const category = this.data.archive === 'post'
        ? 'categories'
        : `${this.data.archive}-category`;
      if (this.filterActive === id) {
        this.filterActive = null;
        this.request = Object.assign(this.request, {
          params: Object.assign(omit(this.request.params, [category]), {
            offset: 0,
          }),
        });
        if (this.$route.path.includes('/community/news')) {
          this.$router.replace('/community/news');
        }
      } else {
        this.filterActive = id;
        this.request = Object.assign(this.request, {
          params: { ...this.request.params, [category]: id, offset: 0 },
        });

        if (!fromRoute && this.$route.path.includes('/community/news')) {
          this.$router.replace({
            name: 'NewsCategory',
            params: {
              cat: this.filters.find((filter) => filter.id === id).slug,
            },
          });
        }
      }
      this.loadItems();
    },
    setRequest() {
      let postType = null;
      const categories = {};
      const excluded = [];
      let include = [];
      let slugArray = [];

      this.type = this.data.archive_type;

      if (this.type === 'archive') {
        const {
          archive, archive_category, product_category, tags, exclude,
        } = this.data;
        postType = archive === 'post' || archive === 'page' ? `${archive}s` : archive;
        if (product_category) {
          categories.category = product_category.term_id
            ? product_category.term_id
            : product_category.id;
        }
        if (archive_category) {
          const taxonomy = archive_category.taxonomy === 'category'
            ? 'categories'
            : archive_category.taxonomy;
          categories[taxonomy] = archive_category.term_id;
        }
        if (tags) {
          categories.tags = tags;
        }
        if (exclude) {
          excluded.push(exclude);
        }
      } else if (this.type === 'posts') {
        postType = `${this.data[this.type][0].post_type}s`;
        slugArray = this.data[this.type].map((item) => item.post_name);
      } else if (this.type === 'taxonomy') {
        postType = 'categories';
        include = this.data[postType].map((item) => item.term_id);
      }

      const slug = slugArray.length > 0
        ? this.data.infinite_scroll
          ? drop(slugArray, this.offset)
          : slugArray
        : null;
      const offset = this.type !== 'posts' ? this.offset : 0;

      if (this.filterActive) {
        const category = this.data.archive === 'post'
          ? 'categories'
          : `${this.data.archive}-category`;
        categories[category] = this.filterActive;
      }

      this.request = {
        type: postType,
        params: {
          ...categories,
          slug,
          include: include.length > 0 ? include : null,
          exclude: excluded.length > 0 ? excluded : null,
          per_page: this.data.infinite_scroll
            ? parseInt(this.data.posts_per_page, 10) || 12
            : parseInt(this.data.max_posts, 10) || 100,
          offset,
          orderby:
            postType === 'goliath_box'
              ? 'menu_order'
              : slugArray.length > 0
                ? 'include_slugs'
                : this.type === 'taxonomy'
                  ? 'id'
                  : 'date',
          lang: this.$store.state.lang,
        },
      };
    },
    setInfiniteScroll() {
      if (this.$el.querySelector('.infinite')) {
        medusa.ref.addTarget({
          id: 'infinite-scroll',
          threshold: 0.0,
          nodes: this.$el.querySelector('.infinite'),
          mode: 'default',
          callback: this.setOffset,
          autoremove: false,
        });
      }
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      this.showMoreAvoid = true;
      if (
        this.type === 'posts'
        && this.offset + (parseInt(this.data.posts_per_page, 10) || 12)
          >= this.data[this.type].length
      ) {
        medusa.ref.removeTarget('infinite-scroll');
        this.showMore = false;
        return;
      }
      this.offset += parseInt(this.data.posts_per_page, 10) || 12;
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.showMoreAvoid = false;
        if (newItems.length < (parseInt(this.data.posts_per_page, 10) || 12)) {
          medusa.ref.removeTarget('infinite-scroll');
          this.showMore = false;
        } else if (
          window.pageYOffset + window.innerHeight
          === document.body.offsetHeight
        ) {
          this.requestOffset();
        }
      });
    },
  },
};
