var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Wrapper',{attrs:{"size":"l","boxed":""}},[_c('Spacer',{class:['reserve', _vm.data.layout !== 'default' ? ("reserve--" + (_vm.data.layout)) : false],attrs:{"all":{ m: 'xl'}}},[_c('Media',{staticClass:"bg",attrs:{"data":_vm.data.media}}),_c('Wrapper',{attrs:{"size":"xs2","margin":false}},[_c('Spacer',{attrs:{"all":{ default: 's', m: 0 }}},[_c('Content',{attrs:{"data":{
            title: _vm.titlePrice,
            rich_text: _vm.data.rich_text,
            boxed: false,
            section_layout: {
              section_wrapper: true,
              section_classes: ''
            },
          }}}),_c('Spacer',{staticClass:"pricing",attrs:{"top":{ default: 's2', m: 'm' }}},[_c('Grid',{attrs:{"col":"12"}},[_c('div',[_c('Label',{attrs:{"data":{ value: _vm.data.product.preorder_label }}}),_c('Richtext',{attrs:{"data":{ value: _vm.preorderPrice }}})],1),_c('div',[_c('Label',{attrs:{"data":{ value: _vm.data.product.shipping_label }}}),_c('Richtext',{attrs:{"data":{ value: _vm.data.product.shipping_price }}})],1),_c('div',[_c('Label',{attrs:{"data":{ value: _vm.data.product.deposit_label }}}),_c('Richtext',{attrs:{"data":{ value: _vm.depositPrice }}})],1)])],1),_c('Spacer',{attrs:{"top":{ default: 'm2', m: 'l' }}},[_c('Cta',{attrs:{"data":_vm.data.cta,"theme":_vm.data.cta_layout || 'button'}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }