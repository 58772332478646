import Vue from 'vue';

// eslint-disable-next-line
const usd = __VUE_WORDPRESS__.state.wcSettings.woocommerce_currency === "USD";

const labels = {
  accept_cookie: 'I accept cookies',
  account: 'Account',
  account_created:
    'The account has been created successfully.<br>Now you can login.',
  account_details: 'Account details',
  account_number: 'Account number',
  sort_code: 'ABA/Routing number',
  actions: 'Actions',
  add: 'Add',
  add_business: 'Add business information',
  add_coupon: 'Apply',
  add_new_card: 'Add new card',
  add_to_cart: 'Add to cart',
  additional_information: 'Additional information',
  address_1: 'Address',
  address_2: 'Address details',
  address_2_placeholder: 'Apartment, suit, unit, etc.',
  addresses: 'Addresses',
  available: 'available',
  back_to_cart: 'Back to cart',
  bank: 'Bank',
  bic_swift: 'BIC/SWIFT',
  billing_address: 'Billing Address',
  cancel_card: 'Delete card',
  cancel_card_dialog: 'Are you sure you want to delete this card?',
  cancel_order: 'Cancel order',
  cancel_order_dialog: 'Are you sure you want to cancel this order?',
  cannot_pay_for_order: 'You cannot pay for this order',
  cart: 'Cart',
  change_currency_content:
    'It seems the selected currency is not right for the country selected. Please update the cart with the correct currency for the selected country. ',
  change_currency_cta: 'Update currency',
  change_currency_title: 'Change of currency request',
  check_on_splitit:
    'You can check the status of your payments on the Splitit website.',
  check_serial: 'Check serial',
  checkout: 'Checkout',
  choose_option: 'Choose an option',
  city: 'City',
  close: 'Close',
  company: 'Company',
  countries_cta: 'Which countries do we ship to?',
  country: 'Country',
  coupon: 'Coupon',
  create_account: 'Create account',
  create_accunt: 'Create account',
  date: 'Date',
  decline_cookie: 'I decline cookies',
  delete: 'Delete',
  details: 'Details',
  discount: 'Discount',
  display_name: 'Display name',
  do_you_have_account: 'Do you have an account?',
  downloadUrlFirmware: 'Download Firmware',
  downloadUrlSoftware: 'Download Software',
  edit: 'Edit',
  email: 'Email',
  empty_cart: 'Empty Cart',
  ending_in: 'ending in',
  error_confirmed_after: '',
  error_confirmed_pre: 'The field must be the same of',
  error_email: 'The email is not valid',
  error_generic: 'An error occurred',
  error_generic_customer: 'Cannot get customer data.',
  error_generic_login:
    'Something went wrong during the login. You should login again.',
  error_length_after: 'characters',
  error_length_pre: 'The field must have',
  error_max_after: 'characters',
  error_max_pre: 'The field must have maximum',
  error_min_after: 'characters',
  error_min_pre: 'The field must have at least',
  error_required: 'The field is required',
  error_user_addresses_update: 'A problem occurred while saving the data.',
  error_user_update:
    'A problem occurred while saving the data. <br>Please check them before retrying.',
  experience: 'Experience',
  expires: 'expires',
  first_name: 'First name',
  fiscal_code: 'Fiscal code',
  for_further_informations:
    'Please ensure that your credit limit is sufficient: Splitit works on a pre-authorization system, it maintains a hold on a consumer\'s credit card for the entire amount but charges only the current installment.',
  future_payments: 'Future payments',
  go_to: 'Go to',
  go_to_checkout: 'Proceed to checkout',
  go_to_orders: 'Go to your orders',
  go_to_payment: 'Proceed to payment',
  go_to_shop: 'Go back to shop',
  goliathg1x: usd
    ? 'Goliath serial code G1-U/XXXXXX'
    : 'Goliath serial code G1-E/XXXXXX',
  goto_splitit: 'Go to Splitit',
  hide_order_summary: 'Hide order summary',
  iban: 'IBAN',
  id: 'ID',
  insert_coupon: 'Insert your coupon',
  interest_zero: '(interest 0%)',
  is_business: 'Business information',
  last_name: 'Last name',
  logged_in_with_mail: 'You\'re logged with the e-mail',
  login: 'Login',
  logout: 'Logout',
  lost_password: 'Forgot your password?',
  main_area_for_use: 'Main area for use',
  main_material: 'Main material',
  make_default: 'Make default',
  may_be_interested_in: 'You may  be interested in...',
  modal_splitit:
    'Click on the "Pay" button to be redirected to the Splitit website to set up your payment plan and enter your credit card details. <br>Please note that the order will not be concluded until you\'ll have completed the procedure on the Splitit website.',
  monthly_installments: 'Monthly installments',
  months: 'Months',
  my_account: 'My account',
  no_credit_checks: 'No credit checks and 0% interest',
  no_order: 'No order has been placed yet.',
  no_saved_card: 'You have no saved cards',
  number_months: 'Number of months',
  old_password: 'Old password',
  one_time_payment: 'One time payment',
  order: 'Order',
  order_cancelled: 'Your order was cancelled',
  order_details: 'Order details',
  order_failed:
    'Unfortunately your order cannot be processed as the originating bank/merchant has declined your transaction. Please attempt your purchase again',
  order_infos: 'Order information',
  order_notes: 'Order notes',
  order_notes_placeholder: 'Need a specific delivery day? Sending a gift? ',
  order_received: 'Your order has been received',
  order_updated: 'Your order was updated',
  order_updates: 'Order updates',
  orders: 'Orders',
  orders_notice:
    'Head <a href="https://goliathcnc.com/my-account" target="_blank">here</a> for orders placed before May 11th.',
  out_of_stock: 'Out of stock',
  password: 'Password',
  password_placeholder: 'Minimum 6 characters',
  pay_for_order: 'Pay for order',
  pay_in_monthly_installments:
    'Pay your order in convenient monthly installments with your credit card (debit cards are not valid for the Splitit payment option)',
  pay_via_splitit: 'Credit Card via Splitit',
  payment: 'Payment',
  payment_method: 'Payment method',
  payment_methods: 'Payment methods',
  pec_email: 'PEC email',
  phone: 'Phone',
  place_order: 'Place Order',
  postcode: 'Postcode / ZIP',
  powered: 'Powered by',
  product: 'Product',
  product_details_cta: 'View detail',
  product_discover_cta: 'Discover other',
  products: 'Products',
  profile: 'Profile',
  purpose: 'Purpose',
  read_our_faq: 'Know more',
  refunded: 'Refunded',
  register: 'Create an account',
  remove: 'Remove',
  repeat_password: 'Repeat password',
  save: 'Save',
  save_card: 'Save card for future payments',
  saved_cards: 'Saved cards',
  saved_changes: 'Changes have been saved',
  sdi_code: 'SDI code',
  select_option: 'Select option',
  send: 'Send',
  sensort11: 'Positioning sensor T1-1/XXXXXX',
  sensort12: 'Positioning sensor T1-2/XXXXXX',
  ship_different_address: 'Ship to a different address?',
  shipping: 'Shipping',
  shipping_address: 'Shipping Address',
  shipping_excluded: 'Calculated at checkout',
  shipping_exstimated: 'Extimated shipping',
  shipping_free: 'Free shipping',
  shipping_method: 'Shipping method',
  shipping_methods: 'Shipping methods',
  shipping_methods_empty_states:
    'Please enter your address to know the available shipping methods.',
  shop: 'shop',
  show_order_summary: 'Show order summary',
  state: 'State / Country',
  status: 'Status',
  subtotal: 'Subtotal',
  success_newsletter: 'Thank you for subscribing!',
  tax_id: 'Tax ID',
  tax: usd ? 'Taxes' : 'VAT',
  tax_excluded: usd ? 'Excl. taxes' : 'Excl. VAT',
  tax_excluded_extended: usd ? 'Excluded taxes' : 'Excluded VAT',
  tax_extimated: usd ? 'Extimated taxes' : 'Extimated VAT',
  tax_included: usd ? 'Incl. taxes' : 'Incl. VAT',
  tax_message:
    'The tax amount for your country is calculated once all billing information is filled in.',
  terms_link: 'terms and conditions.',
  terms_pre: 'I agree with your',
  thank_you: 'Thank you for your order!',
  thank_you_error: 'There is a problem with your order...',
  title_coupon: 'Discount code',
  total: 'Total',
  totals: 'Totals',
  use_new_card: 'Use a new credit card',
  username_email: 'E-mail',
  vat_number: 'VAT number',
  welcome_back: 'Welcome back',
  wrong_login_data:
    'Incorrect data. <br>Please try again or reset your password.',
  wrong_mail: 'This email is not registered in this website.',
  wrong_serial: 'Wrong informations',
  your_cart: 'Your cart',
  your_cart_empty: 'Your cart is currently empty',
  affirm_payorder: 'Please wait until Affirm is loaded.',
  affirm_thxpage:
    'You\'ll be redirected to the "Thank you page".<br> Please do not close the page until the end of redirection.',
};

labels.install = () => {
  Vue.prototype.$labels = labels;
};

Vue.use(labels);

export default labels;
