import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {
        title: this.$labels.billing_address,
        accordion: {
          open: false,
          toggle: false,
        },
        sendLabel: 'save',
      },
      groups: {
        billing: {
          visible: true,
          fields: {
            first_name: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'first_name',
              rules: 'required',
              size: 6,
            },
            last_name: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'last_name',
              rules: 'required',
              size: 6,
            },
            address_1: {
              type: 'text',
              model: '',
              placeholder: null,
              size: 6,
              label: 'address_1',
              rules: 'required',
            },
            address_2: {
              type: 'text',
              model: '',
              placeholder: 'address_2_placeholder',
              size: 6,
              label: 'address_2',
            },
            email: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'email',
              rules: 'required|email',
              size: 6,
            },
            phone: {
              type: 'text',
              model: '',
              placeholder: null,
              size: 6,
              label: 'phone',
              rules: 'required',
            },
            country: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'country',
              rules: 'required',
              options: {},
              size: 6,
              fn: () => {
                this.setStates();
              },
            },
            state: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'state',
              rules: 'required',
              options: {},
              size: 6,
            },
            city: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'city',
              rules: 'required',
              size: 6,
            },
            postcode: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'postcode',
              rules: 'required',
              size: 6,
            },
            fiscal_code: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'fiscal_code',
              size: 12,
            },
            // is_business: {
            //   id: 'is_business',
            //   type: 'checkbox',
            //   model: false,
            //   placeholder: null,
            //   label: 'is_business',
            //   rules: '',
            //   fn: this.toggleIsBusiness,
            // },
            is_business: {
              type: 'title',
              size: 'm',
              value: 'is_business',
            },
            company: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'company',
              size: 6,
              // hidden: !this.isBusiness,
            },
            vat_number: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'vat_number',
              size: 6,
              // hidden: !this.isBusiness,
            },
            // pec_email: {
            //   type: 'text',
            //   model: '',
            //   placeholder: null,
            //   label: 'pec_email',
            //   rules: 'email',
            //   size: 6,
            //   // hidden: !this.isBusiness,
            // },
            sdi_code: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'sdi_code',
              size: 12,
            },
          },
        },
      },

      isBusiness: false,
    };
  },
  computed: {
    ...mapGetters({
      customerBilling: 'customer',
      geoData: 'geoData',
    }),
  },
  watch: {
    customerBilling: {
      immediate: true,
      handler(val) {
        if (val && Object.keys(val).length > 0 && this.groups.billing) {
          this.groups.billing.fields.first_name.model = val.billing.first_name ? val.billing.first_name : '';
          this.groups.billing.fields.last_name.model = val.billing.last_name ? val.billing.last_name : '';
          this.groups.billing.fields.address_1.model = val.billing.address_1 ? val.billing.address_1 : '';
          this.groups.billing.fields.address_2.model = val.billing.address_2 ? val.billing.address_2 : '';
          this.groups.billing.fields.email.model = val.billing.email ? val.billing.email : '';
          this.groups.billing.fields.phone.model = val.billing.phone ? val.billing.phone : '';
          this.groups.billing.fields.country.model = val.billing.country ? val.billing.country : '';
          this.groups.billing.fields.city.model = val.billing.city ? val.billing.city : '';
          this.groups.billing.fields.state.model = val.billing.state ? val.billing.state : '';
          this.groups.billing.fields.postcode.model = val.billing.postcode ? val.billing.postcode : '';
          this.groups.billing.fields.company.model = val.billing.company ? val.billing.company : '';
          this.groups.billing.fields.vat_number.model = val.billing.vat_number ? val.billing.vat_number : '';
          this.groups.billing.fields.fiscal_code.model = val.billing.fiscal_code ? val.billing.fiscal_code : '';
          // this.groups.billing.fields.pec_email.model = val.billing.pec_email ? val.billing.pec_email : '';
          this.groups.billing.fields.sdi_code.model = val.billing.sdi_code ? val.billing.sdi_code : '';
          // if (val.billing.company !== '' || val.billing.vat_number !== '') {
          //   this.groups.billing.fields.is_business.model = true;
          //   this.showBusinessFields();
          // }
          if (val.billing.country !== 'IT') {
            this.groups.billing.fields.fiscal_code.hidden = true;
            this.groups.billing.fields.sdi_code.hidden = true;
          }
        }
      },
    },
  },
  methods: {
    init() {
      this.countries = this.geoData.countries;
      this.states = this.geoData.states;

      Object.assign(this.groups.billing.fields.country, { ...this.groups.billing.fields.country, options: this.countries, model: this.customerBilling && this.customerBilling.billing ? this.customerBilling.billing.country : 'IT' });

      if (this.groups.billing.fields.country.model) {
        Object.assign(this.groups.billing.fields.state, { ...this.groups.billing.fields.state, options: this.states[this.groups.billing.fields.country.model], model: this.customerBilling && this.customerBilling.billing ? this.customerBilling.billing.state : 'RM' });
      }
    },
    setStates() {
      if (this.groups.billing.fields.country.model !== 'IT') {
        this.groups.billing.fields.fiscal_code.hidden = true;
        this.groups.billing.fields.sdi_code.hidden = true;
      } else {
        this.groups.billing.fields.fiscal_code.hidden = false;
        this.groups.billing.fields.sdi_code.hidden = false;
      }
      Object.assign(this.groups.billing.fields.state, { ...this.groups.billing.fields.state, options: this.states[this.groups.billing.fields.country.model], model: this.customerBilling.billing.state || 'RM' });
    },
    // toggleIsBusiness() {
    //   this.isBusiness = !this.isBusiness;
    //   if (this.isBusiness) {
    //     this.showBusinessFields();
    //   } else {
    //     this.hideBusinessFields();
    //   }
    // },
    // hideBusinessFields() {
    //   this.groups.billing.fields.company.hidden = true;
    //   this.groups.billing.fields.vat_number.hidden = true;
    //   this.groups.billing.fields.pec_email.hidden = true;
    //   this.groups.billing.fields.sdi_code.hidden = true;
    // },
    // showBusinessFields() {
    //   this.groups.billing.fields.company.hidden = false;
    //   this.groups.billing.fields.vat_number.hidden = false;
    //   this.groups.billing.fields.pec_email.hidden = false;
    //   this.groups.billing.fields.sdi_code.hidden = false;
    // },
    async callback() {
      const body = {
        billing_first_name: this.groups.billing.fields.first_name.model,
        billing_last_name: this.groups.billing.fields.last_name.model,
        billing_address_1: this.groups.billing.fields.address_1.model,
        billing_address_2: this.groups.billing.fields.address_2.model,
        billing_email: this.groups.billing.fields.email.model,
        billing_phone: this.groups.billing.fields.phone.model,
        billing_country: this.groups.billing.fields.country.model,
        billing_city: this.groups.billing.fields.city.model,
        billing_state: this.groups.billing.fields.state.model,
        billing_postcode: this.groups.billing.fields.postcode.model,
        billing_company: this.groups.billing.fields.company.model,
        billing_vat_number: this.groups.billing.fields.vat_number.model,
        billing_fiscal_code: this.groups.billing.fields.fiscal_code.model,
        // billing_pec_email: this.groups.billing.fields.pec_email.model,
        billing_sdi_code: this.groups.billing.fields.sdi_code.model,
      };

      await this.$store.dispatch('editAddress', body);
    },
  },
};
