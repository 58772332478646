<template>
  <Wrapper
    size="l"
    boxed
  >
    <Spacer :top="{ default: 's2', m: 'm' }">
      <Wrapper
        size="s"
        :margin="false"
      >
        <transition-group
          name="fade"
          tag="div"
        >
          <Form
            v-show="!success"
            id="slingshot"
            key="first-step"
          />
          <div
            v-if="success"
            key="last-step"
            class="last-step"
          >
            <Flex
              :inline="!isMobile"
              class="flex-auto"
            >
              <Spacer
                v-for="(item, key, index) in data"
                :key="index"
                :right="Object.keys(data).length > 1 ? 's' : false"
                :top="index > 0 ? { default: 'xs', m: 0 } : false"
              >
                <Cta
                  :data="{
                    title: $labels[key],
                    url: item,
                    target: '_blank',
                    fn: () => setGa(key),
                  }"
                  theme="button"
                />
              </Spacer>
            </Flex>
          </div>
        </transition-group>
      </Wrapper>
    </Spacer>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';
import Cta from '@/components/typo/cta';

export default {
  name: 'FormSlingshot',
  components: {
    Form,
    Cta,
  },
  data() {
    return {
      success: false,
      data: {},
    };
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    this.$bus.$on('slingshotLinks', this.setSlingshotLinks);
  },
  beforeDestroy() {
    this.$bus.$off('slingshotLinks', this.setSlingshotLinks);
  },
  methods: {
    setSlingshotLinks(data) {
      if (data) {
        this.success = true;
        this.data = data;
      }
    },
    setGa(key) {
      this.$gtag.event('download', {
        event_category: 'Software',
        event_label: key,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.last-step {
  top: 0;
  left: 0;
  position: absolute;
}
</style>
