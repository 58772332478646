<template>
  <Spacer
    :all="{ default: 's', m: 'm' }"
    :class="['thumb', `layout--${data.content.layout}`, 'radius', 't-center']"
  >
    <Flex
      dir="column"
      align="h-between"
      class="flex-height"
    >
      <div />
      <Media
        v-if="data.media[data.media.type]"
        :data="data.media"
      />
      <Flex
        class="label-wrapper"
        align="h-center-v-right"
      >
        <Label
          :data="{ value: data.content.label, size: 'm' }"
        />
      </Flex>
    </Flex>
  </Spacer>
</template>

<script>
import Media from '@/components/blocks/media';
import Label from '@/components/typo/label';

export default {
  name: 'ThumbIconLabel',
  components: {
    Media,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  @include aspect-ratio(1, 1);
  // height: 100%;

  ::v-deep img {
    width: 60px;
    height: 60px;

    @include mq(m) {
      width: 144px;
      height: 144px;
    }
  }

  .label-wrapper {
    @include mq($until: m) {
      min-height: 2.4em;
    }
  }
}
</style>
