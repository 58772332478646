<template>
  <Spacer
    :class="['thumb']"
    @click.native="openDetails"
  >
    <Figure
      v-if="data.gds_featured_image"
      :data="{
        ...data.gds_featured_image,
        ratio: false,
        border: true,
        lazyload: true,
        theme: 'custom-ratio'
      }"
      class="media"
    />
    <Spacer
      class="abstract"
      :all="{ default: 'xs', m: 'm' }"
    >
      <Label
        v-if="data.acf.amount"
        class="label"
        :data="{ value: `x${data.acf.amount}`, size: 'm' }"
      />
      <Title
        :data="{
          value: data.title.rendered,
          tag: 'h2',
          size: 's',
        }"
      />
    </Spacer>
    <Cta
      theme="button--full"
      :data="{
        fn: openDetails,
        title: $labels.product_details_cta,
      }"
    />
  </Spacer>
</template>

<script>
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';

export default {
  name: 'BoxThumb',
  components: {
    Figure,
    Title,
    Label,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openDetails() {
      this.$bus.$emit('populateModal', {
        type: 'box-detail',
        theme: 'overlay',
        id: 'box-detail',
        post: this.data,
      });
      this.$bus.$emit('openModal', 'box-detail');
    },
  },
};
</script>

<style lang="scss" scoped>
  .thumb {
    cursor: pointer;
    display: grid;
    text-align: left;

    > * {
      grid-area: 1 / 1;

      &:not(.figure) {
        z-index: 1;
      }
    }
    .cta {
      opacity: 1;
      align-self: end;
      transition: opacity 0.2s $ease-custom;
    }

    @media (hover: hover) {
      .cta {
        opacity: 0;
      }
      &:hover {
        .cta {
          opacity: 1;
        }
      }
    }

    .media {
      @include aspect-ratio(4, 5);
    }

    .label {
      color: $grey-l;
    }
  }
</style>
