<template>
  <main
    v-if="post"
    class="single--product main--no-hero main-theme--white"
  >
    <Section
      :layout="{
        section_wrapper: 'xl',
        section_wrapper_boxed: true,
        section_layout: 'default',
        section_column: '1',
        section_spacer: 'empty',
      }"
    >
      <Column
        v-if="post.gds_featured_image"
        :size="{ default: 12, m: 5 }"
        class="image-column"
      >
        <Media
          :data="{
            type: 'image',
            image: post.gds_featured_image,
            ratio: false,
            theme: 'single-product',
          }"
        />
      </Column>
      <Column
        tag="aside"
        :size="{ default: 12, m: 7 }"
      >
        <header>
          <Title :data="{ value: title }" />
          <Title :data="{ value: post.name, size: 's' }" />
        </header>
        <Media
          class="image--desktop"
          :data="{
            type: 'image',
            image: post.gds_featured_image,
            ratio: false,
            theme: 'single-product',
          }"
        />
        <Price
          :product="post"
          tax
        />
        <Richtext
          v-if="post.short_description"
          :data="{
            value: post.short_description,
          }"
        />
        <div class="shipping-block">
          <Label
            :data="{ value: $labels.shipping, size: 'm' }"
            theme="grey"
          />
          <Richtext
            :data="{ value: $labels[`shipping_${post.acf.product_shipping}`] }"
          />
          <Cta
            v-if="$store.state.wp.site.locale !== 'us'"
            theme="underline"
            :data="{
              url: '/support/faq#which-countries-do-you-ship-to',
              title: $labels.countries_cta,
            }"
          />
        </div>
        <Number
          :key="cartLoading"
          :data="{
            id: `number`,
            fn: numberChanged,
            cartItemKey: post.id,
            readonly: cartLoading,
          }"
          :value.sync="numberToBeAdded"
        />
        <Cta
          :theme="isOos ? 'button--oos' : 'button'"
          :data="{
            fn: addToCart,
            title: isOos ? $labels.out_of_stock : $labels.add_to_cart,
          }"
          :disabled="isOos"
        />
      </Column>
      <Column
        v-if="
          (post.acf.column_1 && post.acf.column_1.length > 0) ||
            (post.acf.column_2 && post.acf.column_2.length > 0)
        "
        :offset="{ default: 0, m: 5 }"
        :size="{ default: 12, m: 6 }"
        class="details"
      >
        <div
          v-if="post.acf.column_1 && post.acf.column_1.length > 0"
          class="column-1"
        >
          <div
            v-for="(item, index) in post.acf.column_1"
            :key="index"
          >
            <Label
              :data="{ value: item.label, size: 's' }"
              theme="detail"
            />
            <Richtext
              :data="{ value: item.text, size: 'label-m' }"
              theme="no-space"
            />
          </div>
        </div>
        <div
          v-if="post.acf.column_2 && post.acf.column_2.length > 0"
          class="column-2"
        >
          <div
            v-for="(item, index) in post.acf.column_2"
            :key="index"
          >
            <Label
              :data="{ value: item.label, size: 's' }"
              theme="detail"
            />
            <Richtext
              :data="{ value: item.text, size: 'label-m' }"
              theme="no-space"
            />
          </div>
        </div>
      </Column>
      <Column
        v-if="post.gallery && post.gallery.length > 0"
        class="gallery-column"
        size="12"
      >
        <Media
          :data="{ type: 'gallery', gallery: post.gallery }"
          :extra-settings="{
            template: 'full',
            wrapAround: true,
            cellAlign: 'center',
          }"
        />
      </Column>
      <Column size="12">
        <Related
          :product="post.id"
          :categories="post.categories"
        />
      </Column>
    </Section>
  </main>
</template>

<script>
import data from '@/mixins/data';
import { mapGetters } from 'vuex';
import { isOos } from '@/assets/js/utils-wc';

import Cta from '@/components/typo/cta';
import Label from '@/components/typo/label';
import Media from '@/components/blocks/media';
import Number from '@/components/form/fields/number';
import Price from '@/components/wc/price';
import Related from '@/components/wc/related-products';
import Richtext from '@/components/typo/richtext';
import Section from '@/components/section';
import Title from '@/components/typo/title';

export default {
  name: 'SingleProduct',
  components: {
    Cta,
    Label,
    Media,
    Number,
    Price,
    Related,
    Richtext,
    Section,
    Title,
  },
  mixins: [data],
  data() {
    return {
      numberToBeAdded: 1,
    };
  },
  computed: {
    ...mapGetters(['cartLoading', 'showCart', 'wcSettings']),
    isOos() {
      return isOos(this.post);
    },
    title() {
      let value = this.post.name;
      const categories = this.post.gds_taxonomies.product_cat;

      if (categories && categories.length > 0) {
        const childCategory = categories.find(
          (category) => category.parent !== 0,
        );

        if (childCategory) {
          value = childCategory.name;
        } else value = categories[0].name;
      }

      return value;
    },
  },
  async mounted() {
    // Marketing
    this.$bus.$emit('viewItem', this.post);
    // Marketing
    // Da abilitare solo con varianti
    //  this.$bus.$emit('selectItem', this.product);
  },
  methods: {
    addToCart() {
      this.$store.dispatch('addToCart', {
        ...this.post,
        id: String(this.post.id),
        quantity: this.numberToBeAdded,
        quantityDelta: this.numberToBeAdded,
      });
    },
    numberChanged(val) {
      if (val[0]) {
        /* eslint-disable */
        this.numberToBeAdded = val[1];
      } else {
        this.numberToBeAdded = val.target.value;
      }
      if (this.numberToBeAdded < 1) {
        this.numberToBeAdded = 1;
      }
      if (
        this.post.stock_quantity &&
        this.numberToBeAdded > this.product.stock_quantity
      ) {
        this.numberToBeAdded = this.product.stock_quantity;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.single {
  &--product {
    /deep/ .section-grid {
      row-gap: map-get($spacer, "xxl");
    }
    aside {
      align-content: start;
      display: grid;
      justify-items: start;
      row-gap: map-get($spacer, "m2");

      header {
        display: grid;
        row-gap: map-get($spacer, "xs");
      }
    }

    .image-column {
      display: none;

      @include mq(m) {
        display: initial;
      }
    }

    .image--desktop {
      @include mq(m) {
        display: none;
      }
    }

    .gallery-column {
      grid-row: 2;

      @include mq(m) {
        grid-row: initial;
      }
    }

    .shipping-block {
      display: grid;
      gap: map-get($spacer, "xs");
    }

    .details {
      column-gap: map-get($spacer, "s");
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      justify-self: stretch;
      row-gap: map-get($spacer, "m");

      @include mq(m) {
        grid-auto-flow: column;
      }

      > * {
        align-content: start;
        display: grid;
        row-gap: map-get($spacer, "m");
      }
    }
  }
}
</style>
