<template>
  <Wrapper
    boxed
    size="xxl"
  >
    <Grid
      v-for="(spec, index) in data.specification"
      :key="index"
      col="12"
    >
      <Grid>
        <Column :size="{ default: 12, m: 6 }">
          <Title :data="{ value: spec.title }" />
        </Column>
        <Column :size="{ default: 12, m: 6 }">
          <Grid col="6">
            <Details
              v-for="(specs, indexSpecs) in spec.specs"
              :key="indexSpecs"
              :data="specs"
            />
          </Grid>
        </Column>
        <Column
          class="border-line border-line--large"
          size="12"
        />
      </Grid>
    </Grid>

    <Spacer
      v-if="data.cta"
      :top="{ default: 's2', m: 'l' }"
      class="t-center"
    >
      <Cta
        :data="data.cta"
        theme="button"
      />
    </Spacer>
  </Wrapper>
</template>

<script>
import Title from '@/components/typo/title';
import Details from '@/components/typo/details';
import Cta from '@/components/typo/cta';

export default {
  name: 'TechSpecs',
  components: {
    Title,
    Details,
    Cta,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
