<template>
  <div>{{ data.title }} - simple</div>
</template>

<script>
export default {
  name: 'Simple',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
