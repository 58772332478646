<template>
  <component
    :is="tag"
    :class="[
      'flex',
      ...alignItems,
      ...direction,
      inline ? 'flex-inline' : false,
      noWrap ? 'flex-nowrap' : false
    ]"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Flex',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    align: {
      type: [Object, String],
      default: '',
    },
    dir: {
      type: [Object, String],
      default: 'row',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alignItems() {
      const { align } = this;
      const aligns = [];
      if (typeof align === 'string' && align !== '') {
        aligns.push(`align-items-${align}`);
      } else if (typeof align === 'object') {
        Object.keys(align).forEach((key) => {
          if (key !== 'default') {
            aligns.push(`align-items-${key}-${align[key]}`);
          } else {
            aligns.push(`align-items-${align[key]}`);
          }
        });
      }

      return aligns;
    },

    direction() {
      const { dir } = this;
      const directions = [];
      if (typeof dir === 'string' && dir !== '') {
        directions.push(`dir-${dir}`);
      } else if (typeof dir === 'object') {
        Object.keys(dir).forEach((key) => {
          if (key !== 'default') {
            directions.push(`dir-${key}-${dir[key]}`);
          } else {
            directions.push(`dir-${dir[key]}`);
          }
        });
      }

      return directions;
    },
  },
};
</script>

<style>

</style>
