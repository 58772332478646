import labels from '@/assets/js/labels';

import {
  recoveryPassword,
} from '@/api';

import sessionHandler from '@/assets/js/sessionHandler';

export const removeCartCookies = () => {
  document.cookie = `woocommerce_cart_hash=; Path=${process.env.VUE_APP_BASE_URL}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `woocommerce_items_in_cart=; Path=${process.env.VUE_APP_BASE_URL}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  let cocartSessionCookie = document.cookie.match(new RegExp('(^| )wp_cocart_session\\w+=([^;]+)'));
  // console.log('cocartSessionCookie regex', cocartSessionCookie);
  if (cocartSessionCookie && cocartSessionCookie.length > 0) {
    cocartSessionCookie = cocartSessionCookie[0].trim();
    const cookieKey = cocartSessionCookie.split('=')[0];
    console.log('cookieKey', cookieKey);
    document.cookie = `${cookieKey}=; Path=${process.env.VUE_APP_BASE_URL}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

export default {
  async login({ commit }, body) {
    commit('SET_AUTH_LOADING', true);
    try {
      await sessionHandler.login(body);
      commit('SET_USER', sessionHandler.user);
      commit('SET_AUTH_LOADING', false);
      return sessionHandler.user;
    } catch (error) {
      commit('SET_SNACKBAR', { active: true, content: labels.wrong_login_data });
      commit('SET_AUTH_LOADING', false);
      return null;
    }
  },
  async recoveryPassword({ commit }, body) {
    try {
      const { data } = await recoveryPassword(body);
      commit('SET_SNACKBAR', { active: true, content: data.message, theme: 'success' });
    } catch (error) {
      commit('SET_SNACKBAR', { active: true, content: labels.wrong_mail });
    }
  },
  logout({ commit }) {
    sessionHandler.logout();
    removeCartCookies();

    commit('SET_USER', null);
    commit('SET_CUSTOMER', null);
    commit('SET_CUSTOMER_SAVED_METHODS', null);
  },
};
