import { sendSlingShotCode } from '@/api';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'check_serial',
      },
      groups: {
        slingshot: {
          visible: true,
          fields: {
            email: {
              type: 'email',
              model: null,
              placeholder: null,
              label: 'email',
              rules: 'required|email',
              size: 6,
            },
            goliathg1x: {
              type: 'text',
              model: null,
              placeholder: null,
              label: 'goliathg1x',
              rules: 'required',
              size: 6,
            },
            sensort11: {
              type: 'text',
              model: null,
              placeholder: null,
              label: 'sensort11',
              rules: 'required',
              size: 6,
            },
            sensort12: {
              type: 'text',
              model: null,
              placeholder: null,
              label: 'sensort12',
              rules: 'required',
              size: 6,
            },
          },
        },
      },
    };
  },
  methods: {
    async callback() {
      const body = {
        email: this.groups.slingshot.fields.email.model,
        params: {
          serialgoliath: this.groups.slingshot.fields.goliathg1x.model,
          serialfirsttower: this.groups.slingshot.fields.sensort11.model,
          serialsecondtower: this.groups.slingshot.fields.sensort12.model,
        },
      };

      await sendSlingShotCode(body)
        .then((res) => {
          this.$bus.$emit('slingshotLinks', res.data);
        })
        .catch(() => {
          this.$store.commit('SET_SNACKBAR', { active: true, content: 'Error, wrong informations' });
        });
      // this.$bus.$emit('responseSlingShot', body)
    },
  },
};
