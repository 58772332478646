var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"gallery",class:[
    'gallery',
    _vm.extraSettings && _vm.extraSettings.template
      ? ("gallery-template--" + (_vm.extraSettings.template))
      : false ]},[(!_vm.data.disableCarousel)?[_vm._l((_vm.extraSettings.wrapAround
        ? _vm.data.concat( _vm.data)
        : _vm.data),function(item,index){return _c('Flex',{key:index,staticClass:"item",attrs:{"align":"v-center"}},[_c('Figure',{ref:index,refInFor:true,attrs:{"data":Object.assign(item, {
            full_height: false,
            lazyload: false,
            ratio: false,
            object_fit: 'cover',
            border: _vm.extraSettings && !_vm.extraSettings.template === 'project',
          })}})],1)}),_c('div',{staticClass:"next"},[_c('Icon',{attrs:{"fn":_vm.goTo,"name":"arrow-right","size":"l"}})],1),(
        _vm.extraSettings &&
          (_vm.extraSettings.template === 'project' ||
            _vm.extraSettings.template === 'full')
      )?_c('div',{staticClass:"prev"},[_c('Icon',{attrs:{"fn":_vm.goToPrev,"name":"arrow-left","size":"l"}})],1):_vm._e()]:_vm._e(),(_vm.data.disableCarousel)?[_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Grid',{attrs:{"col":{ default: 12, m: _vm.data.length < 3 ? 12 / _vm.data.length : 4 }}},_vm._l((_vm.data),function(item,index){return _c('Figure',{key:index,ref:index,refInFor:true,attrs:{"data":Object.assign(item, {
              full_height: false,
              lazyload: true,
              ratio: true,
              object_fit: 'contain',
            })}})}),1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }