<template>
  <footer
    v-if="footer"
    :class="[
      $route.meta.hideHeader ? 'footer-none' : false,
      $route.meta.customView || isMobile ? 'footer-visible' : false,
    ]"
  >
    <div class="layout--dark">
      <Wrapper
        boxed
        size="xl"
        class="typo--label-m"
      >
        <Spacer template="footer">
          <Spacer :bottom="{ default: 'l', m: 'xxl' }">
            <Grid>
              <Column
                class="info order-1"
                :size="{ default: 12, m: 3 }"
              >
                <Spacer :top="{ default: 'l', m: 0 }">
                  <Flex :align="{ default: 'h-between', m: 'h-left' }">
                    <inline-svg
                      class="info-logo color--primary"
                      :src="require('@/assets/svg/springa-logo.svg')"
                    />
                    <Spacer
                      class="info-text"
                      :left="{ default: 'xxs', l: 'm' }"
                    >
                      <Spacer bottom="xxs">
                        <Title
                          class="color--primary"
                          :data="{ value: title, size: 'xs' }"
                        />
                      </Spacer>
                      <Richtext
                        :data="{
                          value: footer.list[0].content,
                          size: 'label-m',
                        }"
                      />
                    </Spacer>
                  </Flex>
                </Spacer>
              </Column>
              <Column
                class="order-2"
                :size="{ default: 12, m: 9 }"
              >
                <Grid
                  tag="ul"
                  :col="{ default: 6, m: 3 }"
                >
                  <li
                    v-for="item in navigation"
                    :key="item.id"
                    :class="[item.css]"
                  >
                    <Spacer bottom="xxs">
                      <Title
                        class="color--primary"
                        :data="{ value: item.content, size: 'xs' }"
                      />
                    </Spacer>

                    <ul v-if="item.children">
                      <Spacer
                        v-for="child in item.children"
                        :key="child.id"
                        tag="li"
                        bottom="xxs"
                      >
                        <Cta
                          :data="{
                            url: child.url,
                            title: child.content,
                            target: child.target,
                          }"
                        />
                      </Spacer>
                    </ul>
                  </li>
                </Grid>
              </Column>
            </Grid>
          </Spacer>
          <Spacer class="typo--xs">
            <Grid>
              <Column :size="{ default: 12, m: 3 }">
                <Flex align="v-left">
                  <Spacer
                    v-for="social in footer.social"
                    :key="social.label"
                    use-margin
                    right="xs"
                  >
                    <a
                      :href="social.url"
                      target="_blank"
                      class="social color--primary"
                    >
                      <inline-svg :src="require(`@/assets/svg/icons/${social.label}.svg`)" />
                    </a>
                  </Spacer>
                </Flex>
              </Column>
              <Column :size="{ default: 12, m: 9 }">
                <Grid>
                  <Column :size="{ default: 12, xs: 6, m: 3 }">
                    <Richtext
                      :data="{ value: footer.credits[0].content, size: 'xs' }"
                      class="no-link"
                    />
                  </Column>
                  <Column :size="{ default: 12, xs: 6, m: 3 }">
                    <Richtext
                      :data="{ value: footer.credits[1].content, size: 'xs' }"
                      class="no-link"
                    />
                  </Column>
                  <Column :size="{ default: 12, xs: 6, m: 3 }">
                    <Richtext
                      :data="{ value: footer.credits[2].content, size: 'xs' }"
                      class="no-link"
                    />
                  </Column>
                  <Column :size="{ default: 12, xs: 6, m: 3 }">
                    <Richtext
                      :data="{ value: footer.credits[3].content, size: 'xs' }"
                      class="no-link"
                    />
                  </Column>
                </Grid>
              </Column>
            </Grid>
          </Spacer>
        </Spacer>
      </Wrapper>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Footer',
  components: {
    Cta,
    Title,
    Richtext,
  },
  computed: {
    ...mapGetters(['footer', 'menu', 'isMobile']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
    title() {
      return this.footer.list[0].label.replace(
        '{currentYear}',
        new Date().getFullYear(),
      );
    },
  },
  mounted() {
    const cookiePolicy = document.querySelector('#cookie-policy');
    if (cookiePolicy) {
      cookiePolicy.addEventListener('click', () => {
        // eslint-disable-next-line
        window._iub.cs.api.openPreferences();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
footer {
  margin-top: -60px;

  &.footer-visible {
    opacity: 1 !important;
    visibility: visible !important;
  }

  &.footer-none {
    display: none !important;
  }
}

.social {
  svg {
    width: 20px;
    height: 20px;
  }
}

.order-1 {
  order: 2;

  @include mq(m) {
    order: 1;
  }
}

.order-2 {
  order: 1;

  @include mq(m) {
    order: 2;
  }
}

.info {
  &-logo {
    height: 72px;
  }

  &-text {
    width: 50%;

    @include mq(m) {
      width: auto;
    }
  }
}

.no-link {
  ::v-deep a {
    border: 0;
  }
}
</style>
