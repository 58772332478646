<template>
  <Wrapper
    :size="data.wrapper || 'l'"
    :class="[`layout--${data.layout}`]"
    :boxed="(typeof data.boxed !== 'undefined') ? data.boxed : data.section_layout ? data.section_layout.section_wrapper !== 's' : true"
    :margin="(typeof data.margin !== 'undefined') ? data.margin : true"
  >
    <Flex
      dir="column"
      align="v-between"
      class="flex-height"
    >
      <div>
        <Label
          v-if="data.label && data.label !== ''"
          :data="{
            value: data.label,
            tag: data.tag_label,
            size: data.size_label
          }"
        />
        <Title
          v-if="data.title && data.title !== ''"
          :data="{
            value: data.title,
            tag: data.tag_title,
            size: data.size_title
          }"
        />
        <Spacer
          v-if="data.rich_text && data.rich_text !== ''"
          :top="classes.includes('section-community') ? { default: 'm', m: 'xl' } : data.title !== '' || data.label !== '' ? 'm' : false"
        >
          <Wrapper
            :size="data.section_layout ? data.section_layout.section_wrapper === 'default' ? 's' : false : 's'"
            :margin="false"
          >
            <RichText
              :data="{
                value: data.rich_text,
                size: data.size_rich_text
              }"
            />
          </Wrapper>
        </Spacer>

        <Spacer
          v-if="data.specs"
          top="m"
        >
          <Grid :col="{ default: 12, m: 6 }">
            <div
              v-for="(spec, index) in data.specs"
              :key="index"
              :class="!data.hideBorder ? 'border-line' : false"
            >
              <Details
                :key="index"
                :data="spec"
              />
            </div>
          </Grid>
        </Spacer>
      </div>
      <Spacer
        v-if="data.ctas && data.ctas.length > 0 && !classes.includes('section-community')"
        class="spacer-cta"
        :top="{ default: 's2', m: 'l' }"
      >
        <Flex
          :inline="!isMobile"
          class="flex-auto"
        >
          <Spacer
            v-for="(item, index) in data.ctas"
            :key="index"
            :right="data.ctas.length > 1 ? 's' : false"
            :top="index > 0 ? { default: 'xs', m: 0 } : false"
          >
            <Cta
              v-if="item.cta"
              :data="item.cta"
              :theme="data.cta_layout"
            />
          </Spacer>
        </Flex>
      </Spacer>
    </Flex>

    <component
      :is="data.ctas ? data.ctas[0].cta.target === '_blank' ? 'a' : 'router-link' : 'div'"
      v-if="classes.includes('section-community')"
      class="arrow arrow-community"
      :to="data.ctas && data.ctas[0].cta.target !== '_blank' ? data.ctas[0].cta.url : '/'"
      :href="data.ctas && data.ctas[0].cta.target === '_blank' ? data.ctas[0].cta.url : false"
      :target="data.ctas && data.ctas[0].cta.target === '_blank' ? '_blank' : false"
    >
      <inline-svg
        :src="require('@/assets/svg/icons/arrow-45.svg')"
      />
    </component>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
import Details from '@/components/typo/details';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Cta,
    Details,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    classes() {
      return this.data.section_layout ? this.data.section_layout.section_classes !== '' ? this.data.section_layout.section_classes.split(' ') : [] : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow-community {
  position: relative;
  display: inline-block;
  width: 60px;

  @include mq($until: m) {
    svg {
      transform: translateY(10px);
    }
  }

  @include mq($and: $hover) {
    &:hover {
      opacity: 0.5;
      transition: opacity 0.2s $ease-custom;
    }
  }

  @include mq(m) {
    position: absolute;
    right: 30px;
    bottom: 0;
  }
}
</style>
