<template>
  <component
    :is="mergeProps.tag"
    :class="[
      'label',
      `typo--label-${mergeProps.size}`,
      theme ? `label--${theme}` : false,
    ]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Label',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        tag: 'h3',
        size: 'm',
      };

      Object.keys(this.data).forEach(
        (key) => this.data[key] === undefined && delete this.data[key],
      );
      return { ...defaultProps, ...this.data };
    },
  },
};
</script>

<style lang="scss" scoped>
  .label {
    letter-spacing: 0.02em;

    &--grey {
      color: $grey;
    }

    &--detail {
      color: $grey;
      text-transform: uppercase;
    }
  }
</style>
