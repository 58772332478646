<template>
  <div
    v-if="isOos"
    class="availability"
  >
    <div
      class="oos"
    >
      {{ $labels.out_of_stock }}
    </div>
    <!-- <div v-else>
      {{ product.stock_quantity }}
      {{ $labels.available }}
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isOos } from '@/assets/js/utils-wc';

export default {
  name: 'Price',
  components: {},
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['wcSettings']),
    isOos() {
      return isOos(this.product);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
  .availability {
    margin-top: map-get($spacer, "xs");
    @extend %typo--label-m;

    .oos {
      background: $primary;
      max-width: max-content;
    }
  }
</style>
