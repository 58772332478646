export default {
  data() {
    return {
      settings: {
        sendLabel: 'recover',
      },
      groups: {
        'recovery-password': {
          visible: true,
          fields: {
            email: {
              type: 'email',
              model: null,
              placeholder: 'Email',
              label: 'email',
              rules: 'required|email',
            },
          },
        },
      },
    };
  },
  methods: {
    init() {

    },
    callback() {
      this.$store.dispatch('recoveryPassword', {
        email: this.groups['recovery-password'].fields.email.model,
      });
    },
  },
};
