<template>
  <Wrapper
    :size="
      data.section_layout &&
        (data.section_layout.section_wrapper !== 'default' ||
          data.section_layout.section_wrapper === 'no')
        ? false
        : 'l'
    "
  >
    <Wrapper
      v-if="data.title"
      :boxed="!data.unboxed"
      :margin="false"
    >
      <Spacer :bottom="{ default: 's2', m: 'l' }">
        <Title :data="{ value: data.title }" />
      </Spacer>
    </Wrapper>

    <Spacer
      v-if="data.filter"
      bottom="l"
      class="filters"
    >
      <Flex
        align="v-baseline"
        no-wrap
        class="overflow-scroll"
      >
        <Spacer
          right="m2"
          :left="{ default: 's', m: 'm2' }"
        >
          <Label :data="{ value: 'Filter:', size: 'm' }" />
        </Spacer>
        <Grid
          v-if="filters"
          col="auto"
        >
          <Cta
            v-for="filter in filters"
            :key="filter.id"
            :data="{
              title: filter.name,
              fn: () => changeFilter(filter.id),
            }"
            theme="button"
            :class="[
              'filter',
              filterActive && filterActive !== filter.id
                ? 'filter-not-active'
                : false,
            ]"
            :disable-hover="filterActive === filter.id"
          />
        </Grid>
      </Flex>
    </Spacer>
    <Wrapper
      :boxed="!classes.includes('archive-stories') && !data.unboxed"
      :margin="false"
    >
      <Grid
        v-if="items.length > 0"
        :col="gridCol"
        :template="gridTemplate"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="item"
        >
          <component
            :is="thumb(data.thumb)"
            :data="{ ...item, carousel: data.thumb === 'box' ? items : false }"
          />
        </div>
      </Grid>
      <div v-else>
        <Skeleton
          :items="skeletonItems"
          :cols="gridCol"
          :thumb-component="data.thumb"
        />
      </div>
      <Spacer
        v-if="showMore"
        :top="{ default: 'm2', m: 'xl' }"
        class="t-center"
      >
        <Cta
          :data="{
            title: 'Show more',
            fn: requestOffset,
          }"
          theme="button"
          :disabled="showMoreAvoid"
        />
      </Spacer>

      <Spacer
        v-if="data.cta"
        class="t-center"
        :top="{ default: 'm2', m: 'xl' }"
      >
        <Cta
          :data="data.cta"
          theme="button"
        />
      </Spacer>
    </Wrapper>
  </Wrapper>
</template>

<script>
import archive from '@/mixins/archive';

import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';

import Skeleton from '@/components/ui/skeleton';
import Thumb from '@/components/thumb';
import ThumbTutorial from '@/components/thumb/tutorial';
import ThumbStoryPost from '@/components/thumb/story-post';
import BoxThumb from '@/components/thumb/goliath-box';
import ProductThumb from '@/components/thumb/product';

const thumbs = new Map();
thumbs.set('box', BoxThumb);
thumbs.set('default', Thumb);
thumbs.set('product', ProductThumb);
thumbs.set('story-post', ThumbStoryPost);
thumbs.set('tutorial', ThumbTutorial);

export default {
  name: 'Archive',
  components: {
    Title,
    Cta,
    Label,
    Skeleton,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]:
                layout_size === 'auto'
                  ? (12 / this.items.length).toString()
                  : layout_size,
          };
        });
      } else {
        cols = {
          default: 12,
          m: 4,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
    classes() {
      return this.data.section_layout
        ? this.data.section_layout.section_classes !== ''
          ? this.data.section_layout.section_classes.split(' ')
          : []
        : [];
    },
  },
  methods: {
    thumb(key) {
      return key ? thumbs.get(key) : thumbs.get('default');
    },
  },
};
</script>

<style lang="scss" scoped>
  .infinite {
    visibility: hidden;
  }

  .filter {
    &-not-active {
      opacity: 0.5;
    }
  }

  .filters {
    min-height: 48px;
  }
</style>
