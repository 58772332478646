<template>
  <Wrapper
    :size="data.section_layout ? data.section_layout.section_wrapper === 'default' ? 'l' : false : 'l'"
    :boxed="(typeof data.boxed !== 'undefined') ? data.boxed : true"
  >
    <Spacer :bottom="{ default: 's2', m: 'xl'}">
      <Title
        v-if="data.title"
        :data="{ value: data.title, size: data.thumb === 'story' || data.thumb === 'team' || data.thumb === 'box' ? 'xl' : null }"
      />
    </Spacer>
    <Grid
      :col="gridCol"
      :template="gridTemplate"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <template v-if="data.thumb === 'default'">
          <Content
            v-if="checkContent(item)"
            :data="setContent(item)"
          />
          <Media
            v-if="checkMedia(item)"
            :data="setMedia(item)"
          />
        </template>
        <component
          :is="thumb(data.thumb)"
          v-else
          :data="{
            content: checkContent(item) ? setContent(item) : null,
            media: setMedia(item) ? setMedia(item) : null,
          }"
        />
      </div>

      <Spacer
        v-if="showMore && isMobile"
        :top="{default: 'm' }"
        class="t-center"
      >
        <Cta
          :data="{
            title: 'Show more',
            fn: showAll,
          }"
          theme="button"
        />
      </Spacer>
    </Grid>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  setContent, setMedia, checkContent, checkMedia,
} from '@/assets/js/utils';
import Title from '@/components/typo/title';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Cta from '@/components/typo/cta';

import ThumbIconLabel from '@/components/thumb/icon-label';
import ThumbTitleArrow from '@/components/thumb/title-arrow';
import ThumbFeatures from '@/components/thumb/features';
import ThumbTeam from '@/components/thumb/team';
import ThumbStory from '@/components/thumb/story';
import BoxThumb from '@/components/thumb/goliath-box';

const thumbs = new Map();
thumbs.set('icon-label', ThumbIconLabel);
thumbs.set('title-arrow', ThumbTitleArrow);
thumbs.set('features', ThumbFeatures);
thumbs.set('team', ThumbTeam);
thumbs.set('story', ThumbStory);
thumbs.set('box', BoxThumb);

export default {
  name: 'Repeater',
  components: {
    Content,
    Media,
    Title,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setContent,
      setMedia,
      checkContent,
      checkMedia,
      showMore: false,
      offset: 1,
    };
  },
  computed: {
    ...mapGetters(['isMobile']),
    items() {
      if (this.data.section_layout.section_id === 'our-story' && this.isMobile) {
        return this.data.items.filter((item, index) => index <= this.offset);
      }
      return this.data.items;
    },
    gridCol() {
      let cols = null;
      // const elementsLength = this.data.items?.length || this.data.categories?.length || this.data.posts?.length;
      const defaultColsNumber = 4;

      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]: layout_size === 'auto' ? defaultColsNumber.toString() : layout_size,
          };
        });
      } else {
        cols = {
          default: 12,
          s: 6,
          m: defaultColsNumber.toString(),
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
  },
  mounted() {
    if (this.data.section_layout.section_id === 'our-story') {
      this.showMore = true;
    }
  },
  methods: {
    thumb(key) {
      return key ? thumbs.get(key) : thumbs.get('default');
    },
    showAll() {
      this.offset = this.data.items.length;
      this.showMore = false;
    },
  },
};
</script>

<style lang="scss">

</style>
