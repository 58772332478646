import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import VTooltip from 'v-tooltip';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import Cookies from 'js-cookie';

import sessionHandler from '@/assets/js/sessionHandler';

import { loadScript } from '@/assets/js/utils';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/js/eventBus';
import '@/assets/js/marketing';
import '@/assets/js/raf';
import '@/assets/js/layout';
import '@/assets/js/labels';
import '@/assets/js/flickity-fix';

sync(store, router);
Vue.use(InlineSvgPlugin);
Vue.use(VTooltip);
Vue.use(Vue2TouchEvents);
Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: {
      // eslint-disable-next-line
      id: __VUE_WORDPRESS__.state.options.extra.gtag_id,
      params: {
        anonymize_ip: false,
      },
    },
    // bootstrap: true,
  },
  router,
);

sessionHandler.init({
  onInitCompleted: () => store.dispatch('initActions'),
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

document.querySelector('html').classList.add('goliath-site');

// eslint-disable-next-line
if (__VUE_WORDPRESS__.state.site.locale === 'int') {
  // eslint-disable-next-line
  window._iub = [];
  // eslint-disable-next-line
  window._iub.csConfiguration = {
    banner: {
      acceptButtonDisplay: true,
      applyStyles: false,
      closeButtonCaption: `<img src="${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/close.svg" class="icon"/>`,
      closeButtonRejects: true,
      customizeButtonCaption: 'Customize',
      customizeButtonDisplay: true,
      position: 'bottom',
      rejectButtonDisplay: false,
      slideDown: true,
    },
    callback: {
      onConsentGiven: () => {
        Cookies.set('goliath-cookie', '1', { expires: 365 });

        if (window.gtag) {
          // window.gtag('consent', 'default', {
          //   ad_storage: 'granted',
          //   analytics_storage: 'granted',
          // });
        }

        // if (this.$gtag) {
        //   this.$gtag.config({
        //     params: {
        //       anonymize_ip: true,
        //       send_page_view: true,
        //     },
        //   });
        // }
      },
      onConsentRejected: () => {
        // TEMP
        // Cookies.remove('goliath-cookie');
        // if (window.gtag) {
        //   window.gtag('consent', 'default', {
        //     ad_storage: 'denied',
        //     analytics_storage: 'denied',
        //   });
        // }
        // if (this.$gtag) {
        //   this.$gtag.config({
        //     params: {
        //       anonymize_ip: false,
        //       send_page_view: false,
        //     },
        //   });
        // }
      },
      onBannerClosed: () => {
        Vue.prototype.$bus.$emit('iubendaClose');
      },
      onBannerShown: () => {
        Vue.prototype.$bus.$emit('iubendaReady');
      },
    },
    consentOnContinuedBrowsing: false,
    cookiePolicyId: 10201654,
    floatingPreferencesButtonDisplay: 'bottom-right',
    invalidateConsentWithoutLog: true,
    lang: 'en',
    perPurposeConsent: true,
    siteId: 1171736,
    whitelabel: false,
  };

  loadScript('https://cdn.iubenda.com/cs/iubenda_cs.js');
}

if (window.affirm && window.affirm.ui) {
  window.affirm.ui.ready(() => {
    window.affirm.ui.refresh();
  });
}
