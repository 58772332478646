<template>
  <div
    :class="[
      'dropdown',
      'u-hidden--until-m',
      active ? 'dropdown--active' : false,
    ]"
    :style="{
      left: `${left}px`,
      visibility: left <= 0 || !ready ? 'hidden' : 'visible',
    }"
    @mouseenter="setForce(true)"
    @mouseleave="setForce(false)"
  >
    <div class="active-currency">
      <Icon
        theme="tool"
        :name="currentCurrency"
        size="m"
      />
    </div>
    <div
      v-for="currency in otherCurrencies"
      :key="currency.value"
    >
      <Icon
        theme="tool"
        :name="currency.name"
        size="m"
        :fn="() => setCurrency(currency.value)"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';

// eslint-disable-next-line no-undef
const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

export default {
  name: 'CurrencyDropdown',
  components: {
    Icon,
  },
  data() {
    return {
      active: false,
      force: false,
      ready: false,
      left: -1,
      currencies: [
        {
          value: 'EUR',
          name: 'euro',
        },
        {
          value: 'USD',
          name: 'dollar',
        },
        {
          value: 'GBP',
          name: 'pound',
        },
        {
          value: 'CAD',
          name: 'c-dollar',
        },
      ],
    };
  },
  computed: {
    currentCurrency() {
      return this.currencies.find((cur) => cur.value === woocommerce_currency)
        .name;
    },
    otherCurrencies() {
      return this.currencies.filter(
        (cur) => cur.value !== woocommerce_currency,
      );
    },
  },
  watch: {
    left(val) {
      if (val <= 0) this.setLeft();
    },
    ready(val) {
      if (val) this.setLeft();
    },
  },
  mounted() {
    this.$bus.$on('openDropdown', this.open);
    this.$bus.$on('closeDropdown', this.close);
    this.$bus.$on('windowResized', this.setLeft);
    this.$bus.$on('endHeaderAnim', () => {
      this.ready = true;
    });
  },
  methods: {
    setLeft() {
      this.force = false;
      this.close();
      this.left = this.$root.$el
        .querySelector('header .currency-drop')
        .getBoundingClientRect().x;
    },
    setCurrency(value) {
      const { location } = window;

      if (value === 'USD') {
        const host = location.host.startsWith('www')
          ? location.host.replace('www.', '')
          : location.host;
        const usLocation = `${location.protocol}//us.${host}${location.pathname}`;
        window.location.assign(usLocation);
      } else if (woocommerce_currency === 'USD') {
        let host = location.host.split('.');
        host.shift();
        host = host.join('.');

        const baseLocation = `${location.protocol}//${host}${location.pathname}?wmc-currency=${value}`;
        window.location.assign(baseLocation);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('wmc-currency', value);
        window.location.search = urlParams;
      }
    },
    open() {
      this.active = true;
    },
    close() {
      if (this.force) return;
      this.active = false;
    },
    setForce(val) {
      this.force = val;
      if (val) {
        this.open();
      } else {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .dropdown {
    position: fixed;
    z-index: 101;
    top: calc(var(--notificationHeight, 0px) + 36px);
    background: $black;
    padding-top: 0px;
    padding-bottom: 4px;
    border-radius: 10px;
    transition: height 0.2s ease-out, top 0.3s;
    height: 40px;
    overflow: hidden;

    &--active {
      height: 164px;
    }
  }
</style>
