import labels from '@/assets/js/labels';

import {
  addToCart,
  updateCartItem,
  removeCartItem,
  getCart,
  clearCart,
  calculateCart,
  createOrder,
  // getCoupons,
  addCoupon,
  removeCoupon,
  setShippingMethod,
  setPaymentGateway,
  createUser,
  getCustomer,
  getOrder,
  getOrders,
  updateAddress,
  updateUser,
  getCountries,
  cancelOrder,
  updatePaymentMethod,
  // calculateShippingMethods,
  // getPaymentGateways,
  trackCheckout,
  trackThxPage,
} from '@/api';
import sessionHandler from '@/assets/js/sessionHandler';

import { removeCartCookies } from '@/store/modules/auth/actions';

const getCartFn = async ({ commit }) => {
  commit('SET_CART_LOADING', true);
  await calculateCart({
    payLoad: {},
  });
  const { data } = await getCart();
  if (window.affirm) {
    window.affirm.ui.refresh();
  }
  commit('SET_CART', data);
  commit('SET_CART_LOADING', false);
};

const addCheckoutMetas = (body, metas) => {
  body.metas = {};
  if (metas.stripe) {
    body.metas.stripe = {
      client_secret: metas.stripe.clientSecret,
    };
  }
  return body;
};

export default {
  async initActions({ dispatch }) {
    await dispatch('checkUser');
  },
  getCart: getCartFn,
  async addToCart({ commit }, initialData) {
    const { id, variation_id, quantity } = initialData;
    commit('SET_CART_LOADING', true);
    const { data, status } = await addToCart({
      payLoad: {
        product_id: id,
        variation_id,
        quantity,
      },
    });
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('addedToCart', initialData);

      const { data: dataCart } = await getCart();

      commit('SET_CART', dataCart);

      commit('SET_SNACKBAR', {
        active: true,
        content: 'Product added to cart',
        theme: 'dark',
      });
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async updateCartItem({ commit }, initialData) {
    const { key, quantity } = initialData;

    commit('SET_CART_LOADING', true);
    const { data, status } = await updateCartItem({
      payLoad: {
        cart_item_key: key,
        quantity,
      },
    });
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('addedToCart', initialData);

      const { data: dataCart } = await getCart();

      commit('SET_CART', dataCart);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async removeCartItem({ commit }, initialData) {
    const { key } = initialData;
    commit('SET_CART_LOADING', true);
    const { data, status } = await removeCartItem({
      payLoad: {
        cart_item_key: key,
      },
    });
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('removedFromCart', initialData);

      const { data: dataCart } = await getCart();

      commit('SET_CART', dataCart);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async clearCart({ state, commit }) {
    commit('SET_CART_LOADING', true);
    removeCartCookies();
    const result = await clearCart();
    state.paymentGateway = null;
    if (result === true) {
      commit('SET_CART', {});
    }
    commit('SET_CART_LOADING', false);
  },
  // getCartTotals: getCartTotalsFn,
  async setShippingMethod({ state, commit, getters }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await setShippingMethod(
      addCheckoutMetas(body, getters.paymentMetas),
    );
    if (status === 200) {
      commit('SET_CART', data);
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('addShippingInfo', state.cart);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async setPaymentGateway({ state, commit, getters }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await setPaymentGateway(
      addCheckoutMetas(body, getters.paymentMetas),
    );
    state.paymentGateway = body.gateway_id;
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit(
        'addPaymentInfo',
        Object.assign(state.cart, { paymentGateway: state.paymentGateway }),
      );
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async addCoupon({ commit, getters }, body) {
    // eslint-disable-next-line no-undef
    const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;
    console.log(getters.paymentGateway);
    commit('SET_CART_LOADING', true);
    if (
      body.coupon.toLowerCase() === 'bday800'
      && woocommerce_currency !== 'USD'
    ) {
      commit('SET_SNACKBAR', {
        active: true,
        content: 'Coupon not enabled for this country',
      });
      // } else if (body.coupon.toLowerCase() === 'bday800' && getters.paymentGateway && getters.paymentGateway === 'splitit') {
      //   commit('SET_SNACKBAR', { active: true, content: 'Discount code valid for one-time payment method only' });
    } else {
      const { data, status } = await addCoupon(
        addCheckoutMetas(body, getters.paymentMetas),
      );

      if (status === 200) {
        commit('SET_CART', data);
        // commit('SET_SNACKBAR', { active: true, content: data.notices.success[0], theme: 'success' });
      } else {
        commit('SET_SNACKBAR', { active: true, content: data.message });
      }
    }
    commit('SET_CART_LOADING', false);
  },
  async removeCoupon({ commit, getters }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await removeCoupon(
      addCheckoutMetas(body, getters.paymentMetas),
    );
    if (status === 200) {
      commit('SET_CART', data);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async createOrder({ commit }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await createOrder(body);
    commit('SET_CART_LOADING', false);
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      // this._vm.$bus.$emit('purchased', data.order);

      return data;
    }
    commit('SET_SNACKBAR', { active: true, content: data.message });
    return false;
  },
  // async paymentGateways({ commit, getters }) {
  //   if (!getters.paymentGateways || Object.keys(getters.paymentGateways).length === 0) {
  //     const { data } = await getPaymentGateways();
  //     commit('SET_PAYMENT_GATEWAYS', data);
  //   }
  // },
  async createUser({ commit }, body) {
    try {
      const { data } = await createUser(body);
      commit('SET_SNACKBAR', {
        active: true,
        content: labels.account_created,
        theme: 'success',
      });
      if (typeof data === 'number') {
        await sessionHandler.login({
          username: body.email,
          password: body.password,
        });
      }
      return true;
    } catch (error) {
      const { response } = error;
      commit('SET_SNACKBAR', { active: true, content: response.data.message });
      return false;
    }
  },
  async simpleCreateUser({ commit }, body) {
    try {
      const { data } = await createUser(body);
      return data;
    } catch (error) {
      const { response } = error;
      commit('SET_SNACKBAR', { active: true, content: response.data.message });
      return false;
    }
  },
  async checkUser({ commit, getters, dispatch }) {
    commit('SET_AUTH_LOADING', true);
    if (sessionHandler.user != null && !getters.user) {
      commit('SET_USER', sessionHandler.user);
    }
    if (getters.user) {
      if (!getters.customer || Object.keys(getters.customer).length === 0) {
        const { data, status } = await getCustomer();
        if (status === 200) {
          commit('SET_CUSTOMER', data.customer);
          commit(
            'SET_CUSTOMER_SAVED_METHODS',
            data.customer_saved_methods_list,
          );
        } else {
          dispatch('logout');
        }
      }
    }
    commit('SET_AUTH_LOADING', false);
  },
  closeSnackbar({ commit }) {
    commit('SET_SNACKBAR', { active: false, content: null });
  },
  // eslint-disable-next-line no-empty-pattern
  getOrder({}, params) {
    return getOrder(params);
  },
  async getOrders({ commit }) {
    // if (!getters.orders) { richiesti per sicurezza sempre altrimenti alla fine di un acquisto uno non li vede
    commit('SET_CART_LOADING', true);
    const response = await getOrders();

    if (response) {
      const { data } = response;
      commit('SET_ORDERS', data);
    }
    commit('SET_CART_LOADING', false);
    // }
  },
  async editAddress({ commit }, body) {
    try {
      const response = await updateAddress(body);
      if (response) {
        const { data } = response;
        commit('SET_CUSTOMER', data);
      }
      commit('SET_SNACKBAR', {
        active: true,
        content: labels.saved_changes,
        theme: 'success',
      });
    } catch (error) {
      commit('SET_SNACKBAR', {
        active: true,
        content: labels.error_user_addresses_update,
      });
    }
  },
  async editUser({ commit, getters }, body) {
    try {
      const { data: user } = await updateUser(body);
      if (user) {
        if (user.access_token) {
          const { data } = user.wp_user;
          commit('SET_USER', data);
        } else {
          const newUser = Object.assign(getters.user, user);
          commit('SET_USER', newUser);
        }

        const { data } = await getCustomer();
        commit('SET_CUSTOMER', data.customer);
      }
      commit('SET_SNACKBAR', {
        active: true,
        content: labels.saved_changes,
        theme: 'success',
      });
    } catch (error) {
      commit('SET_SNACKBAR', {
        active: true,
        content: labels.error_user_update,
      });
    }
  },
  async loadCustomerData({ commit }) {
    try {
      const { data } = await getCustomer();
      commit('SET_CUSTOMER', data.customer);
      commit('SET_CUSTOMER_SAVED_METHODS', data.customer_saved_methods_list);
      const { data: dataCart } = await getCart();
      commit('SET_CART', dataCart);
      return data.customer;
    } catch (error) {
      commit('SET_SNACKBAR', {
        active: true,
        content: labels.error_generic_customer,
      });
      return false;
    }
  },
  async getCountries({ commit, getters }) {
    if (!getters.geoData) {
      const response = await getCountries();

      if (response) {
        const { data } = response;
        commit('SET_COUNTRIES', data);
      }
    }
  },
  async cancelOrder({ commit }, payLoad) {
    let completed;
    commit('SET_CART_LOADING', true);
    const { status } = await cancelOrder(payLoad);
    if (status === 200) {
      commit('SET_SNACKBAR', {
        active: true,
        content: labels.order_cancelled,
        theme: 'success',
      });
      // dispatch('getOrders');
      completed = true;
    } else {
      commit('SET_SNACKBAR', { active: true, content: labels.error_generic });
      completed = false;
    }
    commit('SET_CART_LOADING', false);
    return completed;
  },
  async updatePaymentMethod({ commit, dispatch }, { body, data }) {
    let completed;
    commit('SET_CART_LOADING', true);
    const { data: order, status } = await updatePaymentMethod(body, data);
    if (status === 200) {
      // commit('SET_SNACKBAR', { active: true, content: labels.order_updated, theme: 'success' });
      dispatch('getOrders');
      completed = order;
    } else {
      commit('SET_SNACKBAR', { active: true, content: labels.error_generic });
      completed = false;
    }
    commit('SET_CART_LOADING', false);
    return completed;
  },
  trackCheckout,
  trackThxPage(_, { order_id }) {
    trackThxPage({ order_id });
  },
  // async calculateShippingMethods({ commit, getters }) {
  //   const { customer } = getters;
  //   const { data: customerShippingMethods, status } = await calculateShippingMethods({
  //     country: customer.shipping.country ? customer.shipping.country : customer.billing.country,
  //     state: customer.shipping.state ? customer.shipping.state : customer.billing.state,
  //     city: customer.shipping.city ? customer.shipping.city : customer.billing.city,
  //     postcode: customer.shipping.postcode ? customer.shipping.postcode : customer.billing.postcode,
  //     return_methods: true,
  //   });
  //   if (status === 200) {
  //     commit('SET_CUSTOMER_SHIPPING_METHODS', customerShippingMethods);
  //     return customerShippingMethods;
  //   }
  //   commit('SET_SNACKBAR', { active: true, content: customerShippingMethods.message });
  //   return false;
  // },
};
