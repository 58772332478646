<template>
  <Wrapper
    size="l"
    boxed
  >
    <Spacer
      :all="{ m: 'xl' }"
      :class="[
        'reserve',
        data.layout !== 'default' ? `reserve--${data.layout}` : false,
      ]"
    >
      <Media
        :data="data.media"
        class="bg"
      />
      <Wrapper
        size="xs2"
        :margin="false"
      >
        <Spacer :all="{ default: 's', m: 0 }">
          <Content
            :data="{
              title: titlePrice,
              rich_text: data.rich_text,
              boxed: false,
              section_layout: {
                section_wrapper: true,
                section_classes: '',
              },
            }"
          />

          <Spacer
            :top="{ default: 's2', m: 'm' }"
            class="pricing"
          >
            <Grid col="12">
              <div v-if="price">
                <Label :data="{ value: data.product.price_label }" />
                <Richtext :data="{ value: price }" />
              </div>
              <div v-if="installmentPrice">
                <Label :data="{ value: data.product.installments_label }" />
                <Cta
                  v-if="data.modal.modal_type"
                  :data="{
                    fn: openInstallmentModal,
                    title: installmentPrice,
                    size: 's',
                  }"
                  theme="underline"
                />

                <p
                  v-show="!data.modal.modal_type"
                  data-page-type="category"
                  class="affirm-as-low-as typo--s"
                  data-affirm-type="text"
                  data-learnmore-show="false"
                  :data-amount="
                    unparseCurrency(productReserve.price) * 100
                  "
                >
                  {{ installmentPrice }}
                </p>
              </div>
              <div v-if="shippingPrice">
                <Label :data="{ value: data.product.shipping_label }" />
                <Richtext :data="{ value: shippingPrice }" />
              </div>
            </Grid>
          </Spacer>

          <Spacer :top="{ default: 'm2', m: 'l' }">
            <Cta
              :data="{
                fn: addToCart,
                title: data.cta ? data.cta.title : $labels.add_to_cart,
              }"
              theme="button"
            />
          </Spacer>
        </Spacer>
      </Wrapper>
    </Spacer>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseCurrency, minRate, unparseCurrency } from '@/assets/js/utils-wc';

import Media from '@/components/blocks/media';
import Content from '@/components/blocks/content';
import Richtext from '@/components/typo/richtext';
import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';

// eslint-disable-next-line no-undef
const { woocommerce_currency } = __VUE_WORDPRESS__.state.wcSettings;

export default {
  name: 'OrderGoliath',
  components: {
    Media,
    Content,
    Richtext,
    Label,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titlePrice: this.data.title,
      price: '',
      installmentPrice: '',
      minRate: this.data.product.deposit_price,
      productReserve: null,
      shippingPrice: null,
    };
  },
  computed: {
    ...mapGetters(['product', 'wcSettings']),
  },
  mounted() {
    this.productReserve = this.product(
      this.data.product_id?.post_name || 'goliath-cnc',
    );

    this.titlePrice = this.data.title;
    const priceParsed = parseCurrency(this.productReserve.price, {
      ...this.wcSettings,
      precision: 0,
    });
    if (this.productReserve.sale_price && this.productReserve.sale_price !== this.productReserve.regular_price) {
      this.price = this.data.product.price.replace(
        '{product.price.total}',
        `<del>${parseCurrency(this.productReserve.regular_price, {
          ...this.wcSettings,
          precision: 0,
        })}</del> ${priceParsed}`,
      );
    } else {
      this.price = this.data.product.price.replace(
        '{product.price.total}',
        priceParsed,
      );
    }
    this.installmentPrice = this.data.product.installments_price.replace(
      '{product.price.minRate}',
      `${parseCurrency(
        Math.floor(minRate(this.productReserve.price, this.wcSettings)),
        { ...this.wcSettings, precision: 0 },
      )}`,
    );
    let shippingPrice = '';
    try {
      const shippingPrices = JSON.parse(this.data.product.shipping_price);
      shippingPrice = shippingPrices[woocommerce_currency];
    } catch (error) {
      shippingPrice = 'Free shipping';
    }
    this.shippingPrice = shippingPrice;
    const accordions = {
      items: [],
    };

    if (this.data.modal.modal_type) {
      this.data.modal.accordions.forEach((element) => {
        accordions.items.push({
          cta: '',
          rich_text: element.rich_text,
          title: '',
          title_trigger: element.title_trigger,
        });
      });
      Object.assign(this.data.modal, {
        ...this.data.modal,
        price_label: this.data.product.price_label,
        price: this.price,
        accordions,
      });

      this.$bus.$emit('populateModal', {
        type: 'content',
        theme: 'overlay',
        id: 'order-goliath',
        content: this.data.modal,
        product: this.productReserve,
      });
    } else if (typeof window.affirm.ui.refresh === 'function') {
      this.$nextTick(() => {
        this.$nextTick(() => {
          window.affirm.ui.refresh();
        });
      });
    }
  },
  beforeDestroy() {
    if (this.data.modal.modal_type) {
      this.$bus.$emit('removeContentModal', 'reserve');
    }
  },
  methods: {
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings, true);
    },
    addToCart() {
      this.$store
        .dispatch(
          'addToCart',
          Object.assign(this.data.product, {
            id: String(this.productReserve.id),
            quantity: 1,
            quantityDelta: 1,
          }),
        )
        .then(() => {
          this.show = false;
          this.$router.push('/cart');
        });
    },
    openInstallmentModal() {
      this.$bus.$emit('openModal', 'order-goliath');
    },
  },
};
</script>

<style lang="scss" scoped>
.reserve {
  position: relative;
  text-align: left;
  background: $grey-xl;

  border-radius: var(--radius);

  &--dark {
    background: $grey-d;
    color: $white;

    @include mq(m) {
      background: transparent;
    }
  }

  @include mq(m) {
    background: transparent;
    border-radius: 0;
  }
}

.bg {
  @include aspect-ratio(3, 2);

  @include mq($until: m) {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    ::v-deep picture {
      position: absolute;
      width: 100%;
    }
  }

  @include mq(m) {
    @include aspect-ratio($auto: true);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss">
.pricing {
  .label {
    color: $grey-l;
  }
}

.reserve .affirm-as-low-as a {
  display: inline-block;
}
</style>
